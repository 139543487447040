import "./FacilityListComponent.scss";
import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import {APIConfig, ImageConfig, Misc} from "../../../constants";
import {ITableColumn} from "../../../shared/models/table.model";
import {CommonService} from "../../../shared/services";
import TagComponent from "../../../shared/components/tag/TagComponent";
import {useCallback, useEffect, useMemo, useState} from "react";
import SearchComponent from "../../../shared/components/search/SearchComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {useDispatch} from "react-redux";
import {setCurrentNavParams} from "../../../store/actions/navigation.action";
import {IAPIResponseType} from "../../../shared/models/api.model";
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import TextWithIconComponent from "../../../shared/components/text-with-icon/TextWithIconComponent";
import {getFacilityListLite} from "../../../store/actions/static-data.action";

interface PropertyListComponentProps {

}

const FacilityListComponent = (props: PropertyListComponentProps) => {
    const [filterData, setFilterData] = useState<any>({
        search: '',
        // sort:{},
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentNavParams('Facility') as any);
    }, [dispatch]);

    const [isDownloading, setIsDownloading] = useState<boolean>(false);


    const handleFacilityListDownload = useCallback(() => {
        setIsDownloading(true);
        const payload: any = {};
        const {status} = filterData;
        payload.status = [status];
        let apiCall: any = {};
        apiCall = CommonService._facility.downloadFacilityDataCSVAPICall(filterData);

        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsDownloading(false);
            }

        })
            .catch((error: any) => {
                setIsDownloading(false);
            }).finally(() => {

        });
    }, [filterData]);


    const onFacilityActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  Facility`,
                image: item?.is_active ? ImageConfig.DeactivateFacilityIcon : ImageConfig.ActivateFacilityIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'Deactivate' : 'Activate'}  the
    facility?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: item?.is_active,
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._facility.ActivateDeactivateFacility(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setFilterData({});
                    dispatch(getFacilityListLite());
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, [dispatch]);

    const FacilityListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.FacilitySidebarIcon/>}>Facility
                    Name</TextWithIconComponent>,
                key: 'facility_name',
                dataIndex: 'name',
                fixed: 'left',
                // sortable: true,
                width: 180,
                render: (item: any) => {
                    return <div className={'facility-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div style={{cursor: "pointer"}}
                                             className={"ellipses-for-table-data property-name"}>
                                            {item?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.AgencySidebarIcon/>}>Agency
                    Count</TextWithIconComponent>,
                key: 'agency_count',
                dataIndex: 'agency_count',
                width: 180,
                align: 'center',
                render: (item: any) => {
                    return <>{item?.agencies_count || 0}</>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.SchedulerSidebarIcon/>}>Onboarded
                    On</TextWithIconComponent>,
                key: 'onboarded_on',
                dataIndex: 'onboarded_on',
                width: 150,
                align: 'left',
                render: (item: any) => {
                    if (item?.created_at) {
                        return <>{CommonService.convertDateFormat2(item?.created_at, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },

            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.PersonProfileOutline/>}>Admin
                    Name</TextWithIconComponent>,
                key: 'admin',
                dataIndex: 'admin',
                width: 180,
                render: (item: any) => {
                    return <div className={'facility-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.admin_details?.first_name + " " + item?.admin_details?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.admin_details?.first_name + " " + item?.admin_details?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div style={{cursor: "pointer"}}
                                             className={"ellipses-for-table-data property-name"}>
                                            {item?.admin_details?.first_name + " " + item?.admin_details?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.admin_details?.first_name + " " + item?.admin_details?.last_name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.PhoneIcon/>}>Admin Contact
                    No</TextWithIconComponent>,
                key: 'admin_contact_no',
                dataIndex: 'admin_contact_no',
                width: 150,
                render: (item: any) => {
                    return <>
                        {item?.admin_details?.contact_number}
                    </>;
                }
            },
            {
                title: () => <TextWithIconComponent
                    prefix={<ImageConfig.CheckmarkRound/>}>Status</TextWithIconComponent>,
                key: 'status',
                dataIndex: 'status',
                align: 'center',
                width: 100,
                render: (item: any) => {
                    return <>
                        <TagComponent
                            color={item?.is_active ? CommonService.getStatusTagColor('Checked In') : CommonService.getStatusTagColor('Checked Out')}
                            label={item?.is_active ? 'Active' : 'Inactive'}/>
                    </>;
                }
            },
            {
                title: 'Activate/Deactivate',
                key: 'activate-deactivate',
                dataIndex: 'activate',
                align: 'center',
                width: 150,
                render: (item: any) => {
                    return <>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onFacilityActivateDeactivate(item);
                        }}/>
                    </>;
                }
            },
            {
                title: "",
                dataIndex: "actions",
                align: 'center',
                key: "actions",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent route={CommonService._routeConfig.FacilityDetailsRoute(item?._id)}>
                            <ImageConfig.ArrowRightOutlinedIcon color={"#4E535F"}/>
                        </LinkComponent>;
                    }
                }
            }
        ];
    }, [onFacilityActivateDeactivate]);


    return (
        <>
            <div className={'facility-list-component header-screen mrg-top-25 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.FacilityModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title">Facility</div>
                        </div>
                    </div>
                    <div className="list-options">
                        <div className={"mrg-top-20"}>
                            <SearchComponent
                                size={'medium'}
                                placeholder="Search facility name"
                                value={filterData.search}
                                onSearchChange={(value) => {
                                    setFilterData({...filterData, search: value, page: 1});
                                }}
                            />
                        </div>
                        <div>
                            <ButtonComponent color={'download-button'} variant={"contained"} isLoading={isDownloading}
                                             onClick={handleFacilityListDownload}>
                                Download CSV
                            </ButtonComponent>
                        </div>
                        <LinkComponent route={CommonService._routeConfig.AddFacilityRoute()}>
                            <ButtonComponent color={'primary'} style={{color: '#202937'}}
                                             prefixIcon={<ImageConfig.AddOutlinedIcon/>}>
                                Add New Facility
                            </ButtonComponent>
                        </LinkComponent>

                    </div>
                </div>
            </div>
            <div className={'facility-list-component list-screen mrg-top-10 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-content">
                    <TableWrapperComponent url={APIConfig.FACILITY_LIST.URL}
                                           method={APIConfig.FACILITY_LIST.METHOD}
                                           isPaginated={true}
                                           extraPayload={filterData}
                                           noDataText={filterData.search ? 'Oops! It seems like there are no Facilities Available for the name you have searched.' : 'No Data'}
                                           columns={FacilityListColumn}
                    />
                </div>
            </div>
        </>
    );

};

export default FacilityListComponent;
