import "./FilterPopupComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {Checkbox, IconButton, InputAdornment, ListItemText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import {ImageConfig} from "../../../constants";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {getWingsListLite} from "../../../store/actions/static-data.action";
import ClearIcon from "@mui/icons-material/Clear";
import {CommonService} from "../../../shared/services";
import {open_shift_status, shift_status} from "../../../data";

interface SchedulerFilterComponentProps {
    setFilterData?: any;
    filterData?: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


//const [firstDayOfMonth, lastDayOfMonth] = CommonService.getFirstDayAndLastDayUsingDate();
const SchedulerFilterComponent = (props: SchedulerFilterComponentProps) => {
    const dispatch = useDispatch();
    const {filterData, setFilterData} = props;
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    // const [dateRange, setDateRange] = useState<any[]>([null, null]);
    const {HcpTypesList} = useSelector((state: IRootReducerState) => state.meta);
    const {facilityList, wingsList, isWingsLoading} = useSelector((state: IRootReducerState) => state.staticData);
    const [selectedFacility, setSelectedFacility] = useState<any>(null);
    const [modifiedWingsList, setModifiedWingsList] = useState<any>(null);
    const togglePopup = useCallback(() => {
        setIsPopupOpen((prevIsOpen: any) => !prevIsOpen);
    }, []);

    const [selectedHcpTypeData, setSelectedHcpTypeData] = React.useState<Array<string>>([]);
    const [selectedShiftData, setSelectedShiftData] = React.useState<Array<string>>([]);
    const [selectedWingsData, setSelectedWingsData] = React.useState<Array<string>>([]);
    const [selectedFilterCount, setSelectedFilterCount] = React.useState<number>(0);
    const [selectedRequirementType, setSelectedRequirementType] = React.useState<Array<string>>([]);

    const handleHcpTypeSelection = (event: SelectChangeEvent<typeof selectedHcpTypeData>) => {
        // @ts-ignore
        setSelectedHcpTypeData(event.target.value);
    };

    const handleRequirementTypeSelection = (event: SelectChangeEvent<typeof selectedRequirementType>) => {
        console.log(event.target.value);
        // @ts-ignore
        setSelectedRequirementType(event.target.value);
    };

    const handleShiftTypeSelection = (event: SelectChangeEvent<typeof selectedShiftData>) => {
        console.log(event.target.value);
        // @ts-ignore
        setSelectedShiftData(event.target.value);
    };

    const handleWingsSelection = (event: SelectChangeEvent<typeof selectedWingsData>) => {
        // @ts-ignore
        setSelectedWingsData(event.target.value);
    };

    const handleFacilitySelect = (event: SelectChangeEvent<typeof selectedFacility>) => {
        setSelectedFacility(event.target.value);
        setSelectedWingsData([]);
    };

    const applyFilters = useCallback(() => {
        let updatedFilterData = {...filterData};

        let selectedFilter = 0;
        // Handle hcp_types filter
        if (selectedHcpTypeData?.length === 0) {
            delete updatedFilterData.hcp_types;
        } else {
            selectedFilter = selectedFilter + selectedHcpTypeData?.length;
            updatedFilterData.hcp_types = selectedHcpTypeData.map((item: any) => item?.code);
        }

        //Handle Facility Filter
        if (!selectedFacility) {
            delete updatedFilterData.facility_id;
        } else {
            selectedFilter++;
            updatedFilterData.facility_id = selectedFacility?.code;
        }

        // Handle shift_status filter
        if (selectedShiftData?.length === 0) {
            delete updatedFilterData.statuses;
        } else {
            selectedFilter = selectedFilter + selectedShiftData?.length;
            updatedFilterData.statuses = selectedShiftData.map((item: any) => item?.code);
        }

        if (selectedRequirementType?.length === 0) {
            delete updatedFilterData.open_status_type;
        } else {
            selectedFilter = selectedFilter + selectedRequirementType?.length;
            updatedFilterData.open_status_type = selectedRequirementType.map((item: any) => item?.code);
        }

        //Handling selected_wings filter
        if (selectedWingsData?.length === 0) {
            delete updatedFilterData.facility_wings;
        } else {
            selectedFilter = selectedFilter + selectedWingsData?.length;
            updatedFilterData.facility_wings = selectedWingsData.map((item: any) => item?.code);
        }

        // Update the filterData state once
        setSelectedFilterCount(selectedFilter);
        setSelectedFilterCount(selectedFilter);
        if (selectedFilter > 0) {
            setFilterData(updatedFilterData);
            togglePopup();
        } else {
            CommonService._alert.showToast("Please select atleast one filter", "info");
        }
    }, [togglePopup, selectedFacility, filterData, selectedHcpTypeData, selectedShiftData, selectedRequirementType, selectedWingsData, setFilterData]);


    const clearAllFilter = useCallback(() => {
        setSelectedHcpTypeData([]);
        setSelectedShiftData([]);
        setSelectedRequirementType([]);
        setSelectedFacility(null);
        setSelectedWingsData([]);
        setSelectedFilterCount(0);
        delete filterData.statuses;
        delete filterData.hcp_types;
        delete filterData.facility_wings;
        delete filterData.facility_id;
        delete filterData.open_status_type;
        setFilterData({
            ...filterData
        });
    }, [filterData, setFilterData]);

    useEffect(() => {
        dispatch(getWingsListLite(selectedFacility?.code));
    }, [dispatch, selectedFacility]);


    useEffect(() => {
        if (wingsList) {
            const modifiedData = wingsList?.map((item: any) => {
                const {_id, name, ...rest} = item; // Destructure the "name" property and keep the rest of the object

                return {
                    ...rest,     // Keep the other properties unchanged
                    title: name,
                    code: _id,// Add the new key "newName" with the value of the old "name"
                };
            });
            setModifiedWingsList(modifiedData);
        } else {
            setModifiedWingsList(null);
        }
    }, [wingsList]);

    return (
        <>
            <div className="filter-popup">
                <ButtonComponent color={"download-button"}
                                 className="filter-icon"
                                 prefixIcon={<ImageConfig.FilterPrefixIcon/>}
                                 suffixIcon={<>{selectedFilterCount > 0 && <>
                                     <span className="filter-count filter-display-holder">{selectedFilterCount}</span>
                                 </>}</>}
                                 onClick={togglePopup}>
                    Filters
                </ButtonComponent>
                {isPopupOpen && (
                    <div className="filter-popup-content">
                        {/* Filter options go here */}
                        <div>
                            <div className={'filter-header d-flex'} style={{justifyContent: "space-between"}}>
                                <div className={'d-flex'}
                                     style={{justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                                    <div className={'mrg-right-10'}>
                                        <ImageConfig.FilterPrefixIcon/>
                                    </div>
                                    <div>Filters</div>
                                </div>
                                <div className={'icon-button'} onClick={togglePopup}>
                                    <ImageConfig.FilterHeaderCrossIcon/>
                                </div>
                            </div>
                            <div className={'pdd-left-10 pdd-right-10'}>
                                <HorizontalLineComponent/>
                                <div className={"mrg-top-10"}>
                                    <FormControl sx={{m: 1, width: 300}}>
                                        {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                        {!selectedFacility &&
                                            <div className={'material-select-box-placeholder-custom '}>
                                                Select Facility
                                            </div>}
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedFacility}
                                            //  label="Select Facility"
                                            placeholder={'Select Staff Type'}
                                            onChange={handleFacilitySelect}
                                            displayEmpty={true}
                                            endAdornment={
                                                <>{
                                                    selectedFacility && (
                                                        <InputAdornment className={'mrg-right-10'} position="end">
                                                            <IconButton
                                                                aria-label="Clear selection"
                                                                onClick={() => {
                                                                    setSelectedFacility(null);
                                                                }}
                                                                edge="end"
                                                            >
                                                                <ClearIcon/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )}
                                                </>
                                            }
                                            // IconComponent={!selectedFacility ? () => <></> : () => <div
                                            //     style={{display: 'none'}}></div>}
                                        >
                                            {facilityList.map((item: any) => (
                                                <MenuItem key={item.code} value={item}>
                                                    <ListItemText primary={item.title}/>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <FormControl sx={{m: 1, width: 300}}>
                                    {selectedHcpTypeData?.length === 0 &&
                                        <div className={'material-select-box-placeholder-custom'}>
                                            Select Staff Type
                                        </div>}
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedHcpTypeData}
                                        onChange={handleHcpTypeSelection}
                                        renderValue={(selected) => selected.map((item: any) => item.title).join(", ")}
                                        MenuProps={MenuProps}
                                        endAdornment={
                                            <>{
                                                selectedHcpTypeData?.length > 0 && (
                                                    <InputAdornment className={'mrg-right-10'} position="end">
                                                        <IconButton
                                                            aria-label="Clear selection"
                                                            onClick={() => {
                                                                setSelectedHcpTypeData([]);
                                                            }}
                                                            edge="end"
                                                        >
                                                            <ClearIcon/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )}
                                            </>
                                        }
                                    >
                                        {HcpTypesList.map((item: any) => (
                                            <MenuItem key={item.code} value={item}>
                                                <Checkbox style={{
                                                    color: "#202937"
                                                }}
                                                          checked={selectedHcpTypeData.some((selectedItem: any) => selectedItem.code === item.code)}/>
                                                <ListItemText primary={item.title}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                                <FormControl sx={{m: 1, width: 300}}>
                                    {selectedRequirementType?.length === 0 &&
                                        <div className={'material-select-box-placeholder-custom'}>
                                            Select Requirement Status
                                        </div>}
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedRequirementType}
                                        onChange={handleRequirementTypeSelection}
                                        renderValue={(selected) => selected.map((item: any) => item.title).join(", ")}
                                        MenuProps={MenuProps}
                                        endAdornment={
                                            <>{
                                                selectedRequirementType?.length > 0 && (
                                                    <InputAdornment className={'mrg-right-10'} position="end">
                                                        <IconButton
                                                            aria-label="Clear selection"
                                                            onClick={() => {
                                                                setSelectedRequirementType([]);
                                                            }}
                                                            edge="end"
                                                        >
                                                            <ClearIcon/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )}
                                            </>
                                        }
                                    >
                                        {open_shift_status.map((item: any) => (
                                            <MenuItem key={item.code} value={item}>
                                                <Checkbox style={{
                                                    color: "#202937"
                                                }}
                                                          checked={selectedRequirementType.some((selectedItem: any) => selectedItem.code === item.code)}/>
                                                <ListItemText primary={item.title}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                                <FormControl sx={{m: 1, width: 300}}>
                                    {selectedShiftData?.length === 0 &&
                                        <div className={'material-select-box-placeholder-custom'}>
                                            Select Shift Status
                                        </div>}
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedShiftData}
                                        onChange={handleShiftTypeSelection}
                                        renderValue={(selected) => selected.map((item: any) => item.title).join(", ")}
                                        MenuProps={MenuProps}
                                        endAdornment={
                                            <>{
                                                selectedShiftData?.length > 0 && (
                                                    <InputAdornment className={'mrg-right-10'} position="end">
                                                        <IconButton
                                                            aria-label="Clear selection"
                                                            onClick={() => {
                                                                setSelectedShiftData([]);
                                                            }}
                                                            edge="end"
                                                        >
                                                            <ClearIcon/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )}
                                            </>
                                        }
                                    >
                                        {shift_status.map((item: any) => (
                                            <MenuItem key={item.code} value={item}>
                                                <Checkbox style={{
                                                    color: "#202937"
                                                }}
                                                          checked={selectedShiftData.some((selectedItem: any) => selectedItem.code === item.code)}/>
                                                <ListItemText primary={item.title}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{m: 1, width: 300}}>
                                    {selectedWingsData?.length === 0 &&
                                        <div className={'material-select-box-placeholder-custom'}>
                                            Select Wings
                                        </div>}
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        disabled={!selectedFacility || isWingsLoading}
                                        value={selectedWingsData}
                                        onChange={handleWingsSelection}
                                        renderValue={(selected) => selected.map((item: any) => item.title).join(", ")}
                                        MenuProps={MenuProps}
                                        endAdornment={
                                            <>{
                                                selectedWingsData?.length > 0 && (
                                                    <InputAdornment className={'mrg-right-10'} position="end">
                                                        <IconButton
                                                            aria-label="Clear selection"
                                                            onClick={() => {
                                                                setSelectedWingsData([]);
                                                            }}
                                                            edge="end"
                                                        >
                                                            <ClearIcon/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )}
                                            </>
                                        }
                                    >
                                        {modifiedWingsList.map((item: any) => (
                                            <MenuItem key={item.code} value={item}>
                                                <Checkbox style={{
                                                    color: "#202937"
                                                }}
                                                          checked={selectedWingsData.some((selectedItem: any) => selectedItem.code === item.code)}/>
                                                <ListItemText primary={item.title}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <HorizontalLineComponent className={'mrg-top-15'}/>
                                <div className={'d-flex justify-content-space-between align-items-center'}>
                                    <ButtonComponent variant={"link"}
                                        //   disabled={selectedHcpTypeData?.length === 0 && !selectedFacility && selectedShiftData?.length === 0 && selectedWingsData?.length === 0}
                                                     className={'clear-all-filter-text icon-button '}
                                                     onClick={clearAllFilter}>
                                        Clear All
                                    </ButtonComponent>
                                    <ButtonComponent className="filter-icon mrg-right-10 mrg-top-10 mrg-bottom-10"
                                                     color={"download-button"}
                                                     onClick={applyFilters}>
                                        Apply Filters
                                    </ButtonComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default SchedulerFilterComponent;
