import {
    COMING_SOON_ROUTE,
    FACILITY,
    DASHBOARD,
    LOGIN_ROUTE,
    NOT_FOUND_ROUTE,
    AGENCY,
    FACILITY_DETAILS,
    ADD_FACILITY,
    FORGOT_PASSWORD_ROUTE,
    FACILITY_lIST,
    AGENCY_DETAILS,
    ADD_AGENCY,
    HCP_MANAGEMENT,
    ADD_HCP,
    SHIFT_REQUIREMENT,
    ADD_SHIFT_REQUIREMENT,
    HCP_VIEW_DETAILS,
    SHIFT_REQUIREMENT_VIEW_DETAILS,
    SCHEDULER,
    USER_MANAGEMENT,
    ADD_USER_MANAGEMENT, INTERNAL_HCP_MANAGEMENT, INTERNAL_HCP_VIEW_DETAILS, ADD_INTERNAL_HCP, SHIFT_BULK_UPLOAD
} from "../../constants/RoutesConfig";

const DefaultRoute = () => {
    return "/";
};

//vars-health

const FacilityRoute = () => {
    return FACILITY;
};

const FacilityListRoute = () => {
    return FACILITY_lIST;
};


const FacilityDetailsRoute = (id: any) => {
    return FACILITY_DETAILS + '/' + id;
};


const AgencyRoute = () => {
    return AGENCY;
};

const HcpManagementRoute = () => {
    return HCP_MANAGEMENT;
};

const InternalHcpManagementRoute = () => {
    return INTERNAL_HCP_MANAGEMENT;
};
const ShiftManagementRoute = () => {
    return SHIFT_REQUIREMENT;
};
const SchedulerRoute = () => {
    return SCHEDULER;
};

const UserManagementRoute = () => {
    return USER_MANAGEMENT;
};

const ShiftRequirementDetailsRoute = (shiftType: any, shiftId: any) => {
    return `${SHIFT_REQUIREMENT_VIEW_DETAILS}/${shiftType}/${shiftId}`;
};

const BulkUploadPage = () => {
    return SHIFT_BULK_UPLOAD;
}

const AgencyDetailsRoute = (agencyId: any) => {
    return `${AGENCY_DETAILS}/${agencyId}`;
};

const LoginRoute = () => {
    return LOGIN_ROUTE;
};

const forgotPasswordRoute = () => {
    return FORGOT_PASSWORD_ROUTE;
};

const hcpViewDetailsRoute = (hcpId: any) => {
    return `${HCP_VIEW_DETAILS}/${hcpId}`;
};

const internalHcpViewDetails = (internalHcpId: any) => {
    return `${INTERNAL_HCP_VIEW_DETAILS}/${internalHcpId}`;
};
const NotFoundRoute = () => {
    return NOT_FOUND_ROUTE;
};


const ComingSoonRoute = () => {
    return COMING_SOON_ROUTE;
};

const Dashboard = () => {
    return DASHBOARD;
};

//vars-health
const AddFacilityRoute = () => {
    return ADD_FACILITY;
};

const AddUserManagementRoute = () => {
    return ADD_USER_MANAGEMENT;
};
const AddAgencyRoute = () => {
    return ADD_AGENCY;
};

const AddHcpRoute = () => {
    return ADD_HCP;
};
const AddInternalHcpRoute = () => {
    return ADD_INTERNAL_HCP;
};

const AddShiftRequirement = () => {
    return ADD_SHIFT_REQUIREMENT;
};

const RouteConfigService = {
    DefaultRoute,
    LoginRoute,
    NotFoundRoute,
    ComingSoonRoute,
    Dashboard,
    FacilityRoute,
    AgencyRoute,
    FacilityDetailsRoute,
    AddFacilityRoute,
    forgotPasswordRoute,
    FacilityListRoute,
    AgencyDetailsRoute,
    AddAgencyRoute,
    HcpManagementRoute,
    AddHcpRoute,
    ShiftManagementRoute,
    AddShiftRequirement,
    hcpViewDetailsRoute,
    ShiftRequirementDetailsRoute,
    SchedulerRoute,
    UserManagementRoute,
    AddUserManagementRoute,
    InternalHcpManagementRoute,
    internalHcpViewDetails,
    AddInternalHcpRoute,
    BulkUploadPage
};

export default RouteConfigService;
