import "./FacilityDetailsComponent.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc} from "../../../../constants";
import FormikInputComponent from "../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import {CommonService} from "../../../../shared/services";
import FormikTextAreaComponent
    from "../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getFacilityListLite} from "../../../../store/actions/static-data.action";
import PhoneInputComponent from "../../../../shared/components/phoneInput/PhoneInputComponent";
import LabelComponent from "../../../../shared/components/label/LabelComponent";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {addFacilitySteps, americanTimeZone, shift_type} from "../../../../data";
import FormikSelectDropdownComponent
    from "../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import {IRootReducerState} from "../../../../store/reducers";
import {FACILITY_lIST} from "../../../../constants/RoutesConfig";
import LinkComponent from "../../../../shared/components/link/LinkComponent";
import {v4 as uuidv4} from 'uuid';
import FormikTimePickerComponent
    from "../../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent";
import TableComponent from "../../../../shared/components/table/TableComponent";
import {ITableColumn} from "../../../../shared/models/table.model";
import ToolTipComponent from "../../../../shared/components/tool-tip/ToolTipComponent";
import StepperComponent from "../../../../shared/components/stepper-component/StepperComponent";
import SelectDropdownComponent
    from "../../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent";


interface FacilityDetailsComponentProps {
    prev: () => void;
    setFormTab: any;
    formTab: any;
}

const FacilityValidationSchema = Yup.object({
    name: Yup.string()
        .min(3, 'Facility Name should be at least 3 characters')
        .max(50, 'Facility Name should not exceed 50 characters')
        .required('Facility Name is required'),
    contact_number: Yup.string().min(12, "min 10 digits").required("Phone Number is required"),
    email: Yup.string().required("Email Address is required"),
    about: Yup.string(),
    domain: Yup.string()
        .required('Domain Name is required')
        .matches(/^[a-zA-Z0-9]+$/, 'Invalid domain name format (alphanumeric only)'),
    admin_first_name: Yup.string()
        .min(3, 'First Name should be at least 3 characters')
        .max(50, 'First Name should not exceed 50 characters')
        .required('First Name is required'),
    admin_last_name: Yup.string()
        .min(3, 'Last Name should be at least 3 characters')
        .max(50, 'Last Name should not exceed 50 characters')
        .required('Last Name is required'),
    admin_contact_number: Yup.string().min(12, "min 10 digits").required("Phone Number is required"),
    admin_email: Yup.string().email('Invalid Email Address').required('Email Address is required'),
    address: Yup.object().shape({
        street: Yup.string().required('Address Line is required').min(3, 'Address Line should be at least 3 characters'),
        city: Yup.string().required('City is required').min(2, 'City should be at least 2 characters'),
        state: Yup.string().required('State is required').min(2, 'State should be at least 2 characters'),
        region: Yup.string().required('Region is required'),
        country: Yup.string().required('Country is required').min(2, 'Country should be at least 2 characters'),
        zip_code: Yup.string().required('ZIP Code is required').min(5, 'ZIP Code should be at least 5 characters').max(6, 'ZIP Code should be maximum 6 characters'),
    }),
    timezone: Yup.string().required('Timezone is required')
});

interface formInitialValuesFormSchema {
    name: string;
    contact_number: any;
    email: string;
    about: string;
    domain: string;
    admin_first_name: any;
    admin_last_name: any;
    admin_contact_number: any;
    admin_email: any;
    timezone: any;
    wings: {
        name: any;
    };
    shift_timings: {
        shift_type: string;
        shift_start_time: string;
        shift_end_time: string;
    };
    address: {
        street: any,
        city: any,
        state: any,
        region: any,
        country: any,
        zip_code: any,
    };
    notification_preferences: {
        isSmsRequired: any;
        isEmailRequired: any;
        isPush_notificationRequired: any;
    };
}

const formInitialValues: formInitialValuesFormSchema = {
    name: "",
    contact_number: "",
    email: "",
    about: "",
    domain: "",
    admin_first_name: "",
    admin_last_name: "",
    admin_contact_number: "",
    admin_email: "",
    timezone: "",
    shift_timings: {
        shift_type: '',
        shift_start_time: '',
        shift_end_time: '',
    },
    wings: {
        name: "",
    },
    address: {
        street: "",
        city: "",
        state: "",
        region: "",
        country: "",
        zip_code: "",
    },
    notification_preferences: {
        isSmsRequired: true,
        isEmailRequired: true,
        isPush_notificationRequired: true,
    }
};


const FacilityDetailsComponent = (props: FacilityDetailsComponentProps) => {
    const dispatch = useDispatch();
    const {setFormTab, formTab} = props;
    const [initialValues] = useState<formInitialValuesFormSchema>(formInitialValues);
    const {regionList} = useSelector((state: IRootReducerState) => state.staticData);
    const navigate = useNavigate();
    const [facilityAddActiveStep, setFacilityAddActiveStep] = React.useState(0);
    const [openWingAddField, setOpenWingAddField] = React.useState(false);
    const [wingNameList, setWingNameList] = useState<any>([]);
    const [shiftTimeList, setShiftTimeList] = useState<any>([]);
    
    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
            wings: wingNameList,
            shift_timings: shiftTimeList,
            notification_preferences: {
                isSmsRequired: true,
                isEmailRequired: true,
                isPush_notificationRequired: true,
            }
        };
        let apiCall: any = {};
        apiCall = CommonService._facility.AddFacilityAPICall(payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            navigate(CommonService._routeConfig.FacilityDetailsRoute(response.data._id));
            setFacilityAddActiveStep(2);
            dispatch(getFacilityListLite());
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setFormTab("Facility");
                // setButtonNameOnTabChange("Next");
            }).finally(() => {
            setSubmitting(false);
        });

        // eslint-disable-next-line
    }, [navigate, formTab, dispatch, wingNameList, shiftTimeList]);


    const handleNext = useCallback(() => {
        setFacilityAddActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, []);

    const handleBack = useCallback(() => {
        setFacilityAddActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, []);


    const ShiftTimeListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: 'Shift Type',
                key: 'shift_type',
                dataIndex: 'shift_type',
                fixed: 'left',
                width: 150,
                render: (item: any) => {
                    return <div className={'wing-details-wrapper'}>
                        <div className={'wing-name-wrapper'}>
                            {
                                (item?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.shift_type}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.shift_type || '-'}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="wing-name">
                                        {item?.shift_type || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },


            {
                title: 'Shift Start Time',
                key: 'shift_start_time',
                dataIndex: 'shift_start_time',
                align: 'center',
                width: 150,
                render: (item: any) => {

                    return <>
                        <div>
                            {CommonService?.convertMinutesToTime(item?.shift_start_time)}
                        </div>
                    </>;
                }
            },
            {
                title: "Shift End Time",
                dataIndex: "shift_end_time",
                align: 'center',
                key: "shift_end_time",
                width: 150,
                fixed: 'right',
                render: (item: any) => {
                    return <>
                        <div>
                            {CommonService?.convertMinutesToTime(item?.shift_end_time)}
                        </div>
                    </>;
                }
            },
            {
                title: "",
                dataIndex: "delete",
                align: 'center',
                key: "delete",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    return <LinkComponent onClick={() => {
                        // Filter the list to exclude the item with the specified ID
                        const updatedShiftTimeList = shiftTimeList.filter((setId: any) => setId.id !== item?.id);
                        setShiftTimeList(updatedShiftTimeList);
                    }
                    }>
                        <ImageConfig.DeleteIcon/>
                    </LinkComponent>;
                }
            }
        ];
    }, [shiftTimeList]);

    return (
        <div className={'add-facility-details-component'}>
            <div className={'add-facility-sidebar-steper'}>
                <div className={'add-facility-sidebar-steper-wrapper pdd-20'}>
                    <div className={'flex-1'}>
                        <div className={'adding-module-branding-wrapper'}>
                            <div className="menu-item-icon">
                                <ImageConfig.VarsShortLogoIcon/>
                            </div>

                            <div className={'vars-health-logo-text'}>
                                VARS
                            </div>
                        </div>
                        <div className={'form-top-header-text mrg-top-40'}>
                            Facility Onboarding
                        </div>
                        <StepperComponent activeStep={facilityAddActiveStep} steps={addFacilitySteps}/>
                    </div>
                    <div>
                        <ButtonComponent
                            id={"exit_btn"}
                            suffixIcon={<ImageConfig.ExitIcon/>}
                            color={"exit-onboarding-navigation"}
                            onClick={() => {
                                CommonService.onExitFlowHandling(navigate, FACILITY_lIST);
                            }}
                        >
                            Exit Facility Onboarding
                        </ButtonComponent>
                    </div>
                </div>
            </div>
            <div className={'add-facility-formik-wrapper mrg-top-20'}>
                <Formik
                    validationSchema={FacilityValidationSchema}
                    initialValues={initialValues}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            validateForm();
                        }, [validateForm, values]);
                        return (
                            <Form className="t-form scroll-content" noValidate={true}>
                                {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                                <div className="add-component-content">
                                    <div className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                        {formTab === "Facility" && <>
                                            <div className={'form-top-header-text'}>Facility Details</div>
                                            <Field name={'name'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikInputComponent
                                                            label={'Facility Name'}
                                                            placeholder={'Enter Facility Name'}
                                                            type={"text"}
                                                            required={true}
                                                            formikField={field}
                                                            fullWidth={true}
                                                            inputProps={{maxLength: 100}}
                                                        />
                                                    )
                                                }
                                            </Field>
                                            <div className="ts-row">
                                                <div className="ts-col-6">
                                                    <LabelComponent title={'Phone Number*'}/>
                                                    <Field name={'contact_number'}>
                                                        {(field: FieldProps) => {
                                                            return <PhoneInputComponent field={field}
                                                                                        placeholder={"Enter Phone number"}/>;
                                                        }}
                                                    </Field>
                                                </div>
                                                <div className="ts-col-6">
                                                    <Field name={'email'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Email Address'}
                                                                    placeholder={'example@xyz.com'}
                                                                    type={"email"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className={"mrg-top-10"}>
                                                <Field name={'about'}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikTextAreaComponent
                                                                label={"About The Facility"}
                                                                placeholder={'Enter About The Facility'}
                                                                rows={4}
                                                                //required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                            </div>
                                            <div className="ts-row align-items-center">
                                                <div className="ts-col-0.5 disabled-state-text-font mrg-top-10">
                                                    https://
                                                </div>
                                                <div className="ts-col-3">
                                                    <Field name={'domain'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Domain Name'}
                                                                    placeholder={'Enter Domain Name'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-0.5 disabled-state-text-font mrg-top-10">
                                                    .varshealth.com
                                                </div>
                                            </div>

                                            <HorizontalLineComponent/>
                                            <div className="add-component-header-admin">
                                                <div className="form-top-header-text">
                                                    Admin Details
                                                </div>
                                            </div>
                                            <div className="ts-row">
                                                <div className="ts-col-6">
                                                    <Field name={'admin_first_name'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'First Name'}
                                                                    placeholder={'ex : John'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-6">
                                                    <Field name={'admin_last_name'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Last Name'}
                                                                    placeholder={'ex : Doe'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="ts-row">
                                                <div className="ts-col-6">
                                                    <LabelComponent title={'Phone Number*'}/>
                                                    <Field name={'admin_contact_number'}>
                                                        {(field: FieldProps) => {
                                                            return <PhoneInputComponent  field={field}
                                                                                        placeholder={"Enter Phone number"}/>;
                                                        }}
                                                    </Field>
                                                </div>
                                                <div className="ts-col-6">
                                                    <Field name={'admin_email'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Email Address'}
                                                                    placeholder={'example@xyz.com'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                        </>}
                                        {
                                            formTab === "Address" && <>
                                                <div className="form-top-header-text">
                                                    Facility Address Details
                                                </div>
                                                <Field name={'address.street'}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikInputComponent
                                                                label={'Address Line'}
                                                                placeholder={'Enter address here'}
                                                                type={"text"}
                                                                required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                                <div className="ts-row">
                                                    <div className="ts-col-6">
                                                        <Field name={'address.country'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Country'}
                                                                        placeholder={'Enter the Country'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-6">
                                                        <Field name={'address.state'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'State'}
                                                                        placeholder={'Enter State'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-6">
                                                        <Field name={'address.zip_code'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'ZIP Code*'}
                                                                        placeholder={'Enter ZIP Code'}
                                                                        type={"number"}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                        maxLength={6}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-6">
                                                        <Field name={'address.city'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'City'}
                                                                        placeholder={'Enter City'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-6">
                                                        <Field name={'timezone'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikSelectDropdownComponent
                                                                        options={americanTimeZone}
                                                                        displayWith={(option: any) => option.title}
                                                                        valueExtractor={(option: any) => option.code}
                                                                        label={'Timezone'}
                                                                        placeholder={'Select Timezone'}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                        searchable={true}
                                                                        noDataMessage={'No timezone available'}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-6">
                                                        <Field name={'address.region'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikSelectDropdownComponent
                                                                        options={regionList}
                                                                        displayWith={(option: any) => option.title}
                                                                        valueExtractor={(option: any) => option.code}
                                                                        label={'Region'}
                                                                        placeholder={'Select Region'}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                        searchable={true}
                                                                        noDataMessage={"No region available"}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'d-flex justify-content-space-between align-items-center'}>
                                                    <div className="form-top-header-text">
                                                        Wings
                                                    </div>
                                                    <div>
                                                        <ButtonComponent color={'download-button'}
                                                                         prefixIcon={<ImageConfig.AddOutlinedIcon/>}
                                                                         onClick={() => {
                                                            setOpenWingAddField(true);
                                                        }}>
                                                            Add Wing
                                                        </ButtonComponent>
                                                    </div>
                                                </div>
                                                {openWingAddField &&
                                                    <div className={'d-flex ts-align-items-center'}>
                                                        <Field name={'wings.name'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Wing Name'}
                                                                        placeholder={'Enter Wing Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                        <div className={'mrg-top-10 mrg-left-10'} onClick={()=>{
                                                            setOpenWingAddField(false);
                                                        }}>
                                                                <ImageConfig.CancelWingIcon width={35} height={35}/>
                                                        </div>
                                                        <div
                                                            className={'mrg-top-10 mrg-left-5'}    onClick={() => {
                                                            const uniqueId: string = uuidv4();
                                                            const newWingName = {
                                                                name: values.wings.name,
                                                                id: uniqueId,
                                                            };
                                                            setWingNameList([...wingNameList, newWingName]);
                                                            setFieldValue('wings.name', '');

                                                        }}>
                                                                <ImageConfig.SaveIcon width={35} height={35}/>
                                                        </div>
                                                    </div>
                                                }
                                                <div className={'wing-list-wrapper'}>
                                                    {
                                                        wingNameList?.length > 0 && wingNameList.map((wingName: any, index: number) => {
                                                            return (
                                                                <div
                                                                    className={'d-flex justify-content-space-between align-items-center wing-details-holder'}>
                                                                    <div className="facility-add-wing-text">
                                                                        {wingName?.name}
                                                                    </div>
                                                                    <LinkComponent onClick={() => {
                                                                        // Filter the list to exclude the item with the specified ID
                                                                        const updatedWingNameList = wingNameList.filter((setId: any) => setId.id !== wingName?.id);
                                                                        setWingNameList(updatedWingNameList);
                                                                    }
                                                                    }>
                                                                        <ImageConfig.DeleteIcon/>
                                                                    </LinkComponent>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </>
                                        }
                                        {
                                            formTab === "Shift-Timings" && <>
                                                <div className="form-top-header-text">
                                                    Add Shift Timings
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={"mrg-right-10 flex-1"}>
                                                        <LabelComponent title={'Shift Type*'}/>
                                                        <SelectDropdownComponent className={""}
                                                                                 options={shift_type}
                                                                                 placeholder={"Select"}
                                                                                 isClearable={true}
                                                                                 label={''}
                                                                                 value={values?.shift_timings.shift_type}
                                                                                 valueExtractor={item => item.code}
                                                                                 displayWith={item => item.title}
                                                                                 onUpdate={
                                                                                     (value) => {
                                                                                         setFieldValue("shift_timings.shift_type", value);
                                                                                     }
                                                                                 }
                                                                                 fullWidth={true}
                                                        />
                                                    </div>
                                                    <div className={"mrg-right-10 flex-1"}>
                                                        <div>
                                                            <LabelComponent title={"Shift Start Time*"} tooltip={""}/>
                                                            <Field name={'shift_timings.shift_start_time'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikTimePickerComponent
                                                                            placeholder={'Select'}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className={"mrg-right-10 flex-1"}>
                                                        <div>
                                                            <LabelComponent title={"Shift End Time*"} tooltip={""}/>
                                                            <Field name={'shift_timings.shift_end_time'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikTimePickerComponent
                                                                            placeholder={'Select'}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={'mrg-left-5'}>
                                                        <LabelComponent title={""} tooltip={""}/>
                                                        <div className={'mrg-top-37'}>
                                                        <ButtonComponent
                                                            variant={'outlined'}
                                                            disabled={values.shift_timings.shift_type === '' || values.shift_timings.shift_start_time === '' || values.shift_timings.shift_end_time === ''}
                                                            onClick={() => {
                                                                const uniqueId: string = uuidv4();
                                                                const newShiftTime = {
                                                                    shift_type: values.shift_timings?.shift_type,
                                                                    shift_start_time: CommonService.convertToAdjustedMinutes(values.shift_timings?.shift_start_time),
                                                                    shift_end_time: CommonService.convertToAdjustedMinutes(values.shift_timings?.shift_end_time),
                                                                    id: uniqueId,
                                                                };
                                                                setShiftTimeList([...shiftTimeList, newShiftTime]);
                                                                setFieldValue('shift_timings.shift_type', '');
                                                                setFieldValue('shift_timings.shift_start_time', '');
                                                                setFieldValue('shift_timings.shift_end_time', '');
                                                            }}
                                                            style={{
                                                                width: 0,
                                                                height: 0,
                                                                border: 0,
                                                                borderRadius: '100%',
                                                                backgroundColor: "#fff"
                                                            }}
                                                        >
                                                            <ImageConfig.SaveIcon width={35} height={35}/>
                                                        </ButtonComponent>
                                                        </div>
                                                    </div>
                                                </div>
                                                {shiftTimeList && shiftTimeList?.length > 0 &&
                                                    <div
                                                        className={"list-screen  mrg-top-10 wing-timing-table-wrapper"}>
                                                        <TableComponent columns={ShiftTimeListColumn} data={shiftTimeList}/>
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                    <div className={'add-facility-form-action-element'}>
                                        {formTab === "Facility" &&
                                            <div></div>
                                        }
                                        {formTab === "Facility" &&
                                            <div>
                                                <ButtonComponent
                                                   color={'download-button'}
                                                    onClick={() => {
                                                        setFormTab("Address");
                                                        handleNext();
                                                        setFacilityAddActiveStep(1);
                                                    }}
                                                    suffixIcon={<ImageConfig.NextButtonIcon/>}
                                                >
                                                    Next
                                                </ButtonComponent>
                                            </div>
                                        }

                                        {formTab === "Address" &&
                                            <div className={"mrg-left-20"}>
                                                <ButtonComponent
                                                    className={'back-button'}
                                                    prefixIcon={<ImageConfig.BackFormButton/>}
                                                    onClick={() => {
                                                        setFormTab("Facility");
                                                        handleBack();
                                                    }}
                                                >
                                                    Back
                                                </ButtonComponent>
                                            </div>
                                        }
                                        {formTab === "Shift-Timings" &&
                                            <div className={"mrg-left-20"}>
                                                <ButtonComponent
                                                    className={'back-button'}
                                                    prefixIcon={<ImageConfig.BackFormButton/>}
                                                    onClick={() => {
                                                        setFormTab("Address");
                                                        handleBack();
                                                    }}
                                                >
                                                    Back
                                                </ButtonComponent>
                                            </div>
                                        }
                                        {formTab === "Address" &&
                                            <div>
                                                <ButtonComponent
                                                    color={'download-button'}
                                                    onClick={() => {
                                                        setFormTab("Shift-Timings");
                                                        handleNext();
                                                        setFacilityAddActiveStep(2);
                                                    }}
                                                    suffixIcon={<ImageConfig.NextButtonIcon/>}
                                                >
                                                    Next
                                                </ButtonComponent>
                                            </div>

                                        }
                                        {formTab === "Shift-Timings" &&
                                            <div>
                                                <ButtonComponent
                                                    disabled={isSubmitting || !isValid}
                                                    isLoading={isSubmitting}
                                                    type={'submit'}
                                                    suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                                >
                                                    Save Details
                                                </ButtonComponent>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );

};

export default FacilityDetailsComponent;
