import "./UserManagementComponent.scss";
import React, {useCallback, useMemo, useState} from "react";
import {ITableColumn} from "../../../shared/models/table.model";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {CommonService} from "../../../shared/services";
import TagComponent from "../../../shared/components/tag/TagComponent";
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {APIConfig, ImageConfig, Misc} from "../../../constants";
import SearchComponent from "../../../shared/components/search/SearchComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import EditUserComponent from "../edit-user/EditUserComponent";
import {IAPIResponseType} from "../../../shared/models/api.model";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../shared/components/tabs/TabsComponent";
import TextWithIconComponent from "../../../shared/components/text-with-icon/TextWithIconComponent";

const UserManagementListComponent = () => {
    const [activeTab, setActiveTab] = useState<any>("facility-user");
    const [filterData, setFilterData] = useState<any>({
        search: '',
        // sort:{},
    });
    const [agencyFilterData, setAgencyFilterData] = useState<any>({
        search: '',
        // sort:{},
    });
    const [isEditUserDetailsDrawerOpen, setIsEditUserDetailsDrawerOpen] = useState<any>(false);
    const [userDetails, setUserDetails] = useState<any>(null);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isAgencyDownloading, setIsAgencyDownloading] = useState<boolean>(false);

    const handleDownloadUsers = useCallback(() => {
        setIsDownloading(true);
        let apiCall: any = {};
        apiCall = CommonService._user_management.downloadUserDataCSVAPICall(filterData);
        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsDownloading(false);
            }

        })
            .catch((error: any) => {
                setIsDownloading(false);
            }).finally(() => {

        });
    }, [filterData]);


    const handleAgencyDownloadUsers = useCallback(() => {
        setIsAgencyDownloading(true);
        let apiCall: any = {};
        apiCall = CommonService._user_management.downloadAgencyUserDataCSVAPICall(agencyFilterData);
        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsAgencyDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsAgencyDownloading(false);
            }
        })
            .catch((error: any) => {
                setIsAgencyDownloading(false);
            }).finally(() => {

        });
    }, [agencyFilterData]);


    const onUserActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  User ?`,
                image: item?.is_active ? ImageConfig.UserDeactivateIcon : ImageConfig.UserActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'Deactivate' : 'Activate'}  the selected
    User?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._facility.ActivateDeactivateFacilityAdmin(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setFilterData({});
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, []);

    const onAgencyUserActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  User?`,
                image: item?.is_active ? ImageConfig.UserDeactivateIcon : ImageConfig.UserActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'Deactivate' : 'Activate'}  the selected
    User?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._agency.ActivateDeactivateAgencyAdmin(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setAgencyFilterData({});
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, []);

    const UserManagementListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: () => <TextWithIconComponent
                    prefix={<ImageConfig.PersonProfileOutline/>}>Username</TextWithIconComponent>,
                key: 'user_name',
                dataIndex: 'user_management_name',
                fixed: 'left',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'user-management-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'user-management-name-wrapper'}>
                            {
                                (item?.first_name + " " + item?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.first_name + " " + item?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.first_name + " " + item?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.first_name + " " + item?.last_name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.FacilitySidebarIcon/>}>Facility
                    Name</TextWithIconComponent>,
                key: 'facility_name',
                dataIndex: 'name',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'facility-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.facility_details?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.facility_details?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.facility_details?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.facility_details?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },

            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.PhoneIcon/>}>Phone
                    Number</TextWithIconComponent>,
                key: 'user_phone_no',
                dataIndex: 'admin_contact_no',
                width: 150,
                render: (item: any) => {
                    return <>
                        {item?.contact_number}
                    </>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.EmailIcon/>}>Email
                    ID</TextWithIconComponent>,
                key: 'email_id',
                dataIndex: 'email_id_name',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.email)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.email}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.email}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.email || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent
                    prefix={<ImageConfig.NotificationPreferenceIcon/>}>Notification Preference</TextWithIconComponent>,
                key: 'notification-preference',
                dataIndex: 'notification-preference',
                align: 'left',
                width: 200,
                render: (item: any) => {
                    return <>
                        <div className={'d-flex'}>
                          <span>
                        {item?.notification_preferences?.isEmailRequired ? "Email" : ""}
                              {item?.notification_preferences?.isPush_notificationRequired ? (item?.notification_preferences?.isEmailRequired ? ", In App" : "In App") : ""}
                              {item?.notification_preferences?.isSmsRequired ? ((item?.notification_preferences?.isEmailRequired || item?.notification_preferences?.isPush_notificationRequired) ? ", SMS" : "SMS") : ""}
                      </span>
                        </div>
                    </>;
                }
            },
            {
                title: () => <TextWithIconComponent
                    prefix={<ImageConfig.CheckmarkRound/>}>Status</TextWithIconComponent>,
                key: 'status',
                dataIndex: 'status',
                align: 'left',
                width: 150,
                render: (item: any) => {
                    return <>
                        <TagComponent
                            color={item?.is_active ? CommonService.getStatusTagColor('Checked In') : CommonService.getStatusTagColor('Checked Out')}
                            // showIndicator={true}
                            label={item?.is_active ? 'Active' : 'Inactive'}/>
                    </>;
                }
            },
            {
                title: 'Activate/Deactivate',
                key: 'activate-deactivate',
                dataIndex: 'activate',
                align: 'center',
                width: 150,
                render: (item: any) => {
                    return <>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onUserActivateDeactivate(item);
                        }}/>
                    </>;
                }
            },
            {
                title: "Actions",
                dataIndex: "actions",
                align: 'center',
                key: "actions",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent onClick={() => {
                            setIsEditUserDetailsDrawerOpen(true);
                            setUserDetails(item);
                        }}>
                            <ImageConfig.PencilEditIcon/>
                        </LinkComponent>;
                    }
                }
            }
        ];
    }, [onUserActivateDeactivate]);


    const AgencyUserManagementListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: () => <TextWithIconComponent
                    prefix={<ImageConfig.PersonProfileOutline/>}>Username</TextWithIconComponent>,
                key: 'user_name',
                dataIndex: 'user_management_name',
                fixed: 'left',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'user-management-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'user-management-name-wrapper'}>
                            {
                                (item?.first_name + " " + item?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.first_name + " " + item?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.first_name + " " + item?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.first_name + " " + item?.last_name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.AgencySidebarIcon/>}>Agency
                    Name</TextWithIconComponent>,
                key: 'agency_name',
                dataIndex: 'name',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'facility-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.agencyDetails?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.agencyDetails?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.agencyDetails?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.agencyDetails?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.PhoneIcon/>}>Phone
                    Number</TextWithIconComponent>,
                key: 'user_phone_no',
                dataIndex: 'admin_contact_no',
                width: 150,
                render: (item: any) => {
                    return <>
                        {item?.contact_number}
                    </>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.EmailIcon/>}>Email
                    ID</TextWithIconComponent>,
                key: 'email_id',
                dataIndex: 'email_id_name',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.email)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.email}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.email}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.email || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent
                    prefix={<ImageConfig.NotificationPreferenceIcon/>}>Notification Preference</TextWithIconComponent>,
                key: 'notification-preference',
                dataIndex: 'notification-preference',
                align: 'left',
                width: 200,
                render: (item: any) => {
                    return <>
                        <div className={'d-flex'}>
                          <span>
                        {item?.notification_preferences?.isEmailRequired ? "Email" : ""}
                              {item?.notification_preferences?.isPush_notificationRequired ? (item?.notification_preferences?.isEmailRequired ? ", In App" : "In App") : ""}
                              {item?.notification_preferences?.isSmsRequired ? ((item?.notification_preferences?.isEmailRequired || item?.notification_preferences?.isPush_notificationRequired) ? ", SMS" : "SMS") : ""}
                      </span>
                        </div>
                    </>;
                }
            },
            {
                title: () => <TextWithIconComponent
                    prefix={<ImageConfig.CheckmarkRound/>}>Status</TextWithIconComponent>,
                key: 'status',
                dataIndex: 'status',
                align: 'left',
                width: 150,
                render: (item: any) => {
                    return <>
                        <TagComponent
                            color={item?.is_active ? CommonService.getStatusTagColor('Checked In') : CommonService.getStatusTagColor('Checked Out')}
                            // showIndicator={true}
                            label={item?.is_active ? 'Active' : 'Inactive'}/>
                    </>;
                }
            },
            {
                title: 'Activate/Deactivate',
                key: 'activate-deactivate',
                dataIndex: 'activate',
                align: 'center',
                width: 150,
                render: (item: any) => {
                    return <>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onAgencyUserActivateDeactivate(item);
                        }}/>
                    </>;
                }
            },
            {
                title: "Actions",
                dataIndex: "actions",
                align: 'center',
                key: "actions",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent onClick={() => {
                            setIsEditUserDetailsDrawerOpen(true);
                            setUserDetails(item);
                        }}>
                            <ImageConfig.PencilEditIcon/>
                        </LinkComponent>;
                    }
                }
            }
        ];
    }, [onAgencyUserActivateDeactivate]);

    const closeEditDrawer = useCallback(() => {
        setIsEditUserDetailsDrawerOpen(false);
    }, []);

    return (
        <>
            <DrawerComponent isOpen={isEditUserDetailsDrawerOpen} direction={"right"} onClose={closeEditDrawer}>
                <EditUserComponent filterData={activeTab === "facility-user" ? filterData : agencyFilterData}
                                   setFilterData={activeTab === "facility-user" ? setFilterData : setAgencyFilterData}
                                   userDetails={userDetails}
                                   closeEditDrawer={closeEditDrawer}
                                   selectedUserType={activeTab}
                />
            </DrawerComponent>
            <div className={'user-management-list-component header-screen mrg-top-25 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.UserModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title">User</div>
                        </div>
                    </div>
                    <div className="list-options">
                        {activeTab === "facility-user" &&
                            <div className={"mrg-top-20"}>
                                <SearchComponent
                                    size={'medium'}
                                    placeholder="Search facility name"
                                    value={filterData.search}
                                    onSearchChange={(value) => {
                                        setFilterData({...filterData, search: value, page: 1});
                                    }}
                                />
                            </div>}
                        {activeTab === "agency-user" &&
                            <div className={"mrg-top-20"}>
                                <SearchComponent
                                    size={'medium'}
                                    placeholder="Search agency name"
                                    value={agencyFilterData.search}
                                    onSearchChange={(value) => {
                                        setAgencyFilterData({...agencyFilterData, search: value, page: 1});
                                    }}
                                />
                            </div>
                        }
                        {activeTab === "facility-user" &&
                            <div>
                                <ButtonComponent color={'download-button'} variant={"contained"}
                                                 isLoading={isDownloading}
                                                 onClick={handleDownloadUsers}>
                                    Download CSV
                                </ButtonComponent>
                            </div>}
                        {activeTab === "agency-user" &&
                            <div>
                                <ButtonComponent color={'download-button'} variant={"contained"}
                                                 isLoading={isAgencyDownloading}
                                                 onClick={handleAgencyDownloadUsers}>
                                    Download CSV
                                </ButtonComponent>
                            </div>
                        }
                        <LinkComponent route={CommonService._routeConfig.AddUserManagementRoute()}>
                            <ButtonComponent color={'primary'} prefixIcon={<ImageConfig.AddOutlinedIcon/>}>
                                Add New User
                            </ButtonComponent>
                        </LinkComponent>

                    </div>
                </div>
                <TabsWrapperComponent>
                    <TabsComponent
                        value={activeTab}
                        allowScrollButtonsMobile={false}
                        onUpdate={(e: any, v: any) => {
                            setActiveTab(v);
                            setFilterData({...filterData, status: v, page: 1, search: ""});
                            setAgencyFilterData({...agencyFilterData, status: v, page: 1, search: ""});
                        }}
                    >
                        <TabComponent label="Facility User" value={"facility-user"}/>
                        <TabComponent label="Agency User" value={"agency-user"}/>
                    </TabsComponent>
                    <div className={'facility-list-component list-screen mrg-top-20'}>
                        <div className="list-screen-content">
                            <TabContentComponent value={activeTab} selectedTab={"facility-user"}>
                                <TableWrapperComponent url={APIConfig.USER_MANAGEMENT_LIST.URL}
                                                       method={APIConfig.USER_MANAGEMENT_LIST.METHOD}
                                                       isPaginated={true}
                                                       extraPayload={filterData}
                                                       noDataText={filterData.search ? 'Oops! It seems like there are no user available for the name you have searched.' : 'No Data'}
                                                       columns={UserManagementListColumn}
                                />
                            </TabContentComponent>
                            <TabContentComponent value={activeTab} selectedTab={"agency-user"}>
                                <TableWrapperComponent url={APIConfig.AGENCY_USER_MANAGEMENT_LIST.URL}
                                                       method={APIConfig.AGENCY_USER_MANAGEMENT_LIST.METHOD}
                                                       isPaginated={true}
                                                       extraPayload={agencyFilterData}
                                                       noDataText={agencyFilterData.search ? 'Oops! It seems like there are no user available for the name you have searched.' : 'No Data'}
                                                       columns={AgencyUserManagementListColumn}
                                />
                            </TabContentComponent>
                        </div>
                    </div>
                </TabsWrapperComponent>
            </div>
        </>
    );
};
export default UserManagementListComponent;
