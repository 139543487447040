import {useNavigate, useParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../shared/components/status-card/StatusCardComponent";
import CardComponent from "../../../shared/components/card/CardComponent";
import {APIConfig, ImageConfig, Misc,} from "../../../constants";
import {useDispatch} from "react-redux";
import {setCurrentNavParams} from "../../../store/actions/navigation.action";
import "./FacilityDetailsScreen.scss";
import DataLabelValueComponent from "../../../shared/components/data-label-value/DataLabelValueComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../shared/components/tabs/TabsComponent";
import EditFacilityComponent from "../edit-facility/EditFacilityComponent";
import EditAdminDetailsComponent from "../edit-facility/EditAdminDetailsComponent";
import DetailsHeaderComponent from "../../../shared/components/details-header-component/DetailsHeaderComponent";
import {FACILITY_lIST} from "../../../constants/RoutesConfig";
import TableComponent from "../../../shared/components/table/TableComponent";
import {ITableColumn} from "../../../shared/models/table.model";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import AddWingComponent from "../wings/add-wings/AddWingsComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import EditWingComponent from "../wings/edit-wing/EditWingsComponent";
import AddShiftTimings from "./AddShiftTimings";
import {shiftTypeLayoutRepresentation} from "../../../data";
import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import TextWithIconComponent from "../../../shared/components/text-with-icon/TextWithIconComponent";
import TagComponent from "../../../shared/components/tag/TagComponent";

const FacilityDetailsScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {facilityId} = useParams();
    const [facilityDetails, setFacilityDetails] = useState<any>(null);
    const [isFacilityDetailsLoading, setIsFacilityDetailsLoading] = useState<boolean>(false);
    const [isFacilityDetailsLoadingFailed, setIsFacilityDetailsLoadingFailed] = useState<boolean>(false);
    const [isFacilityDetailsLoaded, setIsFacilityDetailsLoaded] = useState<boolean>(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<any>(false);
    const [isEditAdminDrawerOpen, setIsEditAdminDrawerOpen] = useState<any>(false);
    const [activeTab, setActiveTab] = useState<any>("basic-details");
    const [isAddWingDrawerOpen, setIsAddWingDrawerOpen] = useState<boolean>(false);
    const [isEditWingDrawerOpen, setIsEditWingDrawerOpen] = useState<boolean>(false);
    const [selectedFacilityForEdit, setSelectedFacilityForEdit] = useState<any>(null);
    const [isAddShiftTimingsOpen, setIsAddShiftTimingsOpen] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<any>({
        search: '',
        sort: {},
        facility_id: facilityId
    });

    const openEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(true);
    }, []);

    const openAdminEditDrawer = useCallback(() => {
        setIsEditAdminDrawerOpen(true);
    }, []);

    const closeEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(false);
    }, []);

    const closeAdminEditDrawer = useCallback(() => {
        setIsEditAdminDrawerOpen(false);
    }, []);

    const getFacilityDetails = useCallback(() => {
        setIsFacilityDetailsLoading(true);
        const payload = {};
        if (facilityId) {
            CommonService._facility.FacilityDetailsAPICall(facilityId, payload)
                .then((response: IAPIResponseType<any>) => {
                    console.log(response.data);
                    setFacilityDetails(response.data);
                    setIsFacilityDetailsLoaded(true);
                    setIsFacilityDetailsLoadingFailed(false);
                }).catch((error: any) => {
                setIsFacilityDetailsLoaded(false);
                setIsFacilityDetailsLoadingFailed(true);
            }).finally(() => {
                setIsFacilityDetailsLoading(false);
            });
        }
    }, [facilityId]);

    useEffect((): void => {
        getFacilityDetails();
    }, [getFacilityDetails]);

    useEffect(() => {
        dispatch(setCurrentNavParams('Facility Details', null, () => {
            navigate(CommonService._routeConfig.FacilityRoute());
        }));
    }, [dispatch, navigate]);

    const onWingsActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  Wing`,
                image: item?.is_active ? ImageConfig.DeActivateWingIcon : ImageConfig.ActivateWingIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'deactivate' : 'activate'}  the
    wing?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: item?.is_active,
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._facility.ActivteDeactivateWings(facilityId, item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    getFacilityDetails();
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Cannot put this because api are on patch request", "error");
            });
        });
    }, [facilityId, getFacilityDetails]);

    const deleteShiftTimingsApiCall = useCallback((shiftTimingsId: any) => {
        const payload: any = {};
        CommonService._facility.deleteShiftTimings(facilityId, shiftTimingsId, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getFacilityDetails();
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error, "error");
        });
    }, [facilityId, getFacilityDetails]);

    const onShiftTimingsDelete = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `Delete this shift`,
                image: ImageConfig.ActivateDeactivateIcon,
                confirmationDescription: `Are you sure you want to delete this shift?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, Delete`,
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            deleteShiftTimingsApiCall(item?._id);
        });
    }, [deleteShiftTimingsApiCall]);

    const shiftTimingsColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: 'Shift Type',
                key: 'shift_type',
                dataIndex: 'shift_type',
                fixed: 'left',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    // console.log(item);
                    return <div className={'d-flex justify-content-center align-items-center'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}

                        {
                            (() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(shiftTypeObj => shiftTypeObj.code === item?.shift_type);
                                if (foundShiftType) {
                                    return <>
                                        <div
                                            className={'mrg-right-5 mrg-top-5'}>
                                            {foundShiftType.icon}
                                        </div>
                                        <div className={foundShiftType.color}>
                                            {item?.shift_type}
                                        </div>
                                    </>;
                                } else {
                                    return null; // or some default icon or message if shift type is not found
                                }
                            })()
                        }
                    </div>;
                }
            },
            {
                title: 'Shift Start Time',
                key: 'shift_start_time',
                dataIndex: 'shift_start_time',
                align: 'center',
                width: 150,
                render: (item: any) => {
                    return <>
                        <div>
                            {CommonService.convertMinutesToTime(item?.shift_start_time)}
                        </div>
                    </>;
                }
            },
            {
                title: "Shift End Time",
                dataIndex: "shift_end_time",
                align: 'center',
                key: "shift_end_time",
                width: 150,
                fixed: 'right',
                render: (item: any) => {
                    return <>
                        <div>
                            {CommonService.convertMinutesToTime(item?.shift_end_time)}
                        </div>
                    </>;
                }
            },
            {
                title: "",
                dataIndex: "delete",
                align: 'center',
                key: "delete",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    return <LinkComponent onClick={() => {
                        // Filter the list to exclude the item with the specified ID
                        onShiftTimingsDelete(item);
                    }
                    }>
                        <ImageConfig.DeleteIcon/>
                    </LinkComponent>;
                }
            }
        ];
    }, [onShiftTimingsDelete]);

    const deleteWingsApiCall = useCallback((wingId: any) => {
        const payload: any = {};
        CommonService._facility.deleteWings(wingId, facilityId, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getFacilityDetails();
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error, "error");
        });
    }, [getFacilityDetails,facilityId]);

    const onWingsDelete = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `Delete Wing`,
                image: ImageConfig.ActivateDeactivateIcon,
                confirmationDescription: `Are you sure you want to delete the
    wing?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: `Yes, Delete`,
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            deleteWingsApiCall(item?._id);
        });
    }, [deleteWingsApiCall]);

    const wingDetailsColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: 'Wing Name',
                key: 'wing_name',
                dataIndex: 'wing_list_name',
                // width: 150,
                render: (item: any) => {
                    return <>
                        {
                            (item?.name?.name)?.length > 20 ?
                                <ToolTipComponent
                                    tooltip={item?.name?.name}
                                    showArrow={true}
                                    position={"top"}
                                >
                                    <div
                                        className={"ellipses-for-table-data property-name icon-button"}>
                                        {item?.name?.name || item?.name}
                                    </div>
                                </ToolTipComponent> :
                                <div className="facility-name">
                                    {item?.name?.name || item?.name || "-"}
                                </div>
                        }
                    </>;
                }
            },
            {
                title: "",
                dataIndex: "delete",
                align: 'right',
                key: "delete",
                fixed: 'right',
                render: (item: any) => {
                    return <LinkComponent onClick={() => {
                        // Filter the list to exclude the item with the specified ID
                        onWingsDelete(item);
                    }
                    }>
                        <ImageConfig.DeleteIcon/>
                    </LinkComponent>;
                }
            },

            {
                title: '',
                key: 'activate-deactivate',
                dataIndex: 'activate',
                align: 'right',
                //    width: 150,
                render: (item: any) => {

                    return <>
                        <CustomToggleComponent toggleHandler={() => {
                            onWingsActivateDeactivate(item);
                        }} defaultOn={item?.is_active}/>
                    </>;
                }
            },

            {
                title: "",
                dataIndex: "actions",
                align: 'center',
                key: "actions",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent onClick={() => {
                            setSelectedFacilityForEdit(item);
                            setIsEditWingDrawerOpen(true);
                        }}>
                            <ImageConfig.PencilEditIcon/>
                        </LinkComponent>;
                    }
                }
            }

        ];
    }, [onWingsDelete, onWingsActivateDeactivate]);

    const handleCloseAddWingDrawer = useCallback(() => {
        setIsAddWingDrawerOpen(false);
    }, []);

    const handleCloseEditWingDrawer = useCallback(() => {
        setIsEditWingDrawerOpen(false);
    }, []);

    const handleCloseAddShiftDrawer = useCallback(() => {
        setIsAddShiftTimingsOpen(false);
    }, []);

    const onAgencyActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  Agency`,
                //image: item?.is_active ? ImageConfig.D : ImageConfig.ActivateFacilityIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'deactivate' : 'activate'}  the
    Agency?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: item?.is_active,
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._agency.ActivateDeactivateAgencyFromList(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setFilterData({...filterData});
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, [filterData]);

    const onFacilityActivateDeactivate = useCallback((isActive: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${isActive ? 'Deactivate' : 'Activate'}  Facility`,
                image: isActive ? ImageConfig.DeactivateFacilityIcon : ImageConfig.ActivateFacilityIcon,
                confirmationDescription: `Are you sure you want to ${isActive ? 'deactivate' : 'activate'}  the
    facility?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: isActive,
                yes: {
                    color: "error",
                    text: `Yes, ${isActive ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !isActive;
            CommonService._facility.ActivateDeactivateFacility(facilityDetails?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    getFacilityDetails();
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, [getFacilityDetails, facilityDetails?._id]);

    const FacilityListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.FacilitySidebarIcon/>}>Agency
                    Name</TextWithIconComponent>,
                key: 'facility_name',
                dataIndex: 'name',
                fixed: 'left',
                // sortable: true,
                width: 220,
                render: (item: any) => {
                    return <div className={'facility-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div
                                            className={"ellipses-for-table-data property-name icon-button"}>
                                            {item?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },

            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.SchedulerSidebarIcon/>}>Onboarded
                    On</TextWithIconComponent>,
                key: 'onboarded_on',
                dataIndex: 'onboarded_on',
                width: 100,
                align: 'left',
                render: (item: any) => {
                    if (item?.created_at) {
                        return <>{CommonService.convertDateFormat2(item?.created_at, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },

            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.SchedulerSidebarIcon/>}>Contract Expiry
                    Date</TextWithIconComponent>,
                key: 'contarct_on',
                dataIndex: 'contract_on',
                width: 150,
                align: 'left',
                render: (item: any) => {
                    if (item?.created_at) {
                        return <>{item?.FacilityAgencyData?.contract_expiry_date ? CommonService.convertDateFormat2(item?.FacilityAgencyData?.contract_expiry_date, "MM-DD-YYYY") : "-"}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.PersonProfileOutline/>}>Admin
                    Name</TextWithIconComponent>,
                key: 'admin',
                dataIndex: 'admin',
                width: 180,
                render: (item: any) => {
                    return <div className={'agency-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.admin_details?.first_name + " " + item?.admin_details?.last_name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.admin_details?.first_name + " " + item?.admin_details?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.admin_details?.first_name + " " + item?.admin_details?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="agency-name">
                                        {item?.admin_details?.first_name + " " + item?.admin_details?.last_name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.PhoneIcon/>}>Admin Contact
                    No</TextWithIconComponent>,
                key: 'admin_contact_no',
                dataIndex: 'admin_contact_no',
                width: 150,
                render: (item: any) => {
                    return <>
                        {item?.admin_details?.contact_number}
                    </>;
                }
            },
            {
                title: () => <TextWithIconComponent
                    prefix={<ImageConfig.CheckmarkRound/>}>Status</TextWithIconComponent>,
                key: 'status',
                dataIndex: 'status',
                align: 'center',
                width: 100,
                render: (item: any) => {
                    return <>
                        <TagComponent
                            color={item?.is_active ? CommonService.getStatusTagColor('Checked In') : CommonService.getStatusTagColor('Checked Out')}
                            label={item?.is_active ? 'Active' : 'Inactive'}/>
                    </>;
                }
            },
            {
                title: 'Activate/Deactivate',
                key: 'activate-deactivate',
                dataIndex: 'activate',
                align: 'center',
                width: 150,
                render: (item: any) => {
                    return <>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onAgencyActivateDeactivate(item);
                        }}/>
                    </>;
                }
            },
        ];
    }, [onAgencyActivateDeactivate]);

    return (
        <>
            <DrawerComponent isOpen={isAddWingDrawerOpen} onClose={handleCloseAddWingDrawer}>
                <AddWingComponent getFacilityDetails={getFacilityDetails}
                                  closeEditDrawer={handleCloseAddWingDrawer}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isAddShiftTimingsOpen} onClose={handleCloseAddShiftDrawer}>
                <AddShiftTimings closeEditDrawer={handleCloseAddShiftDrawer} getFacilityDetails={getFacilityDetails}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isEditWingDrawerOpen} onClose={handleCloseEditWingDrawer}>
                <EditWingComponent getFacilityDetails={getFacilityDetails}
                                   selectedFacilityForEdit={selectedFacilityForEdit}
                                   closeEditDrawer={handleCloseEditWingDrawer}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isEditDrawerOpen} direction={"right"} onClose={closeEditDrawer}>
                <EditFacilityComponent getFacilityDetails={getFacilityDetails} closeEditDrawer={closeEditDrawer}
                                       facilityDetails={facilityDetails}/>
            </DrawerComponent>
            <DrawerComponent isOpen={isEditAdminDrawerOpen} direction={"right"} onClose={closeAdminEditDrawer}>
                <EditAdminDetailsComponent facilityDetails={facilityDetails} closeEditDrawer={closeAdminEditDrawer}
                                           getFacilityDetails={getFacilityDetails}/>
            </DrawerComponent>
            {
                isFacilityDetailsLoading && <div className="h-v-center">
                    <LoaderComponent type={'spinner'}/>
                </div>
            }
            {
                isFacilityDetailsLoadingFailed &&
                <div className="h-v-center">
                    <StatusCardComponent title={'Failed to Load'}/>
                </div>
            }
            {
                isFacilityDetailsLoaded && <>
                    <div className={"facility-details-component-wrapper"}>
                        <DetailsHeaderComponent navigate={navigate} details={facilityDetails} backAddress={FACILITY_lIST}
                                                detailsModule={'Facility'}/>
                        <TabsWrapperComponent className={'mrg-top-25'}>
                            <div className={'d-flex justify-content-space-between align-items-center'}>
                            <TabsComponent
                                value={activeTab}
                                allowScrollButtonsMobile={false}
                                onUpdate={(e: any, v: any) => {
                                    setActiveTab(v);
                                }}
                            >
                                <TabComponent label="Basic Details" value={"basic-details"}/>
                                <TabComponent label="Wing Details" value={"wing-details"}/>
                                <TabComponent label="Shift Timings" value={"shift-timings"}/>
                                <TabComponent label="Linked Agency" value={"linked-agencies"}/>

                            </TabsComponent>
                                {activeTab === "basic-details" &&
                                    <>
                                        <ButtonComponent
                                            className={"center-element add-button-text mrg-right-100"}
                                            variant={'outlined'} color={'error'} onClick={() => {
                                            onFacilityActivateDeactivate(facilityDetails?.is_active);
                                        }}>{facilityDetails?.is_active ? "Deactivate Facility" : "Activate Facility"}</ButtonComponent>
                                    </>
                                }
                                {activeTab === "wing-details" &&
                                    <ButtonComponent
                                        prefixIcon={<ImageConfig.AddOutlinedIcon/>}
                                        className={"center-element add-button-text mrg-right-100"} onClick={() => {
                                        setIsAddWingDrawerOpen(true);
                                    }}>Add New
                                        Wing</ButtonComponent>}
                                {activeTab === "shift-timings" &&
                                    <ButtonComponent
                                        prefixIcon={<ImageConfig.AddOutlinedIcon/>}
                                        className={"center-element add-button-text mrg-right-100"} onClick={() => {
                                        setIsAddShiftTimingsOpen(true);
                                    }}>Add Shift Timings</ButtonComponent>
                                }
                            </div>
                            <TabContentComponent selectedTab={activeTab} value={"basic-details"}>
                                <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100 mrg-top-10"}>
                                    <div className={'details-screen'}>
                                        <div className="details-screen-title-with-image-wrapper pdd-10">
                                            <div
                                                className={'details-screen-title-wrapper center-right-element mrg-left-20 mrg-right-20'}
                                            >
                                                <div className={'details-screen-title'}
                                                >Facility
                                                    Details
                                                </div>
                                                <div onClick={openEditDrawer} className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Facility Details</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"pdd-left-25 pdd-right-25 pdd-top-5"}>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Facility Name'}>
                                                        {facilityDetails?.name || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Phone Number'}>
                                                        {facilityDetails?.contact_number || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Email ID'}>
                                                        {facilityDetails?.email || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Address Line'}>
                                                        {`${facilityDetails?.address?.street}, ${facilityDetails?.address?.city}, ${facilityDetails?.address?.state}, ${facilityDetails?.address?.zip_code}, ${facilityDetails?.address?.region}, ${facilityDetails?.address?.country}` || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Timezone'}>
                                                        {CommonService.getTimezoneTitleByCode(facilityDetails?.timezone) || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Region'}>
                                                        {
                                                            facilityDetails?.address?.region
                                                        }
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Domain Name'}>
                                                        <div>
                                                            {facilityDetails?.domain || '-'}
                                                        </div>
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>
                                            <div className={"ts-row"}>
                                                <div className={'ts-col-lg-6'}>
                                                    <DataLabelValueComponent label={'About the Facility'}>
                                                        {facilityDetails?.about || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardComponent>
                                <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>
                                    <div className={'details-screen'}>
                                        <div
                                            className="details-screen-title-with-image-wrapper pdd-10">
                                            <div
                                                className={'details-screen-title-wrapper center-right-element mrg-left-20 mrg-right-20'}>
                                                <div className={'details-screen-title'}>Admin Details
                                                </div>
                                                <div onClick={openAdminEditDrawer} className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Admin Details</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"pdd-left-25 pdd-right-25 pdd-top-5"}>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Admin Name'}>
                                                        {facilityDetails?.admin_details?.first_name + " " + facilityDetails?.admin_details?.last_name || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Phone Number'}>
                                                        {facilityDetails?.admin_details?.contact_number || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Email ID'}>
                                                        {facilityDetails?.admin_details?.email || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Notifications Preference'}>
                                                        {(facilityDetails?.admin_details?.notification_preferences?.isSmsRequired ? "SMS, " : "") + (facilityDetails?.admin_details?.notification_preferences?.isEmailRequired ? "Email, " : "") + (facilityDetails?.admin_details?.notification_preferences?.isPush_notificationRequired ? "In App " : "") || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardComponent>
                            </TabContentComponent>
                            <TabContentComponent selectedTab={activeTab} value={"wing-details"}>

                                <div className={"mrg-left-100  mrg-right-100"}>
                                    <div className={'shift-timings-wrapper'}>
                                        <TableComponent columns={wingDetailsColumn} noDataText={"no data"}
                                                        data={facilityDetails?.wings}/>
                                    </div>
                                    {/*<WingsListComponent facilityId={facilityId}/>*/}
                                </div>
                            </TabContentComponent>
                            <TabContentComponent selectedTab={activeTab} value={"shift-timings"}>
                                <div className={"mrg-left-100  mrg-right-100"}>
                                    <div className={'shift-timings-wrapper'}>
                                        <TableComponent columns={shiftTimingsColumn} noDataText={"no data"}
                                                        data={facilityDetails?.shift_timings}/>
                                    </div>
                                </div>
                            </TabContentComponent>
                            <TabContentComponent selectedTab={activeTab} value={"linked-agencies"}>
                                <div className={"mrg-left-100  mrg-right-100"}>
                                    <div className={'shift-timings-wrapper'}>
                                        <TableWrapperComponent url={APIConfig.LINKED_AGENCY_LIST_FOR_FACILITY.URL}
                                                               method={APIConfig.LINKED_AGENCY_LIST_FOR_FACILITY.METHOD}
                                                               isPaginated={true}
                                                               extraPayload={filterData}
                                                               columns={FacilityListColumn}
                                        />
                                    </div>
                                </div>
                            </TabContentComponent>
                        </TabsWrapperComponent>
                    </div>
                </>
            }
        </>
    );
};
export default FacilityDetailsScreen;
