import Communications from './communications.service';
import queryString from "query-string";
import {FormikErrors} from "formik";
import axios, {CancelTokenSource} from "axios";
import ApiService from './api.service';
import moment, {Moment} from "moment";
import AlertService from "./alert.service";
import RouteConfigService from "./route-config.service";
import AccountService from "./modules/account.service";
import LocalStorageService from "./local-storage.service";
import {v4 as uuidv4} from 'uuid';
import StaticDataService from "./modules/static-data.service";
import * as yup from "yup";
import {IConfirmationConfig} from "../models/confirmation.model";
import _ from "lodash";
import printJS from "print-js";
import {IAttachment} from "../models/common.model";
import FacilityService from "./modules/facility.service";
import AgencyService from "./modules/agency.service";
import MetaDataService from "./modules/meta.service";
import HcpManagementService from "./modules/hcp-management.service";
import ShiftManagementService from "./modules/shift-management.service";
import {americanTimeZone} from "../../data";
import UserManagementService from "./modules/user-management.service";
import {ImageConfig} from "../../constants";


yup.addMethod(yup.mixed, 'atLeastOne', (args) => {
    const {message} = args;
    // @ts-ignore
    return this.test('atLeastOne', message, (list: any) => {
        // If there are 2+ elements after filtering, we know atMostOne must be false.
        return Object.keys(list).filter(item => item).length > 0;
    });
});

const CurrentDate: Moment = moment();

const parseQueryString = (q: string): any => {
    return queryString.parse(q.replace('?', ''));
};

const getBytesInMB = (bytes: number) => {
    return bytes / (1024 * 1024);
};

const formatSizeUnits = (bytes: number, decimals = 2) => {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const getRandomID = (length: number = 10) => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

const getUUID = () => {
    return uuidv4();
};

const handleErrors = ((setErrors: (errors: FormikErrors<any>) => void, err: any, showGlobalError: boolean = false) => {
    if (err.errors) {
        const errors: any = {};
        for (let field in err.errors) {
            const error = err.errors[field];
            if (err.errors.hasOwnProperty(field)) {
                errors[field] = error;
                // AlertService.showToast(error);s
            }
        }
        setErrors(errors);
    }
    // if (err.error) {
    //     AlertService.showToast(err.error);
    // } else {
    //     if (showGlobalError) {
    //         AlertService.showToast('Form contain errors, please check once', 'error');
    //     }
    // }

});

const openDialog = (component: any) => {
    return new Promise((resolve, reject) => {
        Communications.DialogStateSubject.next({component, promise: {resolve, reject}});
    });
};

const getCancelToken = (): CancelTokenSource => {
    return axios.CancelToken.source();
};

const getPayloadFilterDates = (mode: 'day' | 'week' | 'month' | 'year'): { start_date: string, end_date: string } => {
    const payload = {start_date: '', end_date: moment().endOf('day').format('YYYY-MM-DD')};
    switch (mode) {
        case "day":
            payload.start_date = moment().startOf('day').format('YYYY-MM-DD');
            break;
        case "week":
            payload.start_date = moment().subtract(1, 'week').startOf('day').format('YYYY-MM-DD');
            break;
        case "month":
            payload.start_date = moment().subtract(1, "month").startOf('day').format('YYYY-MM-DD');
            break;
        case "year":
            payload.start_date = moment().subtract(1, "year").startOf('day').format('YYYY-MM-DD');
            break;
    }
    return payload;
};

const transformTimeStamp = (date: Date | string | undefined) => {
    return moment(date).format('D-MMM-YYYY | hh:mm A');
};

const transformTimeStamp2 = (date: Date | string | undefined) => {
    return moment(date).format('hh:mm A');
};


const convertDateFormat = (date: Date, format: string = 'MM-DD-YYYY') => {
    // return moment(date).utc().format(format);
    if (format === 'Do MMMM YYYY') {
        const momentDate = moment(date);
        const dayWithSuffix = momentDate.format('Do');
        const formattedDate = momentDate.format(format);

        // Replace day without suffix with day with suffix in the formatted date
        return formattedDate.replace(dayWithSuffix, `${dayWithSuffix}`);
    } else {
        return moment(date).format(format);
    }
};


// const convertDateFormatInExpandedForm = (date: Date, format = 'Do MMMM YYYY') => {
//     const momentDate = moment(date);
//     const dayWithSuffix = momentDate.format('Do');
//     const formattedDate = momentDate.format(format);
//
//     // Replace day without suffix with day with suffix in the formatted date
//     return formattedDate.replace(dayWithSuffix, `${dayWithSuffix}th`);
// };


const convertDateFormat2 = (date: Date, format: string = 'MM-DD-YYYY') => {
    return moment(date).utc().format(format);
};

const generateUseCaseFromCaseDetails = (case_details: any) => {
    return `${CommonService.convertDateFormat2(case_details?.case_date)} - ${case_details?.injury_details?.map((bodyPart: any, index: number) => {
        return (bodyPart?.body_part_details + (bodyPart?.body_side ? `( ${bodyPart.body_side} )` : ' ') + (index !== case_details?.injury_details?.length - 1 ? ' | ' : ''));
    })} `;
};

const generateInterventionNameFromMedicalRecord = (medicalRecordDetails: any) => {
    return `${medicalRecordDetails?.injury_details?.map((bodyPart: any, index: number) => {
        return (bodyPart?.body_part_details?.name + (index === medicalRecordDetails?.injury_details?.length - 1 ? '' : '/'));
    })} - ${CommonService.convertDateFormat2(medicalRecordDetails?.created_at)}`;
};

const getTheDifferenceBetweenDates = (fromDate: string) => {
    let a = moment();
    let b = moment(moment(fromDate), 'YYYY');
    let diff = a.diff(b, 'years');
    return diff;
};


const getFlatJsonFromNestedJSON = (jsonData: any, rootName: string = "", ignoreList: any[] = []): any => {

    const appendFormData = (data: any, root: any) => {
        let newObj: any = {};
        let tmp: any = {};
        if (!ignore(root)) {
            root = root || '';
            if (data instanceof File) {
                newObj[root] = data;
            } else if (Array.isArray(data)) {
                for (let i = 0; i < data.length; i++) {
                    tmp = appendFormData(data[i], root + '[' + i + ']');
                    newObj = {...newObj, ...tmp};
                }
            } else if (typeof data === 'object' && data) {
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            tmp = appendFormData(data[key], key);
                            newObj = {...newObj, ...tmp};
                        } else {
                            tmp = appendFormData(data[key], root + '.' + key);
                            newObj = {...newObj, ...tmp};
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    newObj[root] = data;
                }
            }
        }
        return newObj;
    };

    const ignore = (root: any) => {
        return Array.isArray(ignoreList)
            && ignoreList.some((x) => {
                return x === root;
            });
    };

    return appendFormData(jsonData, rootName);
};

const getFormDataFromJSON = (obj: any, rootName = '', ignoreList = []): FormData => {

    const formData = new FormData();

    const appendFormData = (data: any, root: any) => {
        if (!ignore(root)) {
            root = root || '';
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                for (let i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + '[' + i + ']');
                }
            } else if (typeof data === 'object' && data) {
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + '.' + key);
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                }
            }
        }
    };

    const ignore = (root: any) => {
        return Array.isArray(ignoreList)
            && ignoreList.some((x: any) => {
                return x === root;
            });
    };

    appendFormData(obj, rootName);

    return formData;
};

const capitalizeFirstLetter = (string: string | undefined) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const kFormatter = (num: any) => {
    // @ts-ignore
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
};

const getLettersFromNumber = (number: number) => {
    const previousLetters: any = (number >= 26 ? getLettersFromNumber(Math.floor(number / 26) - 1) : '');
    const lastLetter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[number % 26];
    return previousLetters + lastLetter;
};

const getRandomNumberBetweenRange = (startingNumber: number, endingNumber: number) => {
    startingNumber = Math.ceil(startingNumber);
    endingNumber = Math.floor(endingNumber);
    return Math.floor(Math.random() * (endingNumber - startingNumber + 1)) + startingNumber;
};

const getMinsAndSecondsFromSeconds = (numberOfSeconds: number) => {
    const minutes = Math.floor(numberOfSeconds / 60);
    const seconds = (numberOfSeconds % 60);
    return {
        minutes: minutes < 10 ? '0' + minutes : minutes,
        seconds: seconds < 10 ? '0' + seconds : seconds
    };
};

const getHoursAndMinutesFromMinutes = (minutes: number) => {
    return moment().startOf('day').add(minutes, 'minutes').format('h:mm a');
};

const downloadFile = (url: string, fileName: any, type = 'pdf') => {
    console.log(type);
    switch (type) {
        case 'image':
            axios.get(url, {
                headers: {'Content-Type': 'image/*'}
            }).then(
                (data: any) => {
                    const blob = new Blob([data], {
                        type: 'image/*' // must match the Accept type
                    });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }, (error) => {
                    console.log('error');
                });
            break;
        case 'pdf':
            axios.get(url, {
                headers: {Accept: 'application/pdf'}, responseType: 'arraybuffer'
            }).then(
                (data: any) => {
                    const blob = new Blob([data.data], {
                        type: 'application/pdf' // must match the Accept type
                    });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                }, (error) => {
                    console.log('error');
                });
            break;
        case 'csv':
            console.log(fileName);
            axios.get(url, {
                headers: {Accept: 'application/csv'}, responseType: 'blob'
            }).then(
                (response: any) => {
                    const blob = new Blob([response.data], {
                        type: "application/octet-stream" // or Content-type: "application/vnd.ms-excel"
                    });
                    console.log(blob);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                }, (error) => {
                    console.log('error');
                });
            break;
        case 'xlsx':
            axios.get(url, {
                headers: {Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
                responseType: 'blob'
            }).then(
                (response: any) => {
                    const blob = new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                }, (error) => {
                    console.log('error');
                });
            break;
        default:
            console.log(type, ' file download not supported');
    }
};

const generateBlobFileFromUrl = (attachmentUrl: string, attachmentTitle: string, attachmentType: string) => {
    return fetch(attachmentUrl)
        .then((res) => res.blob())
        .then((myBlob) => {
            const myFile = new File([myBlob], attachmentTitle, {
                type: attachmentType
            });
            return myFile;
        });
};

const getFlatQuestionList = (stepData: any): any => {
    const flatQuestionList: any = [];
    const questionListParser = (arrayData: []) => {
        arrayData.forEach((option: any, index, array) => {
            if (option?.questions) {
                option?.questions.forEach((question: any) => {
                    flatQuestionList.push(question);
                    if (question?.options) {
                        questionListParser(question?.options);
                    }
                });
            }
        });
    };
    if (stepData?.questions) {
        stepData?.questions.forEach((value: any) => {
            flatQuestionList.push(value);
            if (value?.options) {
                questionListParser(value?.options);
            }
        });
    }
    return flatQuestionList;

};

const getArrayOfValuesOfOneKeyFromJSON = (array: any[], key: string) => {
    if (array && array.length > 0) {
        const arrayWithDesiredKeyValue = array.map((item) => {
            return item[key];
        });
        return arrayWithDesiredKeyValue;
    } else {
        return [];
    }
};

const onConfirm = (config: IConfirmationConfig = {}) => {
    return new Promise((resolve, reject) => {
        Communications.ConfirmStateSubject.next({config, promise: {resolve, reject}});
    });
};

const Capitalize = (str: string) => {
    const capitalize = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    return str.split(' ').map(capitalize).join(' ');
};

const convertUpperCase = (str: string) => {
    return str.toUpperCase();
};

const getObjectKeyValue = (object: any, key: string) => {
    return _.get(object, key);
};

const getSystemFormatTimeStamp = (date: Date | string, showTime: boolean = false) => {
    if (!date) {
        return "N/A";
    }
    if (showTime) {
        return moment(date).format('DD-MMM-YYYY hh:mm A');
    } else {
        return moment(date).format('DD-MMM-YYYY');
    }
};

const removeKeysFromJSON = (obj: any, keys: string[]): any => {
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            switch (typeof (obj[prop])) {
                case 'object':
                    if (keys.indexOf(prop) > -1) {
                        delete obj[prop];
                    } else {
                        removeKeysFromJSON(obj[prop], keys);
                    }
                    break;
                default:
                    if (keys.indexOf(prop) > -1) {
                        delete obj[prop];
                    }
                    break;
            }
        }
    }
    return obj;
};

const isEqual = (a: any, b: any) => {
    return _.isEqual(a, b);
};

const formatPhoneNumber = (phone: string) => {
    const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (x) {
        phone = !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '-' + x[3] : '');
    }
    return phone;
};

const extractName = (data: any) => {
    return (data?.first_name || data?.last_name ? data?.last_name + ', ' + data?.first_name : '-');
};

const getNormalizedFileType = (fileType: any) => {
    console.log(fileType);
    let type: any = fileType;
    if (type.includes('image')) {
        type = "image";
    } else if (type.includes('pdf')) {
        type = "pdf";
    } else if (type.includes('word')) {
        type = "word";
    } else if (type.includes('spreadsheet')) {
        type = "xls";
    } else if (type.includes('video')) {
        type = "video";
    } else {
        type = "application";
    }
    return type;
};

const printAttachment = (attachment: IAttachment) => {
    let type: any = getNormalizedFileType(attachment?.type);
    printJS({
        printable: attachment.url,
        type: type
    });
};

const openLinkInNewTab = (url: string) => {
    window.open(url, '_blank');
};

const isTextEllipsisActive = (e: HTMLDivElement) => {
    return (e.offsetWidth < e.scrollWidth);
};

const getNameInitials = (name: string) => {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

const getContrastYIQ = (hexcolor: string) => {
    const r = parseInt(hexcolor.substring(1, 3), 16);
    const g = parseInt(hexcolor.substring(3, 5), 16);
    const b = parseInt(hexcolor.substring(5, 7), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
};

const LightenDarkenColor = (col: any, amt: number) => {
    col = parseInt(col, 16);
    return (((col & 0x0000FF) + amt) | ((((col >> 8) & 0x00FF) + amt) << 8) | (((col >> 16) + amt) << 16)).toString(16);
};

const ComingSoon = () => {
    AlertService.showToast("Coming Soon", 'info');
};

const cleanMentionsPayload = (value: string, mentionsData: any) => {
    const ids = mentionsData?.map((item: any) => item.id);
    let cleanedValue: string = value;
    if (ids.length) {
        ids.forEach((id: any) => {
            cleanedValue = cleanedValue?.replaceAll(new RegExp(`\\(${id}\\)`, 'g'), '');
        });
    }
    cleanedValue = cleanedValue?.split('\n').join("\\n");
    return cleanedValue;
};

const cleanMentionsResponse = (value: string, mentionsData: any) => {
    const ids = mentionsData?.map((item: any) => item.id);
    let cleanedValue: string = value;
    if (ids?.length) {
        ids.forEach((id: any) => {
            cleanedValue = cleanedValue?.replaceAll(`@[${id}]`, `@${id}`);
        });
    }
    cleanedValue = cleanedValue?.split('\\n').join("<br />");
    return cleanedValue || '-';
};

const editMentionsFormat = (value: string, mentionsData: any) => {
    const ids = mentionsData?.map((item: any) => item.id);
    let cleanedValue: string = value;
    if (ids?.length) {
        ids?.forEach((id: any) => {
            cleanedValue = cleanedValue?.split(`@[${id}]`).join(`@[${id}](${id})`);
        });
    }
    cleanedValue = cleanedValue?.split('\\n').join("\n");
    return cleanedValue;
};

const convertToDecimals = (value: number) => {
    return value.toFixed(2);
};

const getStatusTagColor = (status: string) => {
    switch (status) {
        case 'Checked In':
            return 'success';
        case 'Yet to Arrive':
            return 'primary';
        case 'Rejected':
            return 'danger';
        case 'Checked Out':
            return 'warning';
        case 'Cancelled':
            return 'default';
        case 'Active' :
            return 'success';
        default:
            return 'primary';
    }
};

const handleRedirectToLocationUrl = (locationUrl: any) => {
    window.open(locationUrl, '_blank');
};

const convertToAdjustedMinutes24Hour = (isoTimestamp: any) => {
    const unixTimestamp = new Date(isoTimestamp).getTime() / 1000; // Convert to seconds
    const minutesSinceEpoch = unixTimestamp / 60; // Convert to minutes
    // Ensure less than 1440 (24 hours)
    return minutesSinceEpoch % 1440;
};

const convertToAdjustedMinutes = (isoTimestamp: any) => {
    // console.log(isoTimestamp);
    // const date = moment(isoTimestamp);
    // // const minutesSinceMidnight = (date.getUTCHours() * 60 + date.getUTCMinutes() + 330);
    // const minutesSinceMidnight = date.hours() * 60 + date.minutes() + 330;
    // if (minutesSinceMidnight > 1440) {// Adjust for the GMT offset in minutes
    //     return minutesSinceMidnight - 1440;
    // } else {
    //     return minutesSinceMidnight;
    // }
    // console.log(isoTimestamp);
    // const date = moment.utc(isoTimestamp);
    // console.log(date);
    // const minutesSinceMidnight = date.hours() * 60 + date.minutes();
    //
    // return minutesSinceMidnight;

    const date = moment(isoTimestamp, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)');
    return date.hours() * 60 + date.minutes();
};

const convertMinutesToTime = (minutes: any) => {
    const hours = Math.floor(minutes / 60);
    const minutesPart = minutes % 60;
    const amOrPm = hours < 12 ? 'AM' : 'PM';
    const formattedHours = hours % 12 || 12; // Ensure 12-hour format

    return `${formattedHours}:${String(minutesPart).padStart(2, '0')} ${amOrPm}`;
};
const convertMinutesToTimeInCalender = (minutes: any) => {
    const hours = Math.floor(minutes / 60);
    const minutesPart = minutes % 60;
    const amOrPm = hours < 12 ? 'a' : 'p';
    const formattedHours = hours % 12 || 12; // Ensure 12-hour format
    if (minutesPart === 0) {
        return `${formattedHours}${amOrPm}`;
    } else {
        return `${formattedHours}:${String(minutesPart).padStart(2, '0')}${amOrPm}`;
    }
};
const transformName = (name: string) => {
    const words = name.split('_');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
};

const getFirstDayAndLastDayUsingDate = () => {
    const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return [firstDay, lastDay, date];

};

const previewPDF = (url: any) => {
    //  const url = "";
    //will integrate the url once getting from backend
    if (url) {
        window.open(url, '_blank');
    } else {
        CommonService._alert.showToast("No Url Found", 'error');
    }
};

function getTimezoneTitleByCode(timezoneCode: any) {
    const timeZoneEntry = americanTimeZone.find((entry) => entry.code === timezoneCode);
    return timeZoneEntry ? timeZoneEntry.title : null;
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const onExitFlowHandling = (navigate: any, route: any) => {
    CommonService.onConfirm(
        {
            confirmationTitle: "Exit Onboarding?",
            image: ImageConfig.ExitConfirmationIcon,
            confirmationDescription: "Your data will not be saved.<br />Are you sure you want to exit the flow?",
            no: {
                color: "default",
                text: "No, Go Back",
                variant: "outlined"
            },
            activationType: true,
            yes: {
                color: "error",
                text: `Yes, Exit`,
                variant: "contained",
            }
        }
    ).then((result: any) => {
        navigate(route);
    });
};

const convertTimeToMinutes = (timeString: any) => {
    console.log(timeString);
    const [time, period] = timeString?.split(' ');
    const [hours, minutes] = time?.split(':').map(Number);
    let totalMinutes = hours * 60 + minutes;

    if (period === 'PM' && hours !== 12) {
        totalMinutes += 12 * 60;
    } else if (period === 'AM' && hours === 12) {
        totalMinutes -= 12 * 60;
    }

    return totalMinutes;
};

const convertToSnakeCase = (input: string): string => {
    return input?.toLowerCase()?.replace(/ /g, '_');
};

const convertAndCapitalize = (input: string): string[] => {
    return input
        .split('_') // Split the string by underscores
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()); // Capitalize each word
};

const formatDate = (dateString: any) => {
    console.log(dateString)
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const isToday = (dateString: string): boolean => {
    const date = moment(dateString, 'DD-MMM-YYYY');
    return date.isSame(moment(), 'day');
};

const calculateSum = (perDiems: any) => {
    return perDiems.reduce((sum: any, item: any) => sum + parseFloat(item?.total), 0);
};

const calculateAge = (dobString: string): number => {
    const dob = new Date(dobString);
    const now = new Date();

    let age = now.getFullYear() - dob.getFullYear();
    const monthDifference = now.getMonth() - dob.getMonth();

    // If the current date is before the birth date, subtract one year from age
    if (monthDifference < 0 || (monthDifference === 0 && now.getDate() < dob.getDate())) {
        age--;
    }

    return age;
};

function formatDateTime(isoString: string): string {
    const date = new Date(isoString);

    // Extracting components of the date
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();

    // Extracting components of the time
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

    return `${month}-${day}-${year} | ${hours}:${minutes}${ampm}`;
}

const CommonService = {
    LightenDarkenColor,
    getContrastYIQ,
    getNameInitials,
    CurrentDate,
    parseQueryString,
    handleErrors,
    openDialog,
    onConfirm,
    formatSizeUnits,
    getRandomID,
    getBytesInMB,
    getCancelToken,
    getPayloadFilterDates,
    getFormDataFromJSON,
    capitalizeFirstLetter,
    kFormatter,
    getUUID,
    getLettersFromNumber,
    getRandomNumberBetweenRange,
    getMinsAndSecondsFromSeconds,
    convertDateFormat,
    downloadFile,
    generateBlobFileFromUrl,
    getFlatJsonFromNestedJSON,
    getFlatQuestionList,
    getArrayOfValuesOfOneKeyFromJSON,
    Capitalize,
    getObjectKeyValue,
    getHoursAndMinutesFromMinutes,
    getSystemFormatTimeStamp,
    transformTimeStamp,
    getTheDifferenceBetweenDates,
    removeKeysFromJSON,
    isEqual,
    convertDateFormat2,
    formatPhoneNumber,
    generateInterventionNameFromMedicalRecord,
    extractName,
    printAttachment,
    getNormalizedFileType,
    openLinkInNewTab,
    isTextEllipsisActive,
    ComingSoon,
    generateUseCaseFromCaseDetails,
    cleanMentionsPayload,
    cleanMentionsResponse,
    editMentionsFormat,
    convertToDecimals,
    getStatusTagColor,
    convertUpperCase,
    handleRedirectToLocationUrl,
    transformTimeStamp2,
    convertToAdjustedMinutes,
    transformName,
    convertMinutesToTime,
    convertToAdjustedMinutes24Hour,
    getFirstDayAndLastDayUsingDate,
    previewPDF,
    getTimezoneTitleByCode,
    onExitFlowHandling,
    convertTimeToMinutes,
    convertMinutesToTimeInCalender,
    convertToSnakeCase,
    convertAndCapitalize,
    formatDate,
    isToday,
    calculateSum,
    calculateAge,
    formatDateTime,
    // createValidationsObject,
    // createYupSchema,

    _api: ApiService,
    _communications: Communications,
    _routeConfig: RouteConfigService,
    _alert: AlertService,
    _localStorage: LocalStorageService,
    _account: AccountService,
    _staticData: StaticDataService,
    _facility: FacilityService,
    _hcp: HcpManagementService,
    _agency: AgencyService,
    _meta: MetaDataService,
    _shift_management: ShiftManagementService,
    _user_management: UserManagementService,


};
export default CommonService;
