// const downloadUserDataCSVAPICall = (payload: any) => {
//     // @ts-ignore
//     return ApiService[APIConfig.DOWNLOAD_USER_DATA_CSV.METHOD](APIConfig.DOWNLOAD_USER_DATA_CSV.URL, payload);
// };


import {APIConfig} from "../../../constants";
import {ApiService} from "../index";

const downloadUserDataCSVAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DOWNLOAD_USER_DATA_CSV.METHOD](APIConfig.DOWNLOAD_USER_DATA_CSV.URL, payload);
};

const downloadAgencyUserDataCSVAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DOWNLOAD_AGENCY_USER_DATA_CSV.METHOD](APIConfig.DOWNLOAD_AGENCY_USER_DATA_CSV.URL, payload);
};

const UserDataService = {
    downloadUserDataCSVAPICall,
    downloadAgencyUserDataCSVAPICall
};
export default UserDataService
