import './ShiftRequirementList.scss';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import {CommonService} from '../../../shared/services';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import {APIConfig, ImageConfig, Misc} from '../../../constants';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import {ITableColumn} from '../../../shared/models/table.model';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent,
} from '../../../shared/components/tabs/TabsComponent';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import {Checkbox, Typography} from '@mui/material';
import DrawerComponent from '../../../shared/components/drawer/DrawerComponent';
import ApprovedShiftDetailsComponent from '../shift-details/approved-shift-details/ApprovedShiftDetailsComponent';
import CompletedShiftDetailsComponent from '../shift-details/completed-shift-details/CompletedShiftDetailsComponent';
import ConfirmedShiftDetailsComponent from '../shift-details/confirmed-shift-details/ConfirmedShiftDetailsComponent';
import CancelledShiftDetailsComponent from '../shift-details/cancelled-shift-details/CancelledShiftDetailsComponent';
import {IAPIResponseType} from '../../../shared/models/api.model';
import HorizontalLineComponent
    from '../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import DialogComponent from '../../../shared/components/dialog-component/DialogComponent';
import ShiftFilterComponent from '../filter-popup/ShiftFilterComponent';
import AddShiftDetailsComponent from '../add-new-shift/add-shift-details/AddShiftRequirementDetails';
import PendingShiftDetailsComponent from '../shift-details/pending-shift-details/PendingShiftDetails';
import TextWithIconComponent from '../../../shared/components/text-with-icon/TextWithIconComponent';
import ReusableDropdown from '../../../shared/components/action-select-component/ActionSelectComponent';
import {
    approvedTabButtonOptions,
    completedTabButtonOptions,
    confirmedTabButtonOptions,
    pendingTabButtonOptions, shiftHomeType,
    shiftTypeLayoutRepresentation,
} from '../../../data';
import RejectCancelledCommonComponent
    from '../../../shared/components/reject-cancellation/RejectCancelledCommonComponent';
import SearchComponent from '../../../shared/components/search/SearchComponent';
import SelectDropdownComponent from "../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent";
import ApproveApplicationComponent from "../application-approve-component/ApproveApplicationComponent";
import ContractTravelApproveApplicationComponent
    from "../contract-travel-approve-component/ContarctTravelApproveComponent";

export interface ShiftRequirementListProps {
}

const ShiftRequirementList: React.FC<ShiftRequirementListProps> = () => {
    const [filterData, setFilterData] = useState<any>({
        search: '',
        status: 'open',
        open_status_type: 'open',
        requirementType: 'Open Shift',
        // sort:{},
    });

    const [activeTab, setActiveTab] = useState<any>('open');
    // const [progress, setProgress] = useState<any>(0);
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [selectedApplication, setSelectedApplication] = useState<any>([]);
    const [selectedApplicationForApproval, setSelectedApplicationForApproval] = useState<any>([]);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [shiftDetails, setShiftDetails] = useState<any>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openDialogForShiftCreation, setOpenDialogForShiftCreation] =
        useState<boolean>(false);
    const [shiftCancellationReason, setShiftCancellationReason] =
        useState<any>(null);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [cancellationButtonType, setCancellationType] = useState<any>();
    const [shiftTypeHeader, setShiftTypeHeader] = useState(
        'Create Shift Requirement',
    );
    const [loadingOption, setLoadingOption] = useState<string | number | null>(
        null,
    );
    const [isApproveBoxOpen, setIsApproveBoxOpen] = useState<boolean>(false);
    const [isContractApprovalBoxOpen, setIsContractApprovalBoxOpen] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [tabActionSelection, setTabActionSelection] = useState<any>(
        pendingTabButtonOptions,
    );
    const [
        shiftCancellationRejectionLoading,
        setIsShiftCancellationRejectionLoading,
    ] = useState<any>(false);


    useEffect(() => {
        if (activeTab === 'pending') {
            setTabActionSelection(pendingTabButtonOptions);
        } else if (activeTab === 'approved') {
            setTabActionSelection(approvedTabButtonOptions);
        } else if (activeTab === 'completed') {
            setTabActionSelection(completedTabButtonOptions);
        } else if (activeTab === 'confirmed') {
            setTabActionSelection(confirmedTabButtonOptions);
        }
    }, [activeTab]);

    const handleSelectShifts = useCallback(
        (event: any, _id: any) => {
            if (event.target.checked === true) {
                setSelectedShifts([...selectedShifts, _id]);
            } else {
                let tempSelectedShifts = selectedShifts?.filter(
                    (item: any) => item !== _id,
                );
                setSelectedShifts([...tempSelectedShifts]);
            }
        },
        [selectedShifts],
    );

    const handleSelectApplications = useCallback(
        (event: any, app: any) => {
            if (event.target.checked === true) {
                setSelectedApplication([...selectedApplication, app?._id]);
                setSelectedApplicationForApproval([...selectedApplicationForApproval, app]);
            } else {
                let tempSelectedShifts = selectedApplication?.filter(
                    (item: any) => item !== app?._id,
                );
                setSelectedApplication([...tempSelectedShifts]);

                let tempSelectedApplications = selectedApplicationForApproval?.filter(
                    (item: any) => item?._id !== app?._id,
                );
                setSelectedApplicationForApproval([...tempSelectedApplications]);

            }
        },
        [selectedApplicationForApproval, selectedApplication],
    );

    const isSelected = useCallback(
        (_id: any) => {
            return selectedShifts?.indexOf(_id) !== -1;
        },
        [selectedShifts],
    );

    const isApplicationSelected = useCallback(
        (item: any) => {
            return selectedApplication?.indexOf(item?._id) !== -1;
        },
        [selectedApplication],
    );

    const onMarkingShiftCompleted = useCallback(() => {
        const payload: any = {};
        let apiCall: any = {};
        payload.shift_ids = selectedShifts;
        apiCall =
            CommonService._shift_management.markApprovedShiftToCompletedAPICall(
                payload,
            );
        apiCall
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(
                    response[Misc.API_RESPONSE_MESSAGE_KEY],
                    'success',
                );
                setActiveTab('completed');
                setFilterData({...filterData, status: 'completed'});
                setOpenDrawer(false);
                setLoadingOption(null);
            })
            .catch((error: any) => {
            })
            .finally(() => {
            });
    }, [filterData, selectedShifts]);

    const handleConfirmingShiftMarkingAsCompleted = useCallback(() => {
        setOpen(false);
        if (selectedShifts.length === 0) {
            CommonService._alert.showToast(
                'Please select at least one shift to mark as completed',
                'info',
            );
            setLoadingOption(null);
        } else {
            CommonService.onConfirm({
                confirmationTitle: `Mark As Completed`,
                //image: ImageConfig.ActivateDeactivateIcon,
                confirmationDescription:
                    'Are you sure you want to mark this shift as completed ?',
                no: {
                    color: 'default',
                    text: 'No, Go Back',
                    variant: 'outlined',
                },
                yes: {
                    color: 'error',
                    text: 'Yes, Mark as Completed',
                    variant: 'contained',
                },
            }).then((result: any) => {
                onMarkingShiftCompleted();
            });
        }
    }, [onMarkingShiftCompleted, selectedShifts]);

    const onMarkingApplicationAsApprove = useCallback(() => {
        const payload: any = {};
        let apiCall: any = {};
        setLoadingOption(null);
        payload.application_ids = selectedApplication;
        apiCall =
            CommonService._shift_management.markApplicationToApprovedAPICall(payload);
        apiCall
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(
                    response[Misc.API_RESPONSE_MESSAGE_KEY],
                    'success',
                );
                setActiveTab('approved');
                setFilterData({...filterData, status: 'approved'});
                setOpen(false);
                setLoadingOption(null);
            })
            .catch((error: any) => {
            })
            .finally(() => {
            });
    }, [filterData, selectedApplication]);

    const handleMarkingApplicationAsApprove = useCallback(() => {
        setOpen(false);
        if (selectedApplication.length === 0) {
            CommonService._alert.showToast(
                'Please select atleast one shift to approve',
                'info',
            );
            setLoadingOption(null);
        } else {
            CommonService.onConfirm({
                confirmationTitle: `Mark As Approve`,
                //image: ImageConfig.ActivateDeactivateIcon,
                confirmationDescription:
                    'Are you sure you want to mark this application as approve ?',
                no: {
                    color: 'default',
                    text: 'No, Go Back',
                    variant: 'outlined',
                },
                yes: {
                    color: 'error',
                    text: 'Yes, Mark as Approve',
                    variant: 'contained',
                },
            })
                .then((result: any) => {
                    onMarkingApplicationAsApprove();
                })
                .catch(() => {
                    setLoadingOption(null);
                });
        }
    }, [onMarkingApplicationAsApprove, selectedApplication.length]);

    const handleDownloadShifts = useCallback(() => {
        setIsDownloading(true);
        const payload: any = {};
        const {status} = filterData;
        payload.status = [status];
        let apiCall: any = {};
        if (filterData?.status !== 'open') {
            apiCall =
                CommonService._shift_management.downloadShiftDataCSVAPICall(filterData);
        } else {
            apiCall =
                CommonService._shift_management.downloadOpenShiftDataCSVAPICall(
                    filterData,
                );
        }
        apiCall
            .then((response: IAPIResponseType<any>) => {
                if (response?.data) {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'success',
                    );
                    const link = document.createElement('a');
                    link?.setAttribute('href', response?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                    setLoadingOption(null);
                } else {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'error',
                    );
                    setIsDownloading(false);
                    setLoadingOption(null);
                }
            })
            .catch((error: any) => {
                CommonService._alert.showToast(
                    error[Misc.API_ERROR_MESSAGE_KEY],
                    'error',
                );
                setIsDownloading(false);
            })
            .finally(() => {
            });
    }, [filterData]);

    const handleConfirmingShiftMarkingAsConfirmed = useCallback(() => {
        setOpen(false);
        if (selectedShifts.length === 0) {
            CommonService._alert.showToast(
                'Please select atleast one shift to mark as confirmed',
                'info',
            );
            setLoadingOption(null);
        } else {
            CommonService.onConfirm({
                confirmationTitle: `Mark As Confirmed`,
                confirmationDescription:
                    'Are you sure you want to mark this shift as Confirmed ?',
                no: {
                    color: 'default',
                    text: 'No, Go Back',
                    variant: 'outlined',
                },
                yes: {
                    color: 'error',
                    text: 'Yes, Mark as Confirmed',
                    variant: 'contained',
                },
            }).then((result: any) => {
                const payload: any = {};
                let apiCall: any = {};
                payload.shift_ids = selectedShifts;
                apiCall =
                    CommonService._shift_management.markCompletedShiftToConfirmedAPICall(
                        payload,
                    );
                apiCall
                    .then((response: IAPIResponseType<any>) => {
                        CommonService._alert.showToast(
                            response[Misc.API_RESPONSE_MESSAGE_KEY],
                            'success',
                        );
                        CommonService._localStorage.setItem(
                            'shift-requirement-selected-tab',
                            'completed',
                        );
                        setActiveTab('confirmed');
                        setFilterData({...filterData, status: 'confirmed'});
                        setOpenDrawer(false);
                        setLoadingOption(null);
                    })
                    .catch((error: any) => {
                        // Handle error here if needed
                    })
                    .finally(() => {
                        // Any final actions here if needed
                    });
            });
        }
    }, [filterData, selectedShifts, setActiveTab, setFilterData, setOpenDrawer]);

    const openShiftsListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: 'Title',
                key: 'title',
                dataIndex: 'title',
                fixed: 'left',
                // sortable: true,
                width: 200,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {(
                                    CommonService.convertDateFormat2(
                                        item?.shift_date,
                                        'MM-DD-YYYY',
                                    ) +
                                    ' | ' +
                                    item?.facility_details?.name +
                                    ' | ' +
                                    item?.hcp_type_details?.title +
                                    ' | ' +
                                    item?.shift_type
                                )?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={
                                            CommonService.convertDateFormat2(
                                                item?.shift_date,
                                                'MM-DD-YYYY',
                                            ) +
                                            ' | ' +
                                            item?.facility_details?.name +
                                            ' | ' +
                                            item?.hcp_type_details?.title +
                                            ' | ' +
                                            item?.shift_type
                                        }
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {CommonService.convertDateFormat2(
                                                    item?.shift_date,
                                                    'MM-DD-YYYY',
                                                ) +
                                                ' | ' +
                                                item?.facility_details?.name +
                                                ' | ' +
                                                item?.hcp_type_details?.title +
                                                ' | ' +
                                                item?.shift_type}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {CommonService.convertDateFormat2(
                                                item?.shift_date,
                                                'MM-DD-YYYY',
                                            ) +
                                            ' | ' +
                                            item?.facility_details?.name +
                                            ' | ' +
                                            item?.hcp_type_details?.title +
                                            ' | ' +
                                            item?.shift_type}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.FacilitySidebarIcon/>}>
                        Facility Name
                    </TextWithIconComponent>
                ),
                key: 'facility_name',
                dataIndex: 'facility_name',
                // sortable: true,
                width: 200,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.facility_details?.name?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.facility_details?.name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.facility_details?.name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>{item?.facility_details?.name}</div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.ShiftDate/>}>
                        {filterData.requirementType === "Home Health" ? "Visit Date" : "Shift Date "}
                    </TextWithIconComponent>
                ),
                key: 'shift_date',
                dataIndex: 'shift_date',
                width: 200,
                render: (item: any) => {
                    if (item?.shift_date) {
                        return (
                            <>
                                {CommonService.convertDateFormat2(
                                    item?.shift_date,
                                    'MM-DD-YYYY',
                                )}
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.ShiftTime/>}>
                        {filterData.requirementType === "Home Health" ? "Visit Time" : "Shift Time"}
                    </TextWithIconComponent>
                ),
                key: 'shift-time',
                dataIndex: 'shift-time',
                width: 200,
                render: (item: any) => {
                    if (item?.shift_timings) {
                        return (
                            <>
                                {filterData.requirementType === "Home Health" &&
                                    <div>
                                        {CommonService.convertMinutesToTime(
                                            item?.hospice_details?.visit_patient_time,
                                        )}
                                    </div>
                                }
                                {filterData.requirementType !== "Home Health" &&
                                    <div>
                                        {CommonService.convertMinutesToTime(
                                                item?.shift_timings?.start_time,
                                            ) +
                                            '-' +
                                            CommonService.convertMinutesToTime(
                                                item?.shift_timings?.end_time,
                                            )}
                                    </div>
                                }
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.HcpType/>}>
                        Staff Type
                    </TextWithIconComponent>
                ),
                key: 'hcp-type',
                dataIndex: 'hcp_type',
                width: 180,
                render: (item: any) => {
                    return (
                        <>
                            {item?.hcp_type_details?.title
                                ? item?.hcp_type_details?.title
                                : '_'}
                        </>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.ShiftType/>}>
                        Shift Type
                    </TextWithIconComponent>
                ),
                key: 'shift-type',
                dataIndex: 'shift-type',
                width: 250,
                // render: (item: any) => {
                //     return <>
                //         {item?.shift_type ? item?.shift_type : "-"}
                //     </>;
                // }
                render: (item: any) => {
                    // console.log(item);
                    return (
                        <div className={'d-flex justify-content-center align-items-center'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}

                            {(() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(
                                    (shiftTypeObj) => shiftTypeObj.code === item?.shift_type,
                                );
                                if (foundShiftType) {
                                    return (
                                        <>
                                            <div className={'mrg-right-5 mrg-top-5'}>
                                                {foundShiftType.icon}
                                            </div>
                                            <div className={foundShiftType.color}>
                                                {item?.shift_type}
                                            </div>
                                        </>
                                    );
                                } else {
                                    return null; // or some default icon or message if shift type is not found
                                }
                            })()}
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.Wing/>}>
                        Wing
                    </TextWithIconComponent>
                ),
                key: 'wing',
                dataIndex: 'wing',
                width: 137,
                align: 'left',
                // sortable: true,
                // render: (item: any) => {
                //     return <>{item?.fms?.map((item: any) => {
                //         return <>{item?.name}</>
                //     }) || "-"}</>
                // }
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.facility_wing_details?.name?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.facility_wing_details?.name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.facility_wing_details?.name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.facility_wing_details?.name || '-'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.CheckmarkRound/>}>
                        Status
                    </TextWithIconComponent>
                ),
                key: 'timesheet',
                dataIndex: 'timesheet',
                width: 137,
                align: 'left',
                fixed: 'right',
                // sortable: true,
                // render: (item: any) => {
                //     return <>{item?.fms?.map((item: any) => {
                //         return <>{item?.name}</>
                //     }) || "-"}</>
                // }
                render: (item: any) => {
                    return (
                        <>
                            <div
                                onClick={() => {
                                    console.log(item?.status);
                                }}
                                className={`requirement-status requirement-${item?.status}-status`}
                            >
                                {item?.status === 'partially_open' && <div>Partially Open</div>}
                                {item?.status !== 'partially_open' &&
                                    CommonService.capitalizeFirstLetter(item?.status)}
                            </div>
                        </>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.HcpFilled/>}>
                        Staff Filled
                    </TextWithIconComponent>
                ),
                key: 'hcp-filled',
                dataIndex: 'hcp-filled',
                align: 'left',
                width: 250,
                fixed: 'right',
                render: (item: any) => {
                    let hcpCount = item?.approved_hcp_count;
                    let exceededHcpCount = 0;
                    if (hcpCount > item?.required_hcp_count) {
                        exceededHcpCount = hcpCount - item?.required_hcp_count;
                        hcpCount = item?.required_hcp_count;
                    }
                    const progress = (hcpCount / item?.required_hcp_count) * 100;
                    return (
                        <>
                            {/*<Box sx={{display: 'flex', alignItems: 'center' }}>*/}
                            <div
                                style={{width: '100%', display: 'flex', alignItems: 'center'}}
                            >
                                <Box sx={{width: '80%'}}>
                                    <LinearProgress
                                        sx={{
                                            borderRadius: '5px',
                                            minHeight: '8px',
                                            backgroundColor: '#F2F4F7',
                                        }}
                                        variant='determinate'
                                        value={progress}
                                    />
                                </Box>
                                <div className={'mrg-left-20'}>
                                    <Typography>
                                        {item?.approved_hcp_count}/
                                        {Number(item?.required_hcp_count) +
                                            Number(exceededHcpCount)}
                                    </Typography>
                                </div>
                            </div>
                        </>
                    );
                },
            },
            {
                title: 'View Details',
                dataIndex: 'actions',
                align: 'center',
                key: 'actions',
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return (
                            <div>
                                <LinkComponent
                                    route={CommonService._routeConfig.ShiftRequirementDetailsRoute(
                                        filterData?.status,
                                        item?._id,
                                    )}
                                >
                                    <ImageConfig.ArrowRightOutlinedIcon color={'#4E535F'}/>
                                </LinkComponent>
                            </div>
                        );
                    }
                },
            },
        ];
    }, [filterData]);

    const otherShiftsListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: '',
                key: 'selection_checkbox',
                dataIndex: 'selection_checkbox',
                fixed: 'left',
                // sortable: true,
                width: 70,
                render: (item: any) => {
                    const isItemSelected = isSelected(item?._id);
                    return (
                        <>
                            {activeTab !== 'cancelled' ? (
                                <Checkbox
                                    style={{
                                        color: '#202937',
                                    }}
                                    id={'cb_' + item?._id}
                                    checked={isItemSelected}
                                    onChange={(event: any) =>
                                        handleSelectShifts(event, item?._id)
                                    }
                                />
                            ) : null}
                        </>
                    );
                },
            },
            {
                title: 'Title',
                key: 'title',
                dataIndex: 'title',
                fixed: 'left',
                // sortable: true,
                width: 200,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {(
                                    CommonService.convertDateFormat2(
                                        item?.shift_date,
                                        'MM-DD-YYYY',
                                    ) +
                                    ' | ' +
                                    item?.facility_details?.name +
                                    ' | ' +
                                    item?.hcp_type_details?.title +
                                    ' | ' +
                                    item?.shift_type
                                )?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={
                                            CommonService.convertDateFormat2(
                                                item?.shift_date,
                                                'MM-DD-YYYY',
                                            ) +
                                            ' | ' +
                                            item?.facility_details?.name +
                                            ' | ' +
                                            item?.hcp_type_details?.title +
                                            ' | ' +
                                            item?.shift_type
                                        }
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {CommonService.convertDateFormat2(
                                                    item?.shift_date,
                                                    'MM-DD-YYYY',
                                                ) +
                                                ' | ' +
                                                item?.facility_details?.name +
                                                ' | ' +
                                                item?.hcp_type_details?.title +
                                                ' | ' +
                                                item?.shift_type}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {CommonService.convertDateFormat2(
                                                item?.shift_date,
                                                'MM-DD-YYYY',
                                            ) +
                                            ' | ' +
                                            item?.facility_details?.name +
                                            ' | ' +
                                            item?.hcp_type_details?.title +
                                            ' | ' +
                                            item?.shift_type}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.FacilitySidebarIcon/>}>
                        Facility Name
                    </TextWithIconComponent>
                ),
                key: 'facility_name',
                dataIndex: 'facility',
                fixed: 'left',
                // sortable: true,
                width: 200,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.facility_details?.name?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.facility_details?.name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.facility_details?.name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>{item?.facility_details?.name}</div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.AgencySidebarIcon/>}>
                        Agency Name
                    </TextWithIconComponent>
                ),
                key: 'agency_name',
                dataIndex: 'name',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.agency_details?.name?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.agency_details?.name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.agency_details?.name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.agency_details?.name || '-'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.PersonProfileOutline/>}>
                        Staff Name
                    </TextWithIconComponent>
                ),
                key: 'hcp_name',
                dataIndex: 'hcp_name',
                width: 200,
                align: 'left',
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)
                                    ?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={
                                            item?.hcp_details?.first_name +
                                            ' ' +
                                            item?.hcp_details?.last_name
                                        }
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.hcp_details?.first_name +
                                                ' ' +
                                                item?.hcp_details?.last_name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.hcp_details?.first_name +
                                            ' ' +
                                            item?.hcp_details?.last_name || '-'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.ShiftDate/>}>
                        Shift Date
                    </TextWithIconComponent>
                ),
                key: 'shift_date',
                dataIndex: 'shift_date',
                //fixed: 'left',
                // sortable: true,
                width: 200,
                render: (item: any) => {
                    if (item?.shift_date) {
                        return (
                            <>
                                {CommonService.convertDateFormat2(
                                    item?.shift_date,
                                    'MM-DD-YYYY',
                                )}
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.ShiftTime/>}>
                        Shift Time
                    </TextWithIconComponent>
                ),
                key: 'shift-time',
                dataIndex: 'shift-time',
                width: 200,
                render: (item: any) => {
                    if (item?.shift_timings) {
                        return (
                            <>
                                {CommonService.convertMinutesToTime(
                                        item?.shift_timings?.start_time,
                                    ) +
                                    '-' +
                                    CommonService.convertMinutesToTime(
                                        item?.shift_timings?.end_time,
                                    )}
                            </>
                        );
                    } else {
                        return (
                            <>
                                {CommonService.convertMinutesToTime(
                                        item?.expected?.shift_start_time,
                                    ) +
                                    '-' +
                                    CommonService.convertMinutesToTime(
                                        item?.expected?.shift_end_time,
                                    )}
                            </>
                        );
                    }
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.HcpType/>}>
                        Staff Type
                    </TextWithIconComponent>
                ),
                key: 'hcp-type',
                dataIndex: 'hcp_type',
                width: 180,
                render: (item: any) => {
                    return (
                        <>
                            {item?.hcp_type_details?.title
                                ? item?.hcp_type_details?.title
                                : '_'}
                        </>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.ShiftType/>}>
                        Shift Type
                    </TextWithIconComponent>
                ),
                key: 'shift-type',
                dataIndex: 'shift-type',
                width: 250,
                render: (item: any) => {
                    // console.log(item);
                    return (
                        <div className={'d-flex justify-content-center align-items-center'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}

                            {(() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(
                                    (shiftTypeObj) => shiftTypeObj.code === item?.shift_type,
                                );
                                if (foundShiftType) {
                                    return (
                                        <>
                                            <div className={'mrg-right-5 mrg-top-5'}>
                                                {foundShiftType.icon}
                                            </div>
                                            <div className={foundShiftType.color}>
                                                {item?.shift_type}
                                            </div>
                                        </>
                                    );
                                } else {
                                    return null; // or some default icon or message if shift type is not found
                                }
                            })()}
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.Wing/>}>
                        Wing
                    </TextWithIconComponent>
                ),
                key: 'wing',
                dataIndex: 'wing',
                width: 200,
                align: 'left',
                // sortable: true,
                // render: (item: any) => {
                //     return <>{item?.fms?.map((item: any) => {
                //         return <>{item?.name}</>
                //     }) || "-"}</>
                // }
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.facility_wing_details?.name?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.facility_wing_details?.name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.facility_wing_details?.name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.facility_wing_details?.name || '-'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.CheckmarkRound/>}>
                        Timesheet
                    </TextWithIconComponent>
                ),
                key: 'timesheet',
                dataIndex: 'timesheet',
                width: 137,
                align: 'left',
                // sortable: true,
                // render: (item: any) => {
                //     return <>{item?.fms?.map((item: any) => {
                //         return <>{item?.name}</>
                //     }) || "-"}</>
                // }
                render: (item: any) => {
                    return (
                        <>
                            {item?.is_timesheet_uploaded && (
                                <div className={'uploaded'}>Uploaded</div>
                            )}
                            {!item?.is_timesheet_uploaded && (
                                <>
                                    <div className={'pending'}>Pending</div>
                                </>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'View Details',
                dataIndex: 'actions',
                align: 'center',
                key: 'actions',
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return (
                            <div>
                                <LinkComponent
                                    onClick={() => {
                                        setOpenDrawer(true);
                                        setShiftDetails(item);
                                    }}
                                >
                                    <ImageConfig.ArrowRightOutlinedIcon color={'#4E535F'}/>
                                </LinkComponent>
                            </div>
                        );
                    }
                },
            },
        ];
    }, [handleSelectShifts, isSelected, activeTab]);

    const pendingShiftListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: '',
                key: 'selection_checkbox',
                dataIndex: 'selection_checkbox',
                fixed: 'left',
                // sortable: true,
                width: 70,
                render: (item: any) => {
                    const isItemSelected = isApplicationSelected(item);
                    return (
                        <>
                            {activeTab !== 'cancelled' ? (
                                <Checkbox
                                    style={{
                                        color: '#202937',
                                    }}
                                    id={'cb_' + item?._id}
                                    checked={isItemSelected}
                                    onChange={(event: any) =>
                                        handleSelectApplications(event, item)
                                    }
                                />
                            ) : null}
                        </>
                    );
                },
            },
            {
                title: 'Title',
                key: 'title',
                dataIndex: 'title',
                fixed: 'left',
                // sortable: true,
                width: 200,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {(
                                    CommonService.convertDateFormat2(
                                        item?.shift_date,
                                        'MM-DD-YYYY',
                                    ) +
                                    ' | ' +
                                    item?.facility_details?.name +
                                    ' | ' +
                                    item?.hcp_type_details?.title +
                                    ' | ' +
                                    item?.shift_type
                                )?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={
                                            CommonService.convertDateFormat2(
                                                item?.shift_date,
                                                'MM-DD-YYYY',
                                            ) +
                                            ' | ' +
                                            item?.facility_details?.name +
                                            ' | ' +
                                            item?.hcp_type_details?.title +
                                            ' | ' +
                                            item?.shift_type
                                        }
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {CommonService.convertDateFormat2(
                                                    item?.shift_date,
                                                    'MM-DD-YYYY',
                                                ) +
                                                ' | ' +
                                                item?.facility_details?.name +
                                                ' | ' +
                                                item?.hcp_type_details?.title +
                                                ' | ' +
                                                item?.shift_type}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {CommonService.convertDateFormat2(
                                                item?.shift_date,
                                                'MM-DD-YYYY',
                                            ) +
                                            ' | ' +
                                            item?.facility_details?.name +
                                            ' | ' +
                                            item?.hcp_type_details?.title +
                                            ' | ' +
                                            item?.shift_type}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.FacilitySidebarIcon/>}>
                        Facility Name
                    </TextWithIconComponent>
                ),
                key: 'facility_name',
                dataIndex: 'facility',
                fixed: 'left',
                // sortable: true,
                width: 200,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.facility_details?.name?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.facility_details?.name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.facility_details?.name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>{item?.facility_details?.name}</div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.AgencySidebarIcon/>}>
                        Agency Name
                    </TextWithIconComponent>
                ),
                key: 'agency_name',
                dataIndex: 'name',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.agency_details?.name?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.agency_details?.name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.agency_details?.name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.agency_details?.name || '-'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.PersonProfileOutline/>}>
                        Staff Name
                    </TextWithIconComponent>
                ),
                key: 'hcp_name',
                dataIndex: 'hcp_name',
                width: 200,
                align: 'left',
                // sortable: true,
                // render: (item: any) => {
                //     return <>{item?.fms?.map((item: any) => {
                //         return <>{item?.name}</>
                //     }) || "-"}</>
                // }
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {(item?.hcp_details?.first_name + item?.hcp_details?.last_name)
                                    ?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={
                                            item?.hcp_details?.first_name +
                                            ' ' +
                                            item?.hcp_details?.last_name
                                        }
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.hcp_details?.first_name +
                                                ' ' +
                                                item?.hcp_details?.last_name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.hcp_details?.first_name +
                                            ' ' +
                                            item?.hcp_details?.last_name || '-'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.ShiftDate/>}>
                        Shift Date
                    </TextWithIconComponent>
                ),
                key: 'shift_date',
                dataIndex: 'shift_date',
                //fixed: 'left',
                // sortable: true,
                width: 200,
                render: (item: any) => {
                    if (item?.requirement_details?.shift_date) {
                        return (
                            <>
                                {CommonService.convertDateFormat2(
                                    item?.requirement_details?.shift_date,
                                    'MM-DD-YYYY',
                                )}
                            </>
                        );
                    } else {
                        return <>N/A</>;
                    }
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.ShiftTime/>}>
                        Shift Time
                    </TextWithIconComponent>
                ),
                key: 'shift-time',
                dataIndex: 'shift-time',
                width: 200,
                render: (item: any) => {
                    if (item?.requirement_details?.shift_timings) {
                        return (
                            <>
                                {CommonService.convertMinutesToTime(
                                        item?.requirement_details?.shift_timings?.start_time,
                                    ) +
                                    '-' +
                                    CommonService.convertMinutesToTime(
                                        item?.requirement_details?.shift_timings?.end_time,
                                    )}
                            </>
                        );
                    } else {
                        return (
                            <>
                                {CommonService.convertMinutesToTime(
                                        item?.expected?.shift_start_time,
                                    ) +
                                    '-' +
                                    CommonService.convertMinutesToTime(
                                        item?.expected?.shift_end_time,
                                    )}
                            </>
                        );
                    }
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.HcpType/>}>
                        Staff Type
                    </TextWithIconComponent>
                ),
                key: 'hcp-type',
                dataIndex: 'hcp_type',
                width: 180,
                render: (item: any) => {
                    return (
                        <>
                            {item?.hcp_type_details?.title
                                ? item?.hcp_type_details?.title
                                : '_'}
                        </>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.ShiftType/>}>
                        Shift Type
                    </TextWithIconComponent>
                ),
                key: 'shift-type',
                dataIndex: 'shift-type',
                width: 250,
                // render: (item: any) => {
                //     return <>
                //         {item?.requirement_details?.shift_type ? item?.requirement_details?.shift_type : "-"}
                //     </>;
                // }
                render: (item: any) => {
                    // console.log(item);
                    return (
                        <div className={'d-flex justify-content-center align-items-center'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}

                            {(() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(
                                    (shiftTypeObj) =>
                                        shiftTypeObj.code === item?.requirement_details?.shift_type,
                                );
                                if (foundShiftType) {
                                    return (
                                        <>
                                            <div className={'mrg-right-5 mrg-top-5'}>
                                                {foundShiftType.icon}
                                            </div>
                                            <div className={foundShiftType.color}>
                                                {item?.requirement_details?.shift_type}
                                            </div>
                                        </>
                                    );
                                } else {
                                    return null; // or some default icon or message if shift type is not found
                                }
                            })()}
                        </div>
                    );
                },
            },
            {
                title: () => (
                    <TextWithIconComponent prefix={<ImageConfig.Wing/>}>
                        Wing
                    </TextWithIconComponent>
                ),
                key: 'wing',
                dataIndex: 'wing',
                width: 200,
                align: 'left',
                // sortable: true,
                // render: (item: any) => {
                //     return <>{item?.fms?.map((item: any) => {
                //         return <>{item?.name}</>
                //     }) || "-"}</>
                // }
                render: (item: any) => {
                    return (
                        <div className={'hcp-details-wrapper'}>
                            {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                            <div className={'hcp-name-tower-wrapper'}>
                                {item?.wing_details?.name?.length > 20 ? (
                                    <ToolTipComponent
                                        tooltip={item?.wing_details?.name}
                                        showArrow={true}
                                        position={'top'}
                                    >
                                        <div className={'ellipses-for-table-data property-name'}>
                                            {item?.wing_details?.name}
                                        </div>
                                    </ToolTipComponent>
                                ) : (
                                    <div className='hcp-name'>
                                        {item?.wing_details?.name || '-'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'View Details',
                dataIndex: 'actions',
                align: 'center',
                key: 'actions',
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return (
                            <div>
                                <LinkComponent
                                    onClick={() => {
                                        setOpenDrawer(true);
                                        setShiftDetails(item);
                                    }}
                                >
                                    <ImageConfig.ArrowRightOutlinedIcon color={'#4E535F'}/>
                                </LinkComponent>
                            </div>
                        );
                    }
                },
            },
        ];
    }, [activeTab, handleSelectApplications, isApplicationSelected]);

    const cancelShifts = useCallback(() => {
        setIsShiftCancellationRejectionLoading(true);
        if (cancellationButtonType === 'cancel-shift') {
            const payload: any = {
                reason: shiftCancellationReason,
            };
            let apiCall: any = {};
            payload.shift_ids = selectedShifts;
            payload.cancelled_details = shiftCancellationReason;

            apiCall =
                CommonService._shift_management.cancelApprovedShiftToCancelledAPICall(
                    payload,
                );
            apiCall
                .then((response: IAPIResponseType<any>) => {
                    setIsShiftCancellationRejectionLoading(false);
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'success',
                    );
                    CommonService._staticData.openDialogPopup(setOpenDialog, false);
                    setActiveTab('cancelled');
                    setFilterData({...filterData, status: 'cancelled'});
                    setOpenDrawer(false);
                    setLoadingOption(null);
                })
                .catch((error: any) => {
                    setIsShiftCancellationRejectionLoading(false);
                })
                .finally(() => {
                });
        } else {
            const payload: any = {
                rejected_reason: shiftCancellationReason,
            };
            let apiCall: any = {};
            payload.application_ids = selectedApplication;
            payload.rejected_reason = shiftCancellationReason;

            apiCall =
                CommonService._shift_management.markShiftAsRejectedAPICall(payload);
            apiCall
                .then((response: IAPIResponseType<any>) => {
                    setIsShiftCancellationRejectionLoading(false);
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'success',
                    );
                    CommonService._staticData.openDialogPopup(setOpenDialog, false);
                    setActiveTab('pending');
                    setFilterData({...filterData, status: 'pending'});
                    setOpenDrawer(false);
                    setSelectedApplication([]);
                    setSelectedApplicationForApproval([]);
                    setLoadingOption(null);
                })
                .catch((error: any) => {
                    setIsShiftCancellationRejectionLoading(false);
                })
                .finally(() => {
                });
        }
    }, [
        cancellationButtonType,
        filterData,
        selectedApplication,
        selectedShifts,
        shiftCancellationReason,
    ]);

    const handleOptionChange = useCallback(
        (value: string | number) => {
            setLoadingOption(value);
            if (value === 'approve-shifts') {
                if (filterData?.requirementType === "Home Health") {
                    if (selectedApplication.length === 0) {
                        CommonService._alert.showToast(
                            'Please select at least one shift to approve',
                            'info',
                        );
                        setLoadingOption(null);
                        setOpen(false);
                    } else {
                        setOpen(false);
                        setIsApproveBoxOpen(true);

                        setLoadingOption(null);
                    }
                } else if (filterData?.requirementType === "Contract Travel") {
                    if (selectedApplication.length === 0) {
                        CommonService._alert.showToast(
                            'Please select at least one shift to approve',
                            'info',
                        );
                        setLoadingOption(null);
                        setOpen(false);
                    } else {
                        setOpen(false);
                        setIsContractApprovalBoxOpen(true);
                        setLoadingOption(null);
                    }
                } else {
                    handleMarkingApplicationAsApprove();
                }
            } else if (value === 'reject-shifts') {
                setOpen(false);
                if (selectedApplication.length === 0) {
                    CommonService._alert.showToast(
                        'Please select at least one shift to reject',
                        'info',
                    );
                    setLoadingOption(null);
                } else {
                    setCancellationType('reject-application');
                    CommonService._staticData.openDialogPopup(setOpenDialog, true);
                }
            } else if (value === 'download-csv') {
                handleDownloadShifts();
                setOpen(false);
            } else if (value === 'mark-as-cancel') {
                setOpen(false);
                if (selectedShifts.length === 0) {
                    setLoadingOption(null);
                    CommonService._alert.showToast(
                        'Please select atleast one shift to cancel',
                        'info',
                    );
                } else {
                    setCancellationType('cancel-shift');
                    CommonService._staticData.openDialogPopup(setOpenDialog, true);
                }
            } else if (value === 'mark-as-completed') {
                handleConfirmingShiftMarkingAsCompleted();
            } else if (value === 'mark-as-confirmed') {
                handleConfirmingShiftMarkingAsConfirmed();
            }
        },
        [
            handleMarkingApplicationAsApprove,
            selectedApplication.length,
            handleDownloadShifts,
            selectedShifts.length,
            handleConfirmingShiftMarkingAsCompleted,
            handleConfirmingShiftMarkingAsConfirmed,
            filterData
        ],
    );

    return (
        <>
            <DialogComponent
                maxWidth={'xl'}
                cancel={() => {
                    CommonService._staticData.openDialogPopup(
                        setOpenDialogForShiftCreation,
                        false,
                    );
                    setFilterData({...filterData});
                }}
                open={openDialogForShiftCreation}
            >
                <div>
                    <div className={'calender-open-details-header'}>
                        <div className={'form-header-text'}>{shiftTypeHeader}</div>
                        <div
                            className={'icon-button'}
                            onClick={() => {
                                CommonService._staticData.openDialogPopup(
                                    setOpenDialogForShiftCreation,
                                    false,
                                );
                                setFilterData({...filterData});
                            }}
                        >
                            <ImageConfig.CrossIcon/>
                        </div>
                    </div>
                    <HorizontalLineComponent/>
                    <AddShiftDetailsComponent setShiftTypeHeader={setShiftTypeHeader}/>
                </div>
            </DialogComponent>
            <DrawerComponent
                onClose={() => {
                    CommonService._staticData.openDialogPopup(setOpenDrawer, false);
                }}
                isOpen={openDrawer}
            >
                {activeTab === 'approved' && (
                    <ApprovedShiftDetailsComponent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        shiftDetails={shiftDetails}
                        setOpenDrawer={setOpenDrawer}
                    />
                )}
                {activeTab === 'pending' && (
                    <PendingShiftDetailsComponent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        shiftDetails={shiftDetails}
                        setOpenDrawer={setOpenDrawer}
                    />
                )}
                {activeTab === 'completed' && (
                    <CompletedShiftDetailsComponent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        shiftDetails={shiftDetails}
                        setOpenDrawer={setOpenDrawer}
                    />
                )}
                {activeTab === 'confirmed' && (
                    <ConfirmedShiftDetailsComponent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        shiftDetails={shiftDetails}
                        setOpenDrawer={setOpenDrawer}
                    />
                )}
                {activeTab === 'cancelled' && (
                    <CancelledShiftDetailsComponent
                        filterData={filterData}
                        setFilterData={setFilterData}
                        shiftDetails={shiftDetails}
                        setOpenDrawer={setOpenDrawer}
                    />
                )}
            </DrawerComponent>
            <DialogComponent
                cancel={() => {
                    CommonService._staticData.openDialogPopup(setOpenDialog, false);
                    setLoadingOption(null);
                }}
                open={openDialog}
            >
                <RejectCancelledCommonComponent
                    setLoadingOption={setLoadingOption}
                    setOpenDialog={setOpenDialog}
                    cancellationType={
                        cancellationButtonType === 'cancel-shift'
                            ? 'Cancel Shift'
                            : 'Reject Shift'
                    }
                    setShiftCancellationReason={setShiftCancellationReason}
                    onSubmit={cancelShifts}
                    shiftCancellationReason={shiftCancellationReason}
                    isLoading={shiftCancellationRejectionLoading}
                />
            </DialogComponent>
            <DialogComponent cancel={() => {
                setIsApproveBoxOpen(false)
            }} open={isApproveBoxOpen} maxWidth={'xl'}>
                <ApproveApplicationComponent selectedApplication={selectedApplication}
                                             selectedApplicationForApproval={selectedApplicationForApproval}
                                             setIsApproveBoxOpen={setIsApproveBoxOpen}
                                             status={"list"}
                                             pendingListFilterData={filterData}
                                             setPendingListFilterData={setFilterData}
                />
            </DialogComponent>

            <DialogComponent cancel={() => {
                setIsContractApprovalBoxOpen(false)
            }} open={isContractApprovalBoxOpen} maxWidth={'xl'}>
                <ContractTravelApproveApplicationComponent selectedApplication={selectedApplication}
                                                           selectedApplicationForApproval={selectedApplicationForApproval}
                                                           setIsContractApprovalBoxOpen={setIsContractApprovalBoxOpen}
                                                           status={"list"}
                                                           pendingListFilterData={filterData}
                                                           setPendingListFilterData={setFilterData}
                />
            </DialogComponent>


            <div className={'shift-requirement-screen'}>
                <div
                    className={
                        'facility-list-component header-screen mrg-top-15 mrg-left-25 mrg-right-30'
                    }
                >
                    <div className='list-screen-header-wrapper'>
                        <div className='list-screen-header'>
                            {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                            <div className='list-screen-header-title-and-count d-flex'>
                                <div className={'mrg-right-10'}>
                                    <ImageConfig.ShiftManagementModuleHeaderIcon/>
                                </div>
                                <div className='list-screen-header-title'>Shift</div>
                            </div>
                        </div>
                        <div className='list-options'>
                            {(filterData?.status === 'pending' ||
                                activeTab === 'approved' ||
                                activeTab === 'completed' ||
                                activeTab === 'confirmed') && (
                                <div>
                                    <ReusableDropdown
                                        options={tabActionSelection}
                                        loadingOption={loadingOption}
                                        onChange={handleOptionChange}
                                        open={open}
                                        setOpen={setOpen}
                                    />
                                </div>
                            )}

                            <SelectDropdownComponent className={"select-box-width mrg-top-15"}
                                                     options={shiftHomeType}
                                                     placeholder={"select"}
                                                     isClearable={true}
                                                     label={''}
                                                     value={filterData.requirementType}
                                                     valueExtractor={item => item.code}
                                                     displayWith={item => item.title}
                                                     onUpdate={
                                                         (value) => {
                                                             setFilterData({
                                                                 ...filterData,
                                                                 requirementType: value
                                                             });
                                                             setSelectedApplication([]);
                                                             setSelectedApplicationForApproval([]);
                                                         }
                                                     }
                                                     fullWidth={true}
                            />
                            {filterData?.status === 'open' && (
                                <ButtonComponent
                                    color={'download-button'}
                                    variant={'contained'}
                                    isLoading={isDownloading}
                                    onClick={handleDownloadShifts}
                                >
                                    Download CSV
                                </ButtonComponent>
                            )}
                            {/*{filterData?.status === 'open' && (*/}
                            {/*    <LinkComponent behaviour={'spa'}*/}
                            {/*                   route={CommonService._routeConfig.BulkUploadPage()}*/}
                            {/*    >*/}
                            {/*        <ButtonComponent*/}
                            {/*            color={'download-button'}*/}
                            {/*            variant={'contained'}*/}
                            {/*            //   isLoading={isDownloading}*/}
                            {/*            //  onClick={handleDownloadShifts}*/}
                            {/*        >*/}
                            {/*            Bulk Upload*/}
                            {/*        </ButtonComponent>*/}
                            {/*    </LinkComponent>*/}
                            {/*)}*/}
                            <LinkComponent
                                onClick={() => {
                                    CommonService._staticData.openDialogPopup(
                                        setOpenDialogForShiftCreation,
                                        true,
                                    );
                                }}
                            >
                                <ButtonComponent
                                    color={'primary'}
                                    prefixIcon={<ImageConfig.AddOutlinedIcon/>}
                                >
                                    Create Shift
                                </ButtonComponent>
                            </LinkComponent>
                            <ShiftFilterComponent
                                setFilterData={setFilterData}
                                filterData={filterData}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <TabsWrapperComponent>
                        <div className={'d-flex d-flex-space-between mrg-right-30'}>
                            <div>
                                <TabsComponent
                                    value={activeTab}
                                    allowScrollButtonsMobile={false}
                                    onUpdate={(e: any, v: any) => {
                                        setActiveTab(v);
                                        setSelectedShifts([]);
                                        setSelectedApplication([]);
                                        setSelectedApplicationForApproval([]);
                                        setFilterData({...filterData, status: v, page: 1});
                                    }}
                                >
                                    <TabComponent label='Open' value={'open'}/>
                                    <TabComponent label='Applied' value={'pending'}/>
                                    <TabComponent label='Approved' value={'approved'}/>
                                    <TabComponent label='Completed' value={'completed'}/>
                                    <TabComponent label='Confirmed' value={'confirmed'}/>
                                    <TabComponent label='Cancelled' value={'cancelled'}/>
                                </TabsComponent>
                            </div>
                            <div className={'shift-management-list-input-box'}>
                                <SearchComponent
                                    size={'medium'}
                                    placeholder='Search Shift Title'
                                    value={filterData.search}
                                    onSearchChange={(value) => {
                                        setFilterData({...filterData, search: value, page: 1});
                                    }}
                                />
                            </div>
                        </div>
                        <TabContentComponent
                            value={activeTab}
                            selectedTab={filterData?.status}
                        >
                            <div
                                className={
                                    'facility-list-component list-screen mrg-top-10 mrg-left-25 mrg-right-30'
                                }
                            >
                                <div className='list-screen-content'>
                                    {filterData?.status === 'open' && (
                                        <TableWrapperComponent
                                            url={APIConfig.SHIFT_MANAGEMENT_LIST.URL}
                                            method={APIConfig.SHIFT_MANAGEMENT_LIST.METHOD}
                                            //   onDataLoad={handleDataLoad}
                                            isPaginated={true}
                                            extraPayload={filterData}
                                            noDataText={
                                                filterData.search
                                                    ? 'Oops! It seems like there are no shifts available for the name you have searched.'
                                                    : 'No Data'
                                            }
                                            columns={openShiftsListColumn}
                                        />
                                    )}
                                    {filterData?.status !== 'open' &&
                                        filterData?.status !== 'pending' && (
                                            <>
                                                <TableWrapperComponent
                                                    url={
                                                        APIConfig.SHIFT_MANAGEMENT_LIST_AFTER_APPROVAL.URL
                                                    }
                                                    method={
                                                        APIConfig.SHIFT_MANAGEMENT_LIST_AFTER_APPROVAL
                                                            .METHOD
                                                    }
                                                    //  onDataLoad={handleDataLoad}
                                                    isPaginated={true}
                                                    selectedQuantity={selectedShifts?.length}
                                                    extraPayload={filterData}
                                                    noDataText={
                                                        filterData.search
                                                            ? 'Oops! It seems like there are no shifts available for the name you have searched.'
                                                            : 'No Data'
                                                    }
                                                    columns={otherShiftsListColumn}
                                                />
                                            </>
                                        )}
                                    {filterData?.status === 'pending' && (
                                        <>
                                            <TableWrapperComponent
                                                url={APIConfig.SHIFT_MANAGEMENT_PENDING_LIST.URL}
                                                method={APIConfig.SHIFT_MANAGEMENT_PENDING_LIST.METHOD}
                                                isPaginated={true}
                                                extraPayload={filterData}
                                                noDataText={
                                                    filterData.search
                                                        ? 'Oops! It seems like there are no shifts available for the name you have searched.'
                                                        : 'No Data'
                                                }
                                                columns={pendingShiftListColumn}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </TabContentComponent>
                    </TabsWrapperComponent>
                </div>
            </div>
        </>
    );
};

export default ShiftRequirementList;
