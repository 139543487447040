import React, {useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import SideMenuComponent from "../../shared/components/layout/side-menu/SideMenuComponent";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../store/reducers";
import {ImageConfig} from "../../constants";
import {setSideMenuView} from "../../store/actions/navigation.action";
import ToolTipComponent from "../../shared/components/tool-tip/ToolTipComponent";

export interface AppLayoutProps {

}

const AppLayout = (props: AppLayoutProps) => {

    const {sideMenuView} = useSelector((state: IRootReducerState) => state.navigation);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const ele = document.getElementById('page-content-holder');
        if (ele) {
            ele.scrollTo(0, 0);
        }
    }, [location]);

    return (
        <div className="app-layout">
            {sideMenuView !== "expanded-view" &&
                <div className={"sidebar-view-switch-icon"}>
                    <div onClick={() => {
                        dispatch(setSideMenuView("expanded-view"));
                        console.log(sideMenuView);
                    }}>
                        <ToolTipComponent tooltip={"Switch to expanded view"} showArrow={true}>
                            <ImageConfig.CloseViewIcon/>
                        </ToolTipComponent>
                    </div>
                </div>
            }
            {sideMenuView === "expanded-view" &&
                <div className={"sidebar-view-switch-icon-right"}>
                    <div onClick={() => {
                        dispatch(setSideMenuView("default"));
                    }}>
                        <ToolTipComponent tooltip={"Switch to collapsed view"} showArrow={true}>
                            <ImageConfig.CircleLeftIconSidebar/>
                        </ToolTipComponent>
                    </div>
                </div>
            }


            <div className={`side-bar-holder ${sideMenuView}-view`}>
                <div className="side-menu-holder">
                    <SideMenuComponent/>
                </div>
            </div>
            <div className="header-and-page-container">
                <div className="page-content-holder" id={'page-content-holder'}>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
};


export default AppLayout;




