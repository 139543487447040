import {ImageConfig} from "../../../../constants";
import {CommonService} from "../../../../shared/services";
import React from "react";
import "./../hospice-home-shift-details/shiftDetailsComponent.scss"
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import DataLabelValueComponent from "../../../../shared/components/data-label-value/DataLabelValueComponent";

interface ContractTravelDetailsProps {
    contractTravelShiftDetails?: any;
}

const ContractTravelDetails = (props: ContractTravelDetailsProps) => {
    const {contractTravelShiftDetails} = props;

    return (
        <>
            <>


                <div
                    className={'shift-requirement-week-day-details-card'}>
                    <div className={'pdd-15'}>
                        <div className={'d-flex align-items-center mrg-bottom-15'}>
                            <div
                                className={'shift-requirement-week-day-header form-header-text d-flex ts-justify-content-center'}>Shift
                                Requirement
                                Details
                            </div>
                            <div className={'shift-for-text mrg-left-5'}>
                                {
                                    contractTravelShiftDetails?.requirement_details?.send_to &&
                                    <div className={'d-flex ts-align-items-center'}>(Shift
                                        created for :&nbsp;
                                        {contractTravelShiftDetails?.requirement_details?.send_to?.is_internal_hcp &&
                                            <div>Internal , &nbsp;</div>}
                                        {contractTravelShiftDetails?.requirement_details?.send_to?.is_float &&
                                            <div>Float {contractTravelShiftDetails?.requirement_details?.send_to?.specific_agencies &&
                                                <span>,</span>}&nbsp;</div>}
                                        {contractTravelShiftDetails?.requirement_details?.send_to?.specific_agencies &&
                                            <div>Agency</div>})
                                    </div>
                                }
                            </div>
                        </div>

                        <div className={'d-flex mrg-bottom-20'}>
                            <div
                                className={'d-flex flex-2 ts-align-items-center'}>
                                <div
                                    className={"d-flex mrg-right-15 ts-align-items-center"}>
                                    <ImageConfig.FacilityIcon/>
                                </div>
                                <div>
                                    {contractTravelShiftDetails?.facilityDetails?.name || '-'}
                                </div>
                            </div>
                        </div>
                        <div className={'d-flex mrg-bottom-20'}>
                            <div
                                className={'d-flex  flex-2 ts-align-items-center'}>
                                <div
                                    className={"d-flex mrg-right-15 ts-align-items-center"}>
                                    <ImageConfig.HCPTypeIcon/>
                                </div>
                                <div>
                                    {contractTravelShiftDetails?.hcpTypeDetails?.title} {contractTravelShiftDetails?.hcp_speciality_details?.title && `(${contractTravelShiftDetails?.hcp_speciality_details?.title})`}
                                    &nbsp; {contractTravelShiftDetails?.requirement_details?.gender_preference && `Req for: (${contractTravelShiftDetails?.requirement_details?.gender_preference} Staff)`}
                                </div>
                            </div>
                        </div>
                        <HorizontalLineComponent/>
                        <div className={'d-flex align-items-center mrg-bottom-15 mrg-top-15'}>
                            <div
                                className={'shift-requirement-week-day-header form-header-text d-flex ts-justify-content-center'}>
                                Visit Time
                            </div>
                        </div>
                        <div className={'d-flex mrg-bottom-20'}>
                            <div
                                className={'d-flex  flex-2 ts-align-items-center'}>
                                <div
                                    className={"d-flex mrg-right-15 ts-align-items-center"}>
                                    <ImageConfig.CalenderIconSmall/>
                                </div>
                                <div>
                                    {CommonService.convertDateFormat2(contractTravelShiftDetails?.shift_date)}
                                </div>
                            </div>
                            <div
                                className={'d-flex flex-2 ts-align-items-center'}>
                                <div
                                    className={"d-flex mrg-right-15 ts-align-items-center"}>
                                    <ImageConfig.ClockIconSmall/>
                                </div>
                                <div>
                                    {CommonService?.convertMinutesToTime(contractTravelShiftDetails?.requirement_details?.shift_timings?.start_time)} {"-"}{CommonService?.convertMinutesToTime(contractTravelShiftDetails?.requirement_details?.shift_timings?.end_time)}
                                </div>
                            </div>
                        </div>
                        {/*<div className={'d-flex mrg-bottom-20'}>*/}

                        {/*</div>*/}
                        <HorizontalLineComponent/>
                        <div className={'d-flex align-items-center mrg-bottom-15 mrg-top-15'}>
                            <div
                                className={'shift-requirement-week-day-header form-header-text d-flex ts-justify-content-center'}>
                                Job
                                Details
                            </div>
                        </div>
                        <div className={'ts-row mrg-top-15 '}>
                            <div className={'ts-col-lg-5'}>
                                <DataLabelValueComponent label={'# of positions'}>
                                    {contractTravelShiftDetails?.required_hcp_count || '-'}
                                </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col-lg-7'}>
                                <DataLabelValueComponent label={'Hours per Shift'}>
                                    {contractTravelShiftDetails?.requirement_details?.shift_duration || "-"} hours
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <div className={'ts-row mrg-top-15'}>
                            <div className={'ts-col-lg-5'}>
                                <DataLabelValueComponent label={'# of Days per Week'}>
                                    {contractTravelShiftDetails?.requirement_details?.day_per_week || '-'}
                                </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col-lg-7'}>
                                <DataLabelValueComponent label={'Job Type'}>
                                    {contractTravelShiftDetails?.requirement_details?.job_type || "-"}
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <div className={'ts-row mrg-top-15 '}>
                            <div className={'ts-col-lg-5'}>
                                <DataLabelValueComponent label={'Shift Type'}>
                                    {contractTravelShiftDetails?.shift_type || "-"}
                                </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col-lg-7'}>
                                <DataLabelValueComponent label={'# of Weeks'}>
                                    {contractTravelShiftDetails?.requirement_details?.no_of_weeks || "-"}
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <HorizontalLineComponent/>
                        <div className={'d-flex align-items-center mrg-bottom-15 mrg-top-15'}>
                            <div
                                className={'shift-requirement-week-day-header form-header-text d-flex ts-justify-content-center'}>Rates
                            </div>
                        </div>
                        <div className={'ts-row'}>
                            <div className={'ts-col-lg-4'}>
                                <DataLabelValueComponent label={'Type'}>

                                    {
                                        contractTravelShiftDetails?.requirement_details?.travel_per_diems?.map((item: any, index: any) => {
                                            return (
                                                <div className={'mrg-bottom-10'}>
                                                    {item?.type}
                                                </div>
                                            )
                                        })
                                    }

                                </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col-lg-4'}>
                                <DataLabelValueComponent
                                    label={'Rate'}
                                >
                                    {
                                        contractTravelShiftDetails?.requirement_details?.travel_per_diems?.map((item: any, index: any) => {
                                            return (
                                                <div
                                                    className={'d-flex ts-justify-content-between mrg-bottom-10'}>
                                                    <div>
                                                        $ {item?.rate} {index === 0 ? "/hr" : "/day"}
                                                    </div>
                                                    {/*<div*/}
                                                    {/*    className={'days-rate-text-orientation '}>*/}
                                                    {/*    {`(${shiftRequirementDetails?.day_per_week}days  *  $${item?.rate})`}*/}
                                                    {/*</div>*/}
                                                </div>
                                            )
                                        })
                                    }

                                </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col-lg-4'}>
                                <DataLabelValueComponent
                                    label={'Rate/week'}
                                >
                                    {
                                        contractTravelShiftDetails?.requirement_details?.travel_per_diems?.map((item: any, index: any) => {
                                            return (
                                                <div
                                                    className={'d-flex ts-justify-content-between mrg-bottom-10'}>
                                                    <div>
                                                        $ {item?.week_rate} /week
                                                    </div>
                                                    {/*<div*/}
                                                    {/*    className={'days-rate-text-orientation '}>*/}
                                                    {/*    {`(${shiftRequirementDetails?.day_per_week}days  *  $${item?.rate})`}*/}
                                                    {/*</div>*/}
                                                </div>
                                            )
                                        })
                                    }

                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <HorizontalLineComponent/>
                        <div className={'ts-row mrg-top-15'}>
                            <div className={'ts-col-lg-12'}>
                                <DataLabelValueComponent label={'Cancel Policy'}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: contractTravelShiftDetails?.requirement_details?.cancel_policy?.replace(/\n/g, '<br/>')}}/>
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <div className={'ts-row'}>
                            <div className={'ts-col-lg-12'}>
                                <DataLabelValueComponent label={'Staff Requirements'}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: contractTravelShiftDetails?.requirement_details?.staff_requirement?.replace(/\n/g, '<br/>')}}/>
                                </DataLabelValueComponent>
                            </div>

                        </div>

                        <div className={'ts-row'}>
                            <div className={'ts-col-lg-12'}>
                                <DataLabelValueComponent label={'Job Details'}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: contractTravelShiftDetails?.requirement_details?.job_details?.replace(/\n/g, '<br/>')}}/>
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <div className={'ts-row'}>
                            <div className={'ts-col-lg-12'}>
                                <DataLabelValueComponent label={'Job Benefits'}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: contractTravelShiftDetails?.requirement_details?.job_benefits?.replace(/\n/g, '<br/>')}}/>
                                </DataLabelValueComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}
export default ContractTravelDetails;
