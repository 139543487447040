import {useNavigate, useParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../shared/components/status-card/StatusCardComponent";
import CardComponent from "../../../shared/components/card/CardComponent";
// import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {APIConfig, ImageConfig, Misc,} from "../../../constants";
import {useDispatch} from "react-redux";
import {setCurrentNavParams} from "../../../store/actions/navigation.action";
import "./AgencyDetailsComponent.scss";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import DataLabelValueComponent from "../../../shared/components/data-label-value/DataLabelValueComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../shared/components/tabs/TabsComponent";
import EditHcpRateOfAgencyComponent from "./edit-agency-details/hcp-rate-edit/EditHcpRateOfAgency";
import EditAgencyAdditionalRateComponent
    from "./edit-agency-details/additional-rate-edit/EditAgencyAdditionalRateComponent";
import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import {ITableColumn} from "../../../shared/models/table.model";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import TagComponent from "../../../shared/components/tag/TagComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import EditAgencyBasicDetailsComponent from "../edit-agency/EditAgencyDetails";
import EditAgencyAdminDetailsComponent from "./edit-agency-details/edit-admin-details/EditAdminDetailsComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import DetailsHeaderComponent from "../../../shared/components/details-header-component/DetailsHeaderComponent";
import {AGENCY_LIST} from "../../../constants/RoutesConfig";
import EditPreferenceComponent from "./edit-agency-details/preference-edit/EditPreferenceComponent";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import FilePickerComponent from "../../../shared/components/file-picker/FilePickerComponent";
import _ from "lodash";
import ErrorComponent from "../../../shared/components/error/ErrorComponent";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import FormikDatePickerComponent
    from "../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import * as Yup from "yup";

const contractValidationSchema = Yup.object({
        contract: Yup.mixed().required("Contract File is required"),
        contract_expiry_date: Yup.mixed().required("Contract Date is required"),
    }
);

interface contractFormInitialValuesFormSchema {
    contract: any;
    contract_expiry_date: any;
}

const contractFormInitialValues: contractFormInitialValuesFormSchema = {
    contract: "",
    contract_expiry_date: "",
};
const AgencyDetailsScreen = () => {
    const {agencyId} = useParams();
    const [agencyDetails, setAgencyDetails] = useState<any>(null);
    const [isAgencyDetailsLoaded, setIsAgencyDetailsLoaded] = useState<boolean>(false);
    const [isAgencyDetailsLoading, setIsAgencyDetailsLoading] = useState<boolean>(false);
    const [isAgencyDetailsLoadingFailed, setIsAgencyDetailsLoadingFailed] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [initialValuesContract] = useState<any>(contractFormInitialValues);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<any>(false);
    const [isEditPreferenceOpen, setIsEditPreferenceOpen] = useState<any>(false);
    const [isEditContractOpen, setIsEditContarctOpen] = useState<any>(false);
    const [isUploadContractLoading, setIsUploadContractLoading] = useState(false);
    const [isAgencyHcpRateEditDrawerOpen, setIsAgencyEditDrawerOpen] = useState<any>(false);
    const [isAgencyAdditionalRateEditDrawerOpen, setIsAgencyAdditionalEditDrawerOpen] = useState<any>(false);
    const [activeTab, setActiveTab] = useState<any>("basic-details");
    const [agencyDetailsActiveTab, setFacilityDetailsActiveTab] = useState<any>("hcp-rates");
    const [filterData, setFilterData] = useState<any>({
        search: '',
        // sort:{},
    });
    const [currentTabView, setCurrentTabView] = useState<any>("agency-details-view");
    const [selectedFacility, setSelectedFacility] = useState<any>(null);
    const onFileUploadRef = React.useRef<any>(null);

    const openEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(true);
    }, []);

    const openEditPreferenceDrawer = useCallback(() => {
        setIsEditPreferenceOpen(true);
    }, []);

    const openContractEditDrawer = useCallback(() => {
        setIsEditContarctOpen(true);
    }, []);

    const closeContractEditDrawer = useCallback(() => {
        setIsEditContarctOpen(false);
    }, []);

    const [isHcpRatesAgencyDetailsLoading, setIsHcpRatesAgencyDetailsLoading] = useState<boolean>(false);
    const [isHcpRatesAgencyDetailsLoaded, setIsHcpRatesAgencyDetailsLoaded] = useState<boolean>(false);
    const [HcpRatesAgencyDetails, setHcpRatesAgencyDetails] = useState<any>(null);
    const [isEditAdminDrawerOpen, setIsEditAdminDrawerOpen] = useState<any>(false);
    const [isFileSelected, setIsFileSelected] = useState<boolean>(false);

    const getSelectedAgencyHcpRates = useCallback(() => {
        setIsHcpRatesAgencyDetailsLoading(true);
        const payload = {};
        CommonService._agency.AgencyDetailsHCPRatesAPICall(selectedFacility, agencyId, payload)
            .then((response: IAPIResponseType<any>) => {
                console.log(response.data);
                setIsHcpRatesAgencyDetailsLoaded(true);
                // setSelectedAgencyDetails(response?.data);
                setHcpRatesAgencyDetails(response?.data);
            }).catch((error: any) => {
            setIsHcpRatesAgencyDetailsLoaded(false);
            setIsHcpRatesAgencyDetailsLoading(true);
        }).finally(() => {
            setIsHcpRatesAgencyDetailsLoading(false);
        });

    }, [agencyId, selectedFacility]);


    useEffect(() => {
        if (selectedFacility) {
            getSelectedAgencyHcpRates();
        }
    }, [getSelectedAgencyHcpRates, selectedFacility]);

    const openAgencyHcpRateEditDrawer = useCallback(() => {
        setIsAgencyEditDrawerOpen(true);
    }, []);

    const openAgencyAdditionalRateEditDrawer = useCallback(() => {
        setIsAgencyAdditionalEditDrawerOpen(true);
    }, []);

    const closeEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(false);
    }, []);

    const closeEditPreferenceDrawer = useCallback(() => {
        setIsEditPreferenceOpen(false);
    }, []);

    const closeAgencyHcpRateEditDrawer = useCallback(() => {
        setIsAgencyEditDrawerOpen(false);
    }, []);

    const closeAgencyAdditionalRateEditDrawer = useCallback(() => {
        setIsAgencyAdditionalEditDrawerOpen(false);
    }, []);

    const getAgencyDetails = useCallback(() => {
        setIsAgencyDetailsLoading(true);
        const payload = {};
        if (agencyId) {
            CommonService._agency.AgencyDetailsAPICall(agencyId, payload)
                .then((response: IAPIResponseType<any>) => {
                    setAgencyDetails(response.data);
                    setIsAgencyDetailsLoaded(true);
                    setIsAgencyDetailsLoadingFailed(false);
                }).catch((error: any) => {
                setIsAgencyDetailsLoaded(false);
                setIsAgencyDetailsLoadingFailed(true);
            }).finally(() => {
                setIsAgencyDetailsLoading(false);
            });
        }
    }, [agencyId]);


    useEffect((): void => {
        getAgencyDetails();
    }, [getAgencyDetails]);


    useEffect(() => {
        dispatch(setCurrentNavParams('Agency Details', null, () => {
            navigate(CommonService._routeConfig.AgencyRoute());
        }));
    }, [dispatch, navigate]);

    const onFileUpload = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        setIsUploadContractLoading(true);
        const formData = CommonService.getFormDataFromJSON(values);
        formData.append("contract_expiry_date", CommonService.convertDateFormat(values?.contract_expiry_date, "YYYY-MM-DD"));
        CommonService._agency.AgencyFacilityContractUploadAPICall(selectedFacility, agencyId, formData)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getSelectedAgencyHcpRates();
                closeContractEditDrawer();
                setIsUploadContractLoading(false);
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                setIsUploadContractLoading(false);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [agencyId, closeContractEditDrawer, getSelectedAgencyHcpRates, selectedFacility]);

    const FacilityListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: 'Facility Name',
                key: 'facility_name',
                dataIndex: 'name',
                fixed: 'left',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'facility-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: 'Onboarded On',
                key: 'onboarded_on',
                dataIndex: 'onboarded_on',
                width: 137,
                align: 'left',
                render: (item: any) => {
                    if (item?.created_at) {
                        return <>{CommonService.convertDateFormat2(item?.created_at, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title: 'Admin Name',
                key: 'admin',
                dataIndex: 'admin',
                render: (item: any) => {
                    return <>
                        {item.admin_details ? item?.admin_details?.first_name + " " + item?.admin_details?.last_name : "-"}
                    </>;
                }
            },
            {
                title: 'Admin Contact No',
                key: 'admin_contact_no',
                dataIndex: 'admin_contact_no',
                width: 180,
                render: (item: any) => {
                    return <>
                        {item?.admin_details?.contact_number}
                    </>;
                }
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                align: 'left',
                width: 50,
                render: (item: any) => {
                    return <>
                        <TagComponent
                            color={item?.is_active ? CommonService.getStatusTagColor('Checked In') : CommonService.getStatusTagColor('Checked Out')}
                            // showIndicator={true}
                            label={item?.is_active ? 'Active' : 'Inactive'}/>
                    </>;
                }
            },
            {
                title: "View Details",
                dataIndex: "actions",
                align: 'center',
                key: "actions",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <LinkComponent onClick={() => {
                            setCurrentTabView("facility-details-view");
                            setSelectedFacility(item?._id);
                        }}>
                            <ImageConfig.ArrowRightOutlinedIcon color={"#4E535F"}/>
                        </LinkComponent>;
                    }
                }
            }
        ];
    }, []);

    // const handleRedirect = () => {
    //     // Open the URL in a new browser window/tab
    //     window.open(`${agencyDetails?.location_url}`, '_blank');
    // };

    const closeAdminEditDrawer = useCallback(() => {
        setIsEditAdminDrawerOpen(false);
    }, []);

    const openAdminEditDrawer = useCallback(() => {
        setIsEditAdminDrawerOpen(true);
    }, []);

    const downloadHcpDocument = useCallback((hcpDocumentFileKey: string) => {
        const payload: any = {};
        payload.file_key = hcpDocumentFileKey;
        CommonService._meta.DownloadDocumentAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                const link = document.createElement('a');
                link?.setAttribute('href', response?.data?.data);
                document.body.appendChild(link);
                link.click();
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error || "Failed To download Document", "error");
        });
    }, []);

    const onAgencyActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  Agency`,
                image: item?.is_active ? ImageConfig.AgencyDeactivateIcon : ImageConfig.AgencyActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'deactivate' : 'activate'}  the
    Agency?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: item?.is_active,
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._agency.ActivateDeactivateAgencyFromList(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    getAgencyDetails();
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, [getAgencyDetails]);

    const deleteHcpDocument = useCallback((hcpDocumentFileKey: string, fileType?: string) => {
        const payload: any = {};
        payload.file_type = "agency_contract";
        payload.file_key = hcpDocumentFileKey;
        CommonService._hcp.DeleteDocumentAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getSelectedAgencyHcpRates();
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error || "Failed To delete Document", "error");

        });
    }, [getSelectedAgencyHcpRates]);

    const onHandleDeleteDocument = useCallback((document: string, key?: string) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `Delete ${document}`,
                image: ImageConfig.DocumentDeleteIcon,
                confirmationDescription: `Are you sure you want to delete the
    ${document}?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: true,
                yes: {
                    color: "error",
                    text: "Yes, Delete",
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            if (key) {
                deleteHcpDocument(key);
            } else {
                deleteHcpDocument(HcpRatesAgencyDetails?.contract?.key, "contract");
            }
        });
    }, [HcpRatesAgencyDetails, deleteHcpDocument]);

    return (
        <>
            <div className={'agency-details-component-screen'}>
                <DrawerComponent isOpen={isEditDrawerOpen} direction={"right"} onClose={closeEditDrawer}>
                    <EditAgencyBasicDetailsComponent AdminDetails={agencyDetails} closeEditDrawer={closeEditDrawer}
                                                     getAgencyDetails={getAgencyDetails}/>
                </DrawerComponent>
                <DrawerComponent isOpen={isEditPreferenceOpen} direction={"right"} onClose={closeEditPreferenceDrawer}>
                    <EditPreferenceComponent HcpRatesAgencyDetails={HcpRatesAgencyDetails}
                                             closeEditDrawer={closeEditPreferenceDrawer}
                                             getAgencyDetails={getSelectedAgencyHcpRates}
                                             agencyId={agencyId}
                                             selectedFacility={selectedFacility}
                    />
                </DrawerComponent>
                <DrawerComponent isOpen={isEditAdminDrawerOpen} direction={"right"} onClose={closeAdminEditDrawer}>
                    <EditAgencyAdminDetailsComponent facilityDetails={agencyDetails}
                                                     closeEditDrawer={closeAdminEditDrawer}
                                                     getAgencyDetails={getAgencyDetails}/>
                </DrawerComponent>
                <DrawerComponent isOpen={isAgencyHcpRateEditDrawerOpen} direction={"right"}
                                 onClose={closeAgencyHcpRateEditDrawer}>
                    <EditHcpRateOfAgencyComponent selectedAgencyDetails={HcpRatesAgencyDetails}
                                                  agencyId={agencyId}

                                                  selectedFacility={selectedFacility}
                                                  getAgencyDetails={getSelectedAgencyHcpRates}
                                                  closeAgencyHcpRateEditDrawer={closeAgencyHcpRateEditDrawer}/>
                </DrawerComponent>
                <DrawerComponent isOpen={isAgencyAdditionalRateEditDrawerOpen} direction={"right"}
                                 onClose={closeAgencyAdditionalRateEditDrawer}>
                    <EditAgencyAdditionalRateComponent selectedAgencyDetails={HcpRatesAgencyDetails} agencyId={agencyId}
                                                       getAgencyDetails={getSelectedAgencyHcpRates}
                                                       selectedFacility={selectedFacility}
                                                       closeAgencyAdditionalRateEditDrawer={closeAgencyAdditionalRateEditDrawer}/>
                </DrawerComponent>
                <DrawerComponent
                    isOpen={isEditContractOpen}
                    onClose={closeContractEditDrawer}
                    className={"edit-drawer"}>
                    <>
                        <div className={'edit-facility-component'}>
                            <div className={'edit-facility-header'}>
                                <div
                                    className={'form-header-text'}>Edit Contract Details
                                </div>
                                <div className={'icon-button'} onClick={closeContractEditDrawer}>
                                    <ImageConfig.CrossIcon/></div>
                            </div>
                        <HorizontalLineComponent/>

                            <div className="edit-facility-form-wrapper">
                                <Formik
                                    validationSchema={contractValidationSchema}
                                    initialValues={initialValuesContract}
                                    validateOnChange={false}
                                    validateOnBlur={true}
                                    enableReinitialize={true}
                                    validateOnMount={true}
                                    onSubmit={onFileUpload}
                                    innerRef={onFileUploadRef}
                                >
                                    {({
                                          values,
                                          errors,
                                          validateForm,
                                          touched,
                                          isValid,
                                          setFieldValue,
                                          isSubmitting
                                      }) => {
                                        // eslint-disable-next-line react-hooks/rules-of-hooks
                                        useEffect(() => {
                                            validateForm();
                                            if (values.contract) {
                                                setIsFileSelected(true);
                                            } else {
                                                setIsFileSelected(false);
                                            }
                                        }, [validateForm, values]);
                                        return (
                                            <Form className={"t-form  form-holder scroll-content"} noValidate={true}>
                                                <div className={'add-form-holder'}>
                                                    <div className={'add-form-input-container'}>
                                                        <div className="edit-component-content">
                                                    {
                                                        (!values.contract) && <>
                                                            <FilePickerComponent maxFileCount={1}
                                                                                 height={"524px"}
                                                                                 id={"sc_upload_btn"}
                                                                                 onFilesDrop={(acceptedFiles, rejectedFiles) => {
                                                                                     if (acceptedFiles && acceptedFiles.length > 0) {
                                                                                         const file = acceptedFiles[0];
                                                                                         setFieldValue('contract', file);
                                                                                     }
                                                                                 }}
                                                                                 acceptedFileTypes={["pdf"]}
                                                                // uploadDescription={"(upload only one file)"}
                                                                // acceptedFilesText={"Only .pdf files are supported"}
                                                            />
                                                            {
                                                                (_.get(touched, "contract") && !!(_.get(errors, "contract"))) &&
                                                                <ErrorComponent
                                                                    errorText={(_.get(errors, "contract"))}/>
                                                            }
                                                        </>
                                                    }
                                                    <>
                                                        {
                                                            (values.contract) && <>
                                                                <div className="file-preview-wrapper">
                                                                    <div className="file-name">
                                                                        {values.contract.name}
                                                                    </div>
                                                                    <div className="action-button">
                                                                        <div
                                                                            className={'center-element mrg-left-25 contract-action-text icon-button delete-button'}
                                                                            onClick={() => {
                                                                                setFieldValue('contract', '');
                                                                            }}
                                                                        >
                                                                            <ImageConfig.DeleteOutlineIcon/><span
                                                                            className={'mrg-left-10'}>Delete</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/*<HorizontalLineComponent/>*/}
                                                                <div className={'d-flex'}>
                                                                    <div className={'flex-1'}>
                                                                        <LabelComponent title={'Contract Expiry Date'}/>
                                                                        <Field name={'contract_expiry_date'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikDatePickerComponent
                                                                                        // minDate={new Date()}
                                                                                        placeholder={'Select Date'}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                </div>
                                                    </div>
                                                </div>
                                                <div className="facility-edit-save-button d-flex mrg-top-10">
                                                    {
                                                        isFileSelected && (
                                                            <>
                                                                <ButtonComponent
                                                                    color={'download-button'}
                                                                    isLoading={isUploadContractLoading}
                                                                    className={"save-btn"}
                                                                    type={"submit"}
                                                                >
                                                                    Save Details
                                                                </ButtonComponent>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                            {/*<div className="action-button contract-save-button">*/}
                            {/*    {*/}
                            {/*        isFileSelected && (*/}
                            {/*            <>*/}
                            {/*                /!* eslint-disable-next-line react/jsx-no-undef *!/*/}
                            {/*                <ButtonComponent*/}
                            {/*                    isLoading={isUploadContractLoading}*/}
                            {/*                    className={"save-btn"}*/}
                            {/*                    onClick={() => {*/}
                            {/*                        if (onFileUploadRef.current) {*/}
                            {/*                            onFileUploadRef.current.submitForm();*/}
                            {/*                        }*/}
                            {/*                    }}*/}
                            {/*                >*/}
                            {/*                    Save Details*/}
                            {/*                </ButtonComponent>*/}
                            {/*            </>*/}
                            {/*        )*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                    </>
                </DrawerComponent>

                {
                    isAgencyDetailsLoading && <div className="h-v-center">
                        <LoaderComponent type={'spinner'}/>
                    </div>
                }
                {
                    isAgencyDetailsLoadingFailed &&
                    <div className="h-v-center">
                        <StatusCardComponent title={'Failed to Load'}/>
                    </div>
                }
                {
                    isAgencyDetailsLoaded && <>
                        <DetailsHeaderComponent navigate={navigate} details={agencyDetails} backAddress={AGENCY_LIST}
                                                detailsModule={'Agency'}/>
                        {currentTabView === "agency-details-view" &&
                            <TabsWrapperComponent className={'mrg-top-25'}>
                                <TabsComponent
                                    value={activeTab}
                                    allowScrollButtonsMobile={false}
                                    onUpdate={(e: any, v: any) => {
                                        setActiveTab(v);
                                        setFilterData({...filterData, agency_id: agencyId, page: 1});
                                    }}
                                >
                                    <TabComponent label="Basic Details" value={"basic-details"}/>
                                    <TabComponent label="Facility Details" value={"facility-details"}/>
                                </TabsComponent>
                                <div className={'flex-right mrg-right-100'}>
                                    <ButtonComponent
                                        onClick={() => {
                                            onAgencyActivateDeactivate(agencyDetails);
                                        }}
                                        color={"deactivate-button"}
                                        className={'cancel-shift-button'}
                                    >
                                        {`${agencyDetails?.is_active ? 'Deactivate' : 'Activate'}  Agency`}
                                    </ButtonComponent>
                                </div>
                                <TabContentComponent selectedTab={activeTab} value={"basic-details"}>
                                    <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>
                                        <div className={'details-screen'}>
                                            <div className="details-screen-title-with-image-wrapper pdd-10">
                                                <div
                                                    className={'details-screen-title-wrapper center-right-element mrg-left-20 mrg-right-20'}>
                                                    <div className={'details-screen-title'} onClick={() => {
                                                        console.log(agencyDetails);
                                                    }
                                                    }>Agency
                                                        Details
                                                    </div>
                                                    <div onClick={openEditDrawer} className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Agency Details</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <HorizontalLineComponent/>
                                            <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Agency Name'}>
                                                            {agencyDetails?.name || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Phone Number'}>
                                                            {agencyDetails?.contact_number || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Email ID'}>
                                                            {agencyDetails?.email || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Address'}>
                                                            {`${agencyDetails?.address?.street}, ${agencyDetails?.address?.city}, ${agencyDetails?.address?.state}, ${agencyDetails?.address?.zip_code}, ${agencyDetails?.address?.region}, ${agencyDetails?.address?.country}` || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Timezone'}>
                                                            {CommonService.getTimezoneTitleByCode(agencyDetails?.timezone) || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Region'}>
                                                            {
                                                                agencyDetails?.address?.region
                                                            }
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    {/*<div className={'ts-col-lg-3'}>*/}
                                                    {/*    <DataLabelValueComponent label={'Maps Location Link'}>*/}
                                                    {/*        <div onClick={handleRedirect} style={{cursor: "pointer"}}*/}
                                                    {/*             className={'underline-text'}*/}
                                                    {/*        >*/}
                                                    {/*            View Location*/}
                                                    {/*        </div>*/}
                                                    {/*    </DataLabelValueComponent>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className={"ts-row"}>
                                                    <div className={'ts-col-lg-6'}>
                                                        <DataLabelValueComponent label={'About the Agency'}>
                                                            {agencyDetails?.about || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardComponent>
                                    <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>
                                        <div className={'details-screen'}>
                                            <div className="details-screen-title-with-image-wrapper pdd-10">
                                                <div
                                                    className={'details-screen-title-wrapper center-right-element  mrg-left-20 mrg-right-20'}>
                                                    <div className={'details-screen-title'}>Admin Details
                                                    </div>
                                                    <div onClick={openAdminEditDrawer} className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Admin Details</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <HorizontalLineComponent/>
                                            <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Admin Name'}>
                                                            {agencyDetails?.admin_details?.first_name + " " + agencyDetails?.admin_details?.last_name || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Phone Number'}>
                                                            {agencyDetails?.admin_details?.contact_number || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Email ID'}>
                                                            {agencyDetails?.admin_details?.email || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                    <div className={'ts-col-lg-3'}>
                                                        <DataLabelValueComponent label={'Notifications Preference'}>
                                                            {(agencyDetails?.admin_details?.notification_preferences?.isSmsRequired ? "SMS, " : "") + (agencyDetails?.admin_details?.notification_preferences?.isEmailRequired ? "Email, " : "") + (agencyDetails?.admin_details?.notification_preferences?.isPush_notificationRequired ? "In App " : "") || '-'}
                                                        </DataLabelValueComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardComponent>
                                </TabContentComponent>
                                <TabContentComponent selectedTab={activeTab} value={"facility-details"}>
                                    {/*<div className={'mrg-left-100 mrg-right-100 d-flex'} style={{justifyContent: "right"}}>*/}
                                    {/*    <SearchComponent*/}
                                    {/*        size={'medium'}*/}
                                    {/*        placeholder="Search Facility Name"*/}
                                    {/*        value={filterData.search}*/}
                                    {/*        onSearchChange={(value) => {*/}
                                    {/*            setFilterData({...filterData, search: value, page: 1});*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div
                                        className={'facility-list-component list-screen mrg-left-100 mrg-right-100'}>
                                        <div className="list-screen-content">
                                            <TableWrapperComponent url={APIConfig.FACILITY_LIST.URL}
                                                                   method={APIConfig.FACILITY_LIST.METHOD}
                                                                   isPaginated={true}
                                                                   extraPayload={filterData}
                                                                   noDataText={filterData.search ? 'Oops! It seems like there are no facilities available for the name you have searched.' : 'No Data'}
                                                                   columns={FacilityListColumn}/>
                                        </div>
                                    </div>
                                </TabContentComponent>
                            </TabsWrapperComponent>}
                        {currentTabView === "facility-details-view" &&
                            <>
                                <div className={"agency-tabs-details"}>
                                    <TabsWrapperComponent className={'mrg-top-25'}>
                                        <TabsComponent
                                            value={agencyDetailsActiveTab}
                                            allowScrollButtonsMobile={false}
                                            onUpdate={(e: any, v: any) => {
                                                setFacilityDetailsActiveTab(v);
                                            }}
                                        >
                                            <TabComponent label="Facility Agency Rates" value={"hcp-rates"}/>
                                            <TabComponent label="Additional Rates" value={"additional-rates"}/>
                                            <TabComponent label="Preferences" value={"preference"}/>
                                            <TabComponent label="Contract" value={"contract"}/>
                                        </TabsComponent>

                                        <TabContentComponent selectedTab={agencyDetailsActiveTab} value={"hcp-rates"}>
                                            <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>
                                                <div className={'details-screen'}>
                                                    <div className="details-screen-title-with-image-wrapper pdd-10">
                                                        <div
                                                            className={'details-screen-title-wrapper center-right-element'}>
                                                            <div className={'details-screen-title'}>
                                                                <div className={'d-flex'}>
                                                                    <div className={"mrg-right-10 icon-button"}
                                                                         onClick={() => {
                                                                             setCurrentTabView("agency-details-view");
                                                                         }}><ImageConfig.BackArrow/>
                                                                    </div>
                                                                    <div>
                                                                        Agency Staff Rates
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div onClick={openAgencyHcpRateEditDrawer}
                                                                 className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Agency Staff Rates</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <HorizontalLineComponent/>
                                                    <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                        {(isHcpRatesAgencyDetailsLoaded && HcpRatesAgencyDetails?.hcp_type_rates?.length > 0) && <>
                                                            <div className={'ts-row'}>
                                                                {HcpRatesAgencyDetails?.hcp_type_rates?.map((hcpRate: any, index: any) => {
                                                                    return (
                                                                        <>
                                                                            <div className={'ts-col-lg-3'}>
                                                                                <DataLabelValueComponent
                                                                                    label={`${hcpRate?.hcp_type_details?.title}`}>
                                                                                    $ {hcpRate?.rate || '-'}
                                                                                </DataLabelValueComponent>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })}
                                                            </div>
                                                        </>}
                                                        {isHcpRatesAgencyDetailsLoading && <>
                                                            <div className={'center-element'}>
                                                                <LoaderComponent type={"spinner"}/>
                                                            </div>
                                                        </>}
                                                        {(HcpRatesAgencyDetails?.hcp_type_rates?.length === 0) && <>
                                                            <StatusCardComponent title={"No Staff Rates Found"}/>
                                                        </>}
                                                    </div>
                                                </div>
                                            </CardComponent>
                                        </TabContentComponent>
                                        <TabContentComponent selectedTab={agencyDetailsActiveTab}
                                                             value={"additional-rates"}>
                                            <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>
                                                <div className={'details-screen'}>
                                                    <div className="details-screen-title-with-image-wrapper pdd-10">
                                                        <div
                                                            className={'details-screen-title-wrapper center-right-element'}>
                                                            <div className={'details-screen-title'}>
                                                                <div className={'d-flex'}>
                                                                    <div className={"mrg-right-10 icon-button"}
                                                                         onClick={() => {
                                                                             setCurrentTabView("agency-details-view");
                                                                             // setHcpRatesAgencyDetails(null);
                                                                         }}><ImageConfig.BackArrow/>
                                                                    </div>
                                                                    <div>
                                                                        Additional Rates
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div onClick={openAgencyAdditionalRateEditDrawer}
                                                                 className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Agency Additional Rates</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <HorizontalLineComponent/>
                                                    <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                        {HcpRatesAgencyDetails?.addl_rates?.length > 0 && <>
                                                            <div className={'ts-row'}>
                                                                {HcpRatesAgencyDetails?.addl_rates?.map((hcpRate: any, index: any) => {
                                                                    return (
                                                                        <>
                                                                            <div className={'ts-col-lg-3'}>
                                                                                <DataLabelValueComponent
                                                                                    label={`${hcpRate?.addl_rate_details?.title}`}>
                                                                                    $ {hcpRate?.rate || '-'}
                                                                                </DataLabelValueComponent>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })}
                                                            </div>
                                                        </>}
                                                        {isHcpRatesAgencyDetailsLoading && <>
                                                            <LoaderComponent type={"spinner"}/>
                                                        </>}
                                                        {(HcpRatesAgencyDetails?.addl_rates?.length === 0) && <>
                                                            <StatusCardComponent title={"No Additional Rates Found"}/>
                                                        </>}
                                                    </div>
                                                </div>
                                            </CardComponent>
                                        </TabContentComponent>
                                        <TabContentComponent selectedTab={agencyDetailsActiveTab} value={"preference"}>
                                            <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>
                                                <div className={'details-screen'}>
                                                    <div className="details-screen-title-with-image-wrapper pdd-10">
                                                        <div
                                                            className={'details-screen-title-wrapper center-right-element'}>
                                                            <div className={'details-screen-title'}>
                                                                <div className={'d-flex'}>
                                                                    <div className={"mrg-right-10 icon-button"}
                                                                         onClick={() => {
                                                                             setCurrentTabView("agency-details-view");
                                                                             // setHcpRatesAgencyDetails(null);
                                                                         }}><ImageConfig.BackArrow/>
                                                                    </div>
                                                                    <div>
                                                                        Preferences
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div onClick={openEditPreferenceDrawer}
                                                                 className={'icon-button'}>
                                                            <span
                                                                className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                                className={'mrg-right-10'}/>Edit Preference</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <HorizontalLineComponent/>
                                                    <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                        <div className={'ts-row'}>

                                                            <div className={'ts-col-lg-6'}>
                                                                <DataLabelValueComponent
                                                                    label={'Billing Every (Weekly, Bi-weekly, Etc)'}>
                                                                    {HcpRatesAgencyDetails?.preferences?.bill_payment_frequency || '-'}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                            <div className={'ts-col-lg-3'}>
                                                                <DataLabelValueComponent
                                                                    label={'Preferred Mode of Contact'}>
                                                                    {HcpRatesAgencyDetails?.preferences?.mode_of_contact === "both" ? "Both SMS and Email" : (HcpRatesAgencyDetails?.preferences?.mode_of_contact || "-")}
                                                                </DataLabelValueComponent>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardComponent>
                                        </TabContentComponent>
                                        <TabContentComponent value={"contract"} selectedTab={agencyDetailsActiveTab}>
                                            <CardComponent size={"sm"} className={'mrg-right-100 mrg-left-100'}>
                                                <div className={'details-screen'}>
                                                    <div className="details-screen-title-with-image-wrapper pdd-10">
                                                        <div
                                                            className={'details-screen-title-wrapper center-right-element'}>
                                                            <div className={'details-screen-title'}>
                                                                <div className={'d-flex'}>
                                                                    <div className={"mrg-right-10 icon-button"}
                                                                         onClick={() => {
                                                                             setCurrentTabView("agency-details-view");
                                                                         }}><ImageConfig.BackArrow/>
                                                                    </div>
                                                                    <div>
                                                                        Contract
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <HorizontalLineComponent/>
                                                    {HcpRatesAgencyDetails?.contract && (
                                                        <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                            <div className={'ts-row'}>
                                                                <div className={'ts-col-lg-6'}>
                                                                    <DataLabelValueComponent
                                                                        label={'Expiry Date'}>
                                                                        {HcpRatesAgencyDetails?.contract_expiry_date ? CommonService.convertDateFormat(
                                                                            HcpRatesAgencyDetails?.contract_expiry_date,
                                                                            'MM-DD-YYYY',
                                                                        ) : "-"}
                                                                    </DataLabelValueComponent>
                                                                </div>
                                                                <div className={'ts-col-lg-6'}>
                                                                    <DataLabelValueComponent
                                                                        label={'Contract'}>
                                                                        <div
                                                                            className={'action-button-trigger-wrapper d-flex'}>
                                                                            {HcpRatesAgencyDetails?.contract?.url && <div
                                                                                className={'center-element contract-action-text icon-button'}
                                                                                onClick={() => {
                                                                                    CommonService.previewPDF(HcpRatesAgencyDetails?.contract?.url);
                                                                                }}>
                                                                                <ImageConfig.ViewContractIcon/><span
                                                                                className={'mrg-left-10'}>View</span></div>}
                                                                            {HcpRatesAgencyDetails?.contract?.key && <div
                                                                                className={'center-element mrg-left-25 contract-action-text icon-button'}
                                                                                onClick={() => {
                                                                                    downloadHcpDocument(HcpRatesAgencyDetails?.contract?.key);
                                                                                }}>
                                                                                <ImageConfig.DownloadIcon/><span
                                                                                className={'mrg-left-10'}>Download</span>
                                                                            </div>}
                                                                            {
                                                                                HcpRatesAgencyDetails?.contract?.key && <div
                                                                                    className={'center-element mrg-left-25 contract-action-text icon-button delete-button'}
                                                                                    onClick={() => {
                                                                                        // deleteHcpDocument(hcpDetails?.contract?.key);
                                                                                        onHandleDeleteDocument("Contract");
                                                                                    }}
                                                                                >
                                                                                    <ImageConfig.DeleteOutlineIcon/><span
                                                                                    className={'mrg-left-10'}>Delete</span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </DataLabelValueComponent>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {
                                                        !HcpRatesAgencyDetails?.contract && (
                                                            <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                                                <div className="no-content">
                                                                    <div className="action-button">
                                                                        <div className="description-text">
                                                                            No Contract Available
                                                                        </div>
                                                                        <ButtonComponent
                                                                            color={'download-button'}
                                                                            onClick={() => {
                                                                                openContractEditDrawer();
                                                                            }}
                                                                            prefixIcon={<ImageConfig.UploadIcon/>}>Upload
                                                                            Contract</ButtonComponent>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </CardComponent>
                                        </TabContentComponent>
                                    </TabsWrapperComponent>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </>
    );
};
export default AgencyDetailsScreen;
