import "./SideMenuComponent.scss";
import {ImageConfig} from "../../../../constants";
import {NavLink, useNavigate} from "react-router-dom";
import {CommonService} from "../../../services";
import {useCallback} from "react";
import {logout} from "../../../../store/actions/account.action";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentNavParams} from "../../../../store/actions/navigation.action";
import {IRootReducerState} from "../../../../store/reducers";
import ButtonComponent from "../../button/ButtonComponent";
import {Tooltip} from "@mui/material";
import ToolTipComponent from "../../tool-tip/ToolTipComponent";

interface SideMenuComponentProps {

}

interface IMenuGroups {
    id: string;
    title: string;
    is_separator?: boolean;
    icon: any;
    path: any;
    type?: any;
}

const MENUITEM: IMenuGroups[] = [
    // {
    //     title: "Dashboard",
    //     id: "dashboard",
    //     icon: ImageConfig.DashboardIcon,
    //     path: CommonService._routeConfig.Dashboard()
    // },
    {
        title: "Scheduler",
        id: "scheduler",
        icon: ImageConfig.SchedulerSidebarIcon,
        path: CommonService._routeConfig.SchedulerRoute()
    },
    {
        title: "Shift",
        id: "shift-requirement",
        icon: ImageConfig.ShiftManagementSidebarIcon,
        path: CommonService._routeConfig.ShiftManagementRoute(),

    },
    {
        title: "Facility",
        id: "Facility",
        icon: ImageConfig.FacilitySidebarIcon,
        path: CommonService._routeConfig.FacilityRoute()
    },

    {
        title: "Staff",
        id: "hcp-management",
        icon: ImageConfig.HcpManagementSidebarIcon,
        path: CommonService._routeConfig.HcpManagementRoute(),

    },
    {
        title: "Internal Staff",
        id: "internal-hcp-management",
        icon: ImageConfig.InternalHcpManagementSidebarIcon,
        path: CommonService._routeConfig.InternalHcpManagementRoute(),

    },
    {
        title: "Agency",
        id: "agency",
        icon: ImageConfig.AgencySidebarIcon,
        path: CommonService._routeConfig.AgencyRoute()
    },

    {
        title: "User",
        id: "user-management",
        icon: ImageConfig.UserManagementSidebarIcon,
        path: CommonService._routeConfig.UserManagementRoute()
    },
    // {
    //     path: "",
    //     id: "user-view",
    //     title: '',
    //     icon: ImageConfig.PersonProfileOutline,
    //     type: 'user'
    // },
    // {
    //     path: "",
    //     id: "logout",
    //     title: 'Logout',
    //     icon: ImageConfig.LogoutIcon,
    //     type: 'action'
    // },

];


const SideMenuComponent = (props: SideMenuComponentProps) => {

        const navigate = useNavigate();
        const dispatch = useDispatch();
        const {sideMenuView} = useSelector((state: IRootReducerState) => state.navigation);
        const {currentUser} = useSelector((state: IRootReducerState) => state.account);

        const handleMenuClick = useCallback((menu: string) => {
            dispatch(setCurrentNavParams("menu"));
            if (menu === 'Logout') {
                CommonService._alert.showToast("Logged out", "success");
                navigate(CommonService._routeConfig.LoginRoute());
                dispatch(logout());
            }
        }, [dispatch, navigate]);


        return (
            <div className="menu-list">
                <div className={'branding-wrapper d-flex'}>
                    <div>
                        <ImageConfig.VarsShortLogoIcon/>
                    </div>
                    {sideMenuView === "expanded-view" &&
                        <div className={'vars-health-logo-text'}>
                            VARS
                        </div>}
                </div>
                {
                    MENUITEM?.map((menu: IMenuGroups, index: number) => {
                        return <div className={`menu-group ${menu.is_separator ? 'separator' : ''}`} key={menu.id}>

                            <div className="menu-group-items">
                                {
                                    menu?.type === "action" ?

                                        <div className={'action-wrapper'}>
                                            <ButtonComponent
                                                size={sideMenuView === "expanded-view" ? "large-button-size" : "small"}
                                                color={"default"} key={menu.title}
                                                id={`${menu?.title.toLowerCase().split(' ').join('_')}_menu`}
                                                onClick={() => {
                                                    handleMenuClick(menu?.title);
                                                }}>
                                                <Tooltip title={`${sideMenuView !== "expanded-view" ? menu?.title : ""}`}
                                                         placement={'right'}
                                                         arrow>
                                                <span className={'center-element'}>

                                                <menu.icon/>
                                                    {sideMenuView === "expanded-view" ?
                                                        <span className={'mrg-left-10'}> {menu?.title}</span> : " "}
                                                </span>
                                                </Tooltip>
                                            </ButtonComponent>

                                        </div>
                                        :
                                        (menu?.type === "user" && <>
                                            <div className={'user-wrapper'}>
                                                <div key={menu.title}
                                                     className="user-item"
                                                     id={`${menu?.title.toLowerCase().split(' ').join('_')}_menu`}
                                                     {...props}
                                                >
                                                    <span className="user-item-icon">
                                                       <menu.icon/>
                                                    </span>
                                                    {sideMenuView === "expanded-view" &&
                                                        <span
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "#828f9a",
                                                                marginTop: "3px",
                                                            }}>{currentUser?.first_name + " " + currentUser?.last_name || "User"}</span>}
                                                </div>
                                            </div>
                                        </>)}
                                {
                                    menu?.type !== "action" && menu?.type !== "user" && (
                                        <Tooltip
                                            title={`${(sideMenuView !== "expanded-view") ? menu.title : ""}`}
                                            placement={'right'}
                                            arrow>
                                            <NavLink key={menu.title} to={menu.path}
                                                     onClick={(event) => {
                                                         dispatch(setCurrentNavParams(menu?.title));
                                                         // alert(menu?.title)
                                                         if (menu.path === "") {
                                                             event.preventDefault();
                                                             CommonService.ComingSoon();
                                                             return false;
                                                         }
                                                     }}
                                                     className={`menu-item ${sideMenuView === "expanded-view" ? "expanded-alignment" : "default-alignment"}`}
                                                     id={`${menu.title.toLowerCase().split(' ').join('_')}_menu`}
                                                     {...props}
                                            >

                                            <span className="menu-item-icon">
                                            <menu.icon/>
                                            </span>
                                                {sideMenuView === "expanded-view" &&
                                                    <>
                                                        {
                                                            (menu?.title)?.length > 20 ?
                                                                <ToolTipComponent
                                                                    tooltip={menu?.title}
                                                                    showArrow={true}
                                                                    position={"top"}
                                                                >
                                                                    <div
                                                                        className={"ellipses-for-table-data property-name mrg-left-10"}>
                                                                        {menu?.title}
                                                                    </div>
                                                                </ToolTipComponent> :
                                                                <div className="agency-name mrg-left-10">
                                                                    {menu?.title || '-'}
                                                                </div>
                                                        }
                                                    </>}
                                            </NavLink>
                                        </Tooltip>
                                    )}
                            </div>

                        </div>;

                    })
                }
                <div className={`logged-in-user ${sideMenuView === "expanded-view" ? "expanded-view" : ""}`}>
                    <div className="user-avatar">
                        <ImageConfig.UserIconCircle/>
                    </div>
                    {sideMenuView === "expanded-view" && (
                        <>
                            <div className="user-name">
                                {
                                    (currentUser?.first_name?.length + currentUser?.last_name?.length > 19 ? (
                                        <ToolTipComponent
                                            tooltip={
                                                <>
                                                    {currentUser?.first_name + " " + currentUser?.last_name}
                                                </>
                                            }>
                                            {
                                                <div className={"ellipses-text"}>
                                                    {currentUser?.first_name + " " + currentUser?.last_name}
                                                </div>
                                            }
                                        </ToolTipComponent>) : currentUser?.first_name + " " + currentUser?.last_name)
                                }
                            </div>
                        </>
                    )}
                    <div className="log-out-icon" onClick={() => handleMenuClick('Logout')}>
                        <ToolTipComponent tooltip={"Logout"} showArrow={true}>
                            <ImageConfig.LogOutCircleIcon/>
                        </ToolTipComponent>
                    </div>
                </div>
            </div>
        );

    }
;

export default SideMenuComponent;
;
