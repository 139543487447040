import "./AddUserDetailsComponent.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
//import FormDebuggerComponent from "../../../../shared/components/form-debugger/FormDebuggerComponent";
import {useNavigate} from "react-router-dom";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {ImageConfig, Misc} from "../../../constants";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import SelectDropdownComponent from "../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent";
import {IRootReducerState} from "../../../store/reducers";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import {useSelector} from "react-redux";
import PhoneInputComponent from "../../../shared/components/phoneInput/PhoneInputComponent";
import {addUserSteps} from "../../../data";
import {USER_MANAGEMENT} from "../../../constants/RoutesConfig";
import FormikCheckBoxComponent from "../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";
import RadioButtonGroupComponent
    from "../../../shared/components/form-controls/radio-button-group/RadioButtonGroupComponent";
import StepperComponent from "../../../shared/components/stepper-component/StepperComponent";

interface UserDetailsComponentProps {
    prev: () => void;
}

const UserValidationSchema = Yup.object().shape({
        admin_first_name: Yup.string()
            .min(2, 'First Name should be at least 2 characters')
            .max(50, 'First Name should not exceed 50 characters')
            .required('First Name is required'),
        admin_last_name: Yup.string()
            .min(2, 'Last Name should be at least 2 characters')
            .max(50, 'Last Name should not exceed 50 characters')
            .required('Last Name is required'),
    admin_contact_number: Yup.string().min(12, "min 10 digits").required("Phone Number is required"),
    admin_email: Yup.string().email('Invalid email address').required('Email Address is required'),
    }
);

interface formInitialValuesFormSchema {
    admin_contact_number: any;
    admin_email: string;
    admin_first_name: any;
    admin_last_name: any;
    notification_preferences: {
        isSmsRequired: any;
        isEmailRequired: any;
        isPush_notificationRequired: any;
    };
}

const formInitialValues: formInitialValuesFormSchema = {
    admin_contact_number: "",
    admin_email: "",
    admin_first_name: "",
    admin_last_name: "",
    notification_preferences: {
        isSmsRequired: true,
        isEmailRequired: true,
        isPush_notificationRequired: true,
    }
};


const AddUserDetailsComponent = (props: UserDetailsComponentProps) => {
    const {facilityList, agencyList} = useSelector((state: IRootReducerState) => state.staticData);
    // const {prev} = props;
    const [filterData, setFilterData] = useState<any>({
        selectedFacility: null,
        selectedAgency: null,
    });
    const [initialValues] = useState<formInitialValuesFormSchema>(formInitialValues);
    const navigate = useNavigate();
    const [userAddActiveStep] = React.useState(0);
    const [selectedUserType, setSelectedUserType] = useState("facility");


    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
        };

        let apiCall: any = {};
        if (filterData?.selectedFacility) {
            payload.facility_id = filterData?.selectedFacility;
            apiCall = CommonService._facility.AddFacilityAdminApiCall(payload);
        }

        if (filterData?.selectedAgency) {
            payload.agency_id = filterData?.selectedAgency;
            apiCall = CommonService._facility.AddAgencyAdminApiCall(payload);
        }


        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            navigate(CommonService._routeConfig.UserManagementRoute());
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                CommonService._alert.showToast(error?.error, "error");
            }).finally(() => {
            setSubmitting(false);
        });
        // eslint-disable-next-line
    }, [navigate, filterData?.selectedFacility, filterData?.selectedAgency]);

    return (
        <div className={'add-user-details-component'}>
            <div className={'add-user-sidebar-steper'}>
                <div className={'add-facility-sidebar-steper-wrapper pdd-20'}>
                    <div className={'flex-1'}>
                        <div className={'adding-module-branding-wrapper'}>
                            <div className="menu-item-icon">
                                <ImageConfig.VarsShortLogoIcon/>
                            </div>

                            <div className={'vars-health-logo-text'}>
                                VARS
                            </div>
                        </div>
                        <div className={'form-top-header-text mrg-top-40'}>
                            User Onboarding
                        </div>
                        <StepperComponent activeStep={userAddActiveStep} steps={addUserSteps}/>
                    </div>
                    <div>
                        <ButtonComponent
                            id={"exit_btn"}
                            suffixIcon={<ImageConfig.ExitIcon/>}
                            color={"exit-onboarding-navigation"}
                            onClick={() => {
                                // navigate(FACILITY_lIST);
                                CommonService.onExitFlowHandling(navigate, USER_MANAGEMENT);
                            }}
                        >
                            Exit User Onboarding
                        </ButtonComponent>
                    </div>
                </div>
            </div>
            <div className={'add-user-formik-wrapper mrg-top-20'}>

                <Formik
                    validationSchema={UserValidationSchema}
                    initialValues={initialValues}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            validateForm();
                        }, [validateForm, values]);
                        return (
                            <Form noValidate={true}>
                                {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                                <div className="add-component-content">
                                    <div className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                        <div className={'form-top-header-text'}>User Details</div>
                                        <>
                                            <div className={" mrg-top-10"}>
                                                <RadioButtonGroupComponent
                                                    options={[{
                                                        title: 'Facility',
                                                        code: 'facility'
                                                    }, {title: 'Agency', code: 'agency'}]}
                                                    valueExtractor={(option: any) => option.code}
                                                    onChange={(e) => {
                                                        setSelectedUserType(e);
                                                        setFilterData({
                                                            ...filterData,
                                                            selectedFacility: null,
                                                            selectedAgency: null
                                                        });
                                                    }}
                                                    value={selectedUserType}
                                                />
                                            </div>

                                            {selectedUserType === "facility" &&
                                                <div>
                                                    <LabelComponent title={"Select Facility*"}/>
                                                <SelectDropdownComponent noDataFoundText={"No Facility Found"}
                                                                         navigation={() => {
                                                                         }} buttonRequired={false}
                                                                         placeholder={"Select Facility"}
                                                                         searchMode={"clientSide"}
                                                                         value={filterData.selectedFacility || null}
                                                                         options={facilityList}
                                                                         valueExtractor={item => item.code}
                                                                         searchable={true}
                                                                         onUpdate={
                                                                             (value: any) => {
                                                                                 setFilterData({
                                                                                     ...filterData,
                                                                                     selectedFacility: value,
                                                                                 });
                                                                             }
                                                                         }/>
                                                </div>
                                            }

                                            {selectedUserType === "agency" &&
                                                <div>
                                                    <LabelComponent title={"Select Agency*"}/>
                                                    <SelectDropdownComponent noDataFoundText={"No Agency Found"}
                                                                             navigation={() => {
                                                                             }} buttonRequired={false}
                                                                             placeholder={"Select Agency"}
                                                                             searchMode={"clientSide"}
                                                                             value={filterData.selectedAgency || null}
                                                                             options={agencyList}
                                                                             valueExtractor={item => item.code}
                                                                             searchable={true}
                                                                             onUpdate={
                                                                                 (value: any) => {
                                                                                     setFilterData({
                                                                                         ...filterData,
                                                                                         selectedAgency: value,
                                                                                     });
                                                                                 }
                                                                             }/>
                                                </div>
                                            }


                                            <div className="ts-row">
                                                <div className="ts-col-6">
                                                    <Field name={'admin_first_name'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'First Name'}
                                                                    placeholder={'Ex : John'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-6">
                                                    <Field name={'admin_last_name'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Last Name'}
                                                                    placeholder={'Ex : Doe'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="ts-row">
                                                <div className="ts-col-6">
                                                    {/*<Field name={'admin_contact_number'}>*/}
                                                    {/*    {*/}
                                                    {/*        (field: FieldProps) => (*/}
                                                    {/*            <FormikInputComponent*/}
                                                    {/*                label={'Phone Number'}*/}
                                                    {/*                placeholder={'000-0000-000'}*/}
                                                    {/*                type={"number"}*/}
                                                    {/*                inputProps={{maxLength: 10}}*/}
                                                    {/*                required={true}*/}
                                                    {/*                formikField={field}*/}
                                                    {/*                fullWidth={true}*/}
                                                    {/*            />*/}
                                                    {/*        )*/}
                                                    {/*    }*/}
                                                    {/*</Field>*/}
                                                    <LabelComponent title={'Phone Number*'}/>
                                                    <Field name={'admin_contact_number'}>
                                                        {(field: FieldProps) => {
                                                            return <PhoneInputComponent field={field}
                                                                                        placeholder={"Enter Phone number"}/>;
                                                        }}
                                                    </Field>
                                                </div>
                                                <div className="ts-col-6">
                                                    <Field name={'admin_email'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Email Address'}
                                                                    placeholder={'example@xyz.com'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className={'mrg-top-10'}/>
                                            <div className={'form-top-header-text'}>Notification Preferences</div>
                                            <div className="ts-row">
                                                <div className="ts-col-4">
                                                    <Field name={'notification_preferences.isSmsRequired'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikCheckBoxComponent
                                                                    formikField={field}
                                                                    label={"SMS"}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field name={'notification_preferences.isEmailRequired'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikCheckBoxComponent
                                                                    formikField={field}
                                                                    label={"Email"}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field
                                                        name={'notification_preferences.isPush_notificationRequired'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikCheckBoxComponent
                                                                    formikField={field}
                                                                    label={"In App"}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                    <div className={'add-user-form-action-element'}>
                                        <div className={"mrg-left-20"}>
                                            <ButtonComponent
                                                className={'back-button'}
                                                suffixIcon={<ImageConfig.BackArrow/>}
                                                onClick={() => {
                                                    navigate(USER_MANAGEMENT);
                                                }}
                                            >
                                                Back
                                            </ButtonComponent>
                                        </div>
                                        <ButtonComponent
                                            disabled={isSubmitting || !isValid || (selectedUserType === "facility" && !filterData?.selectedFacility) || (selectedUserType === "agency" && !filterData?.selectedAgency)}
                                            isLoading={isSubmitting}
                                            type={'submit'}
                                            style={{color: "#202937"}}
                                        >
                                            Save
                                        </ButtonComponent>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );

};

export default AddUserDetailsComponent;
