import "./FilterPopupComponent.scss";
import React, {useCallback, useState} from "react";
import {Checkbox, IconButton, InputAdornment, ListItemText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import FormControl from "@mui/material/FormControl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import {ImageConfig} from "../../../constants";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import moment from "moment";
import {MenuProps, open_shift_status, shift_type_filter} from "../../../data";
import {CommonService} from "../../../shared/services";

interface ShiftFilterComponentProps {
    setFilterData?: any;
    filterData?: any;
}

const ShiftFilterComponent = (props: ShiftFilterComponentProps) => {
    const {filterData, setFilterData} = props;
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [dateRange, setDateRange] = useState<any[]>([null, null]);
    const [startDate, endDate] = dateRange;
    const {HcpTypesList} = useSelector((state: IRootReducerState) => state.meta);
    const {facilityList} = useSelector((state: IRootReducerState) => state.staticData);
    const [selectedFacility, setSelectedFacility] = useState<any>(null);
    const [selectedOpenShiftStatus, setSelectedOpenShiftStatus] = useState<any>([]);
    const [selectedHcpTypeData, setSelectedHcpTypeData] = React.useState<Array<string>>([]);
    const [selectedShiftData, setSelectedShiftData] = React.useState<Array<string>>([]);
    const [selectedFilterCount, setSelectedFilterCount] = React.useState<number>(0);
    const [isOpen, setIsOpen] = useState(false);
    const handleHcpTypeSelection = (event: SelectChangeEvent<typeof selectedHcpTypeData>) => {
        // @ts-ignore
        setSelectedHcpTypeData(event.target.value);
    };

    const togglePopup = useCallback(() => {
        setIsPopupOpen((prevIsOpen: any) => !prevIsOpen);
        setIsOpen(false);
    }, []);

    const handleShiftTypeSelection = (event: SelectChangeEvent<typeof selectedHcpTypeData>) => {
        // @ts-ignore
        setSelectedShiftData(event.target.value);
    };

    const handleFacilitySelect = (event: SelectChangeEvent<typeof selectedFacility>) => {
        setSelectedFacility(event.target.value);
    };
    const handleOpenShiftTypeSelect = (event: SelectChangeEvent<typeof selectedFacility>) => {
        setSelectedOpenShiftStatus(event.target.value);
    };
    const applyFilters = useCallback(() => {
        let updatedFilterData = {...filterData};
        let selectedFilter = 0;
        // Handle hcp_types filter
        if (selectedHcpTypeData?.length === 0) {
            delete updatedFilterData.hcp_types;
        } else {
            selectedFilter = selectedFilter + selectedHcpTypeData?.length;
            updatedFilterData.hcp_types = selectedHcpTypeData.map((item: any) => item?.code);
        }
        if (selectedOpenShiftStatus?.length === 0) {
            delete updatedFilterData.open_status_type;
        } else {
            selectedFilter = selectedFilter + selectedOpenShiftStatus?.length;
            updatedFilterData.open_status_type = selectedOpenShiftStatus.map((item: any) => item?.code);
        }
        //Handle Facility Filter
        if (!selectedFacility) {
            delete updatedFilterData.facility_id;
        } else {
            selectedFilter++;
            updatedFilterData.facility_id = selectedFacility?.code;
        }
        // if (!selectedOpenShiftStatus) {
        //     delete updatedFilterData.open_status_type;
        // } else {
        //     selectedFilter++;
        //     updatedFilterData.open_status_type = selectedOpenShiftStatus?.code;
        // }
        // Handle shift_types filter
        if (selectedShiftData?.length === 0) {
            delete updatedFilterData.shift_types;
        } else {
            selectedFilter = selectedFilter + selectedShiftData?.length;
            updatedFilterData.shift_types = selectedShiftData.map((item: any) => item?.title);
        }

        // Handle date range filter
        if (!dateRange[0] && !dateRange[1]) {
            delete updatedFilterData.start_date;
            delete updatedFilterData.end_date;
        } else {
            const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            const endDate = moment(dateRange[1] || dateRange[0]).format("YYYY-MM-DD");
            updatedFilterData.start_date = startDate;
            updatedFilterData.end_date = endDate;
            if (startDate) {
                selectedFilter++;
            }
            if (endDate) {
                selectedFilter++;
            }
        }

        setSelectedFilterCount(selectedFilter);
        if (selectedFilter > 0) {
            setFilterData(updatedFilterData);
            togglePopup();
        } else {
            CommonService._alert.showToast("Please select atleast one filter", "info");
        }
    }, [filterData, selectedHcpTypeData, selectedFacility, selectedOpenShiftStatus, selectedShiftData, dateRange, setFilterData, togglePopup]);


    return (
        <>
            <div className="filter-popup">
                <ButtonComponent color={'download-button'}
                                 className="filter-icon"
                                 prefixIcon={<ImageConfig.FilterPrefixIcon/>}
                                 suffixIcon={<>{selectedFilterCount > 0 && <>
                                     <span className="filter-count filter-display-holder">{selectedFilterCount}</span>
                                 </>}</>}
                                 onClick={togglePopup}>
                    Filters
                </ButtonComponent>
                {isPopupOpen && (
                    <div className="filter-popup-content">
                        <div>
                            <div className={'filter-header d-flex'} style={{justifyContent: "space-between"}}>
                                <div className={'d-flex'}
                                     style={{justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                                    <div className={'mrg-right-10'}>
                                        <ImageConfig.FilterPrefixIcon/>
                                    </div>
                                    <div>Filters</div>
                                </div>
                                <div className={'icon-button'} onClick={togglePopup}>
                                    <ImageConfig.FilterHeaderCrossIcon/>
                                </div>
                            </div>
                            <div className={'pdd-left-10 pdd-right-10'}>
                                <HorizontalLineComponent/>
                                <div className={"mrg-top-15 date-picker-margin"}>
                                    <DatePicker
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="Shift Date"
                                        className="custom-input"
                                        selectsRange={true}
                                        startDate={startDate && new Date(startDate)}
                                        endDate={endDate && new Date(endDate)}
                                        onInputClick={() => {
                                            setIsOpen(!isOpen);
                                        }}
                                        onClickOutside={() => {
                                            setIsOpen(false);
                                        }}
                                        open={isOpen}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        isClearable={true}
                                    />
                                </div>
                                {filterData?.status === 'open' &&
                                    <>
                                        {/*<FormControl sx={{m: 1, width: 300}}>*/}
                                        {/*    {!selectedOpenShiftStatus &&*/}
                                        {/*        <div className={'material-select-box-placeholder-custom '}>*/}
                                        {/*            Select Status*/}
                                        {/*        </div>}*/}
                                        {/*    <Select*/}
                                        {/*        labelId="demo-simple-select-label"*/}
                                        {/*        id="demo-simple-select"*/}
                                        {/*        value={selectedOpenShiftStatus}*/}
                                        {/*        onChange={handleOpenShiftTypeSelect}*/}
                                        {/*        endAdornment={*/}
                                        {/*            <>{*/}
                                        {/*                selectedOpenShiftStatus && (*/}
                                        {/*                    <InputAdornment className={'mrg-right-10'} position="end">*/}
                                        {/*                        <IconButton*/}
                                        {/*                            aria-label="Clear selection"*/}
                                        {/*                            onClick={() => {*/}
                                        {/*                                setSelectedOpenShiftStatus(null);*/}
                                        {/*                            }}*/}
                                        {/*                            edge="end"*/}
                                        {/*                        >*/}
                                        {/*                            <ClearIcon/>*/}
                                        {/*                        </IconButton>*/}
                                        {/*                    </InputAdornment>*/}
                                        {/*                )}*/}
                                        {/*            </>*/}
                                        {/*        }*/}
                                        {/*    >*/}
                                        {/*        {open_shift_status.map((item: any) => (*/}
                                        {/*            <MenuItem style={{maxWidth: '100%'}} key={item.code} value={item}>*/}
                                        {/*                <ListItemText primary={item.title}/>*/}
                                        {/*            </MenuItem>*/}
                                        {/*        ))}*/}
                                        {/*    </Select>*/}
                                        {/*</FormControl>*/}
                                        <FormControl sx={{m: 1, width: 300}}>
                                            {selectedOpenShiftStatus?.length === 0 &&
                                                <div className={'material-select-box-placeholder-custom '}>
                                                    Select Status Type
                                                </div>}
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={selectedOpenShiftStatus}
                                                onChange={handleOpenShiftTypeSelect}
                                                //  input={<OutlinedInput label="Select HCP Type"/>}
                                                renderValue={(selected) => selected?.map((item: any) => item.title).join(", ")}
                                                MenuProps={MenuProps}
                                            >
                                                {open_shift_status.map((item: any) => (
                                                    <MenuItem key={item.code} value={item}>
                                                        <Checkbox style={{
                                                            color: "#202937"
                                                        }}
                                                                  checked={selectedOpenShiftStatus?.some((selectedItem: any) => selectedItem.code === item.code)}/>
                                                        <ListItemText primary={item.title}/>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </>
                                }
                                <FormControl sx={{m: 1, width: 300}}>
                                    {!selectedFacility &&
                                        <div className={'material-select-box-placeholder-custom '}>
                                            Select Facility
                                        </div>}
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedFacility}
                                        onChange={handleFacilitySelect}
                                        endAdornment={
                                            <>{
                                                selectedFacility && (
                                                    <InputAdornment className={'mrg-right-10'} position="end">
                                                        <IconButton
                                                            aria-label="Clear selection"
                                                            onClick={() => {
                                                                setSelectedFacility(null);
                                                            }}
                                                            edge="end"
                                                        >
                                                            <ClearIcon/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )}
                                            </>
                                        }
                                    >
                                        {facilityList.map((item: any) => (
                                            <MenuItem style={{maxWidth: '100%'}} key={item.code} value={item}>
                                                <ListItemText primary={item.title}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{m: 1, width: 300}}>
                                    {selectedHcpTypeData?.length === 0 &&
                                        <div className={'material-select-box-placeholder-custom '}>
                                            Select Staff Type
                                        </div>}
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedHcpTypeData}
                                        onChange={handleHcpTypeSelection}
                                        //  input={<OutlinedInput label="Select HCP Type"/>}
                                        renderValue={(selected) => selected.map((item: any) => item.title).join(", ")}
                                        MenuProps={MenuProps}
                                    >
                                        {HcpTypesList.map((item: any) => (
                                            <MenuItem key={item.code} value={item}>
                                                <Checkbox style={{
                                                    color: "#202937"
                                                }}
                                                          checked={selectedHcpTypeData.some((selectedItem: any) => selectedItem.code === item.code)}/>
                                                <ListItemText primary={item.title}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{m: 1, width: 300}}>
                                    {selectedShiftData?.length === 0 &&
                                        <div className={'material-select-box-placeholder-custom '}>
                                            Select Shift Type
                                        </div>}
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedShiftData}
                                        onChange={handleShiftTypeSelection}
                                        renderValue={(selected) => selected.map((item: any) => item.code).join(", ")}
                                        MenuProps={MenuProps}
                                    >
                                        {shift_type_filter.map((item: any) => (
                                            <MenuItem key={item.code} value={item}>
                                                <Checkbox style={{
                                                    color: "#202937"
                                                }}
                                                          checked={selectedShiftData.some((selectedItem: any) => selectedItem.code === item.code)}/>
                                                <ListItemText primary={item.title}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <HorizontalLineComponent className={'mrg-top-15'}/>
                                <div className={'d-flex justify-content-space-between align-items-center'}>
                                    <ButtonComponent variant={"link"}
                                                     disabled={!dateRange[0] && selectedHcpTypeData?.length === 0 && !selectedFacility && !selectedOpenShiftStatus && selectedShiftData?.length === 0}
                                                     className={'clear-all-filter-text icon-button '}
                                                     onClick={() => {
                                                         setDateRange([null, null]);
                                                         setSelectedHcpTypeData([]);
                                                         setSelectedShiftData([]);
                                                         setSelectedFacility(null);
                                                         setSelectedOpenShiftStatus([]);
                                                         setSelectedFilterCount(0);
                                                         delete filterData.shift_types;
                                                         delete filterData.hcp_types;
                                                         delete filterData.start_date;
                                                         delete filterData.end_date;
                                                         delete filterData.hcp_types;
                                                         delete filterData.facility_id;
                                                         delete filterData.open_status_type;
                                                         setFilterData({
                                                             ...filterData
                                                         });
                                                     }}>
                                        Clear All
                                    </ButtonComponent>
                                    <ButtonComponent className="filter-icon mrg-right-10 mrg-top-10 mrg-bottom-10"
                                                     style={{backgroundColor: "#202937", color: "#fff"}}
                                                     onClick={applyFilters}
                                    >
                                        Apply Filters
                                    </ButtonComponent>
                                </div>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ShiftFilterComponent;
