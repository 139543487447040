// import "./EditHcpBasicDetails.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import {ImageConfig, Misc} from "../../../../constants";
import FormikInputComponent from "../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import LabelComponent from "../../../../shared/components/label/LabelComponent";
import PhoneInputComponent from "../../../../shared/components/phoneInput/PhoneInputComponent";
import FormControl from "@mui/material/FormControl";
import {ListItemText, MenuItem, Select} from "@mui/material";
import {americanTimeZone} from "../../../../data";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../../store/reducers";
import FormikTextAreaComponent
    from "../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
// import FormikSelectDropdownComponent
//     from "../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
// import FormDebuggerComponent from "../../../shared/components/form-debugger/FormDebuggerComponent";

interface EditHcpDetailsComponentProps {
    hcpDetails: any;
    closeEditDrawer: any;
    getFacilityDetails: any;
}

const EditHcpDetailsValidationSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(3, 'First name should be at least 3 characters')
            .max(50, 'First Name should not exceed 50 characters')
            .required('First Name is required'),
        last_name: Yup.string()
            .min(3, 'Last Name should be at least 3 characters')
            .max(50, 'Last Name should not exceed 50 characters')
            .required('Last Name is required'),
        contact_number: Yup.string().min(12, "min 10 digits").required("Phone number is required"),
        email: Yup.string().email('Invalid email address').required('Email Address is required'),
        about: Yup.string().max(1000, "About should be maximum 1000 characters"),
        address: Yup.object({
            street: Yup.string()
                .min(1, 'Address Line should be at least 1 character')
                .required('Address Line  is required'),
            zip_code: Yup.string().required('ZIP Code is required').min(5, 'ZIP Code should be at least 5 characters').max(6, 'ZIP Code should be maximum 6 characters'),
            state: Yup.string()
                .min(1, 'State should be at least 1 character')
                .max(30, 'State should not exceed 30 characters')
                .required('State is required'),
            city: Yup.string()
                .min(1, 'City should be at least 1 character')
                .max(30, 'City should not exceed 30 characters')
                .required('City is required'),
            country: Yup.string()
                .min(1, 'Country should be at least 1 character')
                .max(30, 'Country should not exceed 30 characters')
                .required('Country is required'),
            region: Yup.string()
                .min(1, 'Region should be at least 1 character')
                .max(30, 'Region should not exceed 30 characters')
                .required('Region is required'),
        }),
    }
);

interface EditHcpDetailsFormInitialValuesFormSchema {
    first_name: string;
    last_name: string;
    contact_number: string;
    email: string;
    hcp_type_id: string;
    about: string;
    location_url: string,
    timezone: string,
    address: {
        region: any,
        zip_code: any,
        street: any,
        state: string,
        city: string,
        country: string,

    };
}

const EditFacilityFormInitialValues: EditHcpDetailsFormInitialValuesFormSchema = {
    first_name: "",
    last_name: "",
    contact_number: "",
    email: "",
    hcp_type_id: "",
    about: "",
    location_url: "",
    timezone: "",
    address: {
        region: "",
        zip_code: "",
        street: "",
        state: "",
        city: "",
        country: "",
    }
};


const EditInternalHcpBasicDetailsComponent = (props: EditHcpDetailsComponentProps) => {
    const [selectedTimeZone, setSelectedTimeZone] = useState<any>(null);
    const [selectedRegion, setSelectedRegion] = useState<any>(null);
    const [selectedHcpType, setSelectedHcpType] = useState<any>(null);
    const {regionList} = useSelector((state: IRootReducerState) => state.staticData);
    const {hcpDetails, closeEditDrawer, getFacilityDetails} = props;
    const [initialValues, setInitialValues] = useState<EditHcpDetailsFormInitialValuesFormSchema>(EditFacilityFormInitialValues);

    const {
        HcpTypesList,
    } = useSelector((state: IRootReducerState) => state.meta);

    useEffect(() => {
        const basicDetails = {
            first_name: hcpDetails?.first_name,
            last_name: hcpDetails?.last_name,
            contact_number: hcpDetails?.contact_number,
            email: hcpDetails?.email,
            hcp_type_id: hcpDetails?.hcp_type_id,
            about: hcpDetails?.about,
            location_url: hcpDetails?.location_url,
            timezone: hcpDetails?.timezone,
            address: {
                region: hcpDetails?.address?.region,
                zip_code: hcpDetails?.address?.zip_code,
                street: hcpDetails?.address?.street,
                state: hcpDetails?.address?.state,
                city: hcpDetails?.address?.city,
                country: hcpDetails?.address?.country,
            }
        };
        setSelectedHcpType(hcpDetails?.hcp_type_id);
        setInitialValues(basicDetails);
        setSelectedTimeZone(hcpDetails?.timezone);
        setSelectedRegion(hcpDetails?.address?.region);
    }, [hcpDetails]);

    const onHCPDetailsSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
        };
        let apiCall: any = {};
        apiCall = CommonService._hcp.EditHCPDetailsAPICall(hcpDetails._id, payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            closeEditDrawer();
            getFacilityDetails();
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [closeEditDrawer, getFacilityDetails, hcpDetails]);

    return (
        <>
            <div className={'edit-facility-component'}>
                <div className={'edit-facility-header'}>
                    <div className={'form-header-text'}>Edit Internal Staff Details</div>
                    <div className={'icon-button'} onClick={closeEditDrawer}><ImageConfig.CrossIcon/></div>
                </div>

                <div className={'edit-facility-form-wrapper'}>
                    <Formik
                        validationSchema={EditHcpDetailsValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onHCPDetailsSubmit}
                    >
                        {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form scroll-content form-holder" noValidate={true}>
                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={true}/>*/}
                                    <div className={'add-form-holder'}>
                                        <div className={'add-form-input-container'}>
                                            <div className="edit-component-content">
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                        <Field name={'first_name'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Internal Staff First Name'}
                                                                        placeholder={'Enter Internal Staff First Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <Field name={'last_name'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Internal Staff Last Name'}
                                                                        placeholder={'Enter Internal Staff Last Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'} onClick={() => {
                                                        console.log(initialValues);
                                                    }}>
                                                        {/*<Field name={'contact_number'}>*/}
                                                        {/*    {*/}
                                                        {/*        (field: FieldProps) => (*/}
                                                        {/*            <FormikInputComponent*/}
                                                        {/*                label={'Phone Number'}*/}
                                                        {/*                placeholder={'000-000-0000'}*/}
                                                        {/*                type={"text"}*/}
                                                        {/*                required={true}*/}
                                                        {/*                formikField={field}*/}
                                                        {/*                fullWidth={true}*/}
                                                        {/*            />*/}
                                                        {/*        )*/}
                                                        {/*    }*/}
                                                        {/*</Field>*/}
                                                        <LabelComponent title={'Phone Number*'}/>
                                                        <Field name={'contact_number'}>
                                                            {(field: FieldProps) => {
                                                                return <PhoneInputComponent field={field}
                                                                                            placeholder={"Enter Phone number"}/>;
                                                            }}
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <Field name={'email'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Email Address'}
                                                                        placeholder={'example@xyz.com'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'fullWidth mrg-bottom-15'}>
                                                    <LabelComponent title={'Staff Type*'}/>
                                                    <Field name={'hcp_type_id'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormControl sx={{m: 1, width: 500}}>
                                                                    {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        inputProps={{
                                                                            name: 'hcp_type_id',
                                                                            id: 'uncontrolled-native',
                                                                        }}
                                                                        // input={<BootstrapInput/>}
                                                                        value={selectedHcpType}
                                                                        onChange={(event: any) => {
                                                                            setSelectedHcpType(event.target.value);
                                                                            setFieldValue('hcp_type_id', event.target.value);
                                                                        }}
                                                                        // label="Select Facility"
                                                                    >
                                                                        {HcpTypesList.map((item: any) => (
                                                                            <MenuItem key={item.code}
                                                                                      value={item?.code}>
                                                                                <ListItemText
                                                                                    primary={item.title}/>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <Field name={'about'}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikTextAreaComponent
                                                                label={'About the Internal Staff'}
                                                                //  placeholder={'Enter Facility Name'}
                                                                rows={5}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                            </div>
                                            <div className="edit-component-content">
                                                <Field name={'address.street'}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikInputComponent
                                                                label={'Address Line'}
                                                                placeholder={'Enter Address Line here'}
                                                                type={"text"}
                                                                required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                        <Field name={'address.country'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Country'}
                                                                        placeholder={'Enter Country Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <Field name={'address.state'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'State'}
                                                                        placeholder={'Enter State Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                        <Field name={'address.zip_code'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'ZIP Code'}
                                                                        placeholder={'Enter ZIP Code'}
                                                                        type={"number"}
                                                                        maxLength={6}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <Field name={'address.city'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'City'}
                                                                        placeholder={'Enter City Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                        <LabelComponent title={'Timezone*'}/>
                                                        <Field name={'timezone'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormControl sx={{m: 1, width: 236.5}}>
                                                                        {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            inputProps={{
                                                                                name: 'timezone',
                                                                                id: 'uncontrolled-native',
                                                                            }}
                                                                            // input={<BootstrapInput/>}
                                                                            value={selectedTimeZone}
                                                                            onChange={(event: any) => {
                                                                                setSelectedTimeZone(event.target.value);
                                                                                setFieldValue('timezone', event.target.value);
                                                                            }}
                                                                            // label="Select Facility"
                                                                        >
                                                                            {americanTimeZone.map((item: any) => (
                                                                                <MenuItem key={item.code}
                                                                                          value={item?.code}>
                                                                                    <ListItemText
                                                                                        primary={item.title}/>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        {/*<Field name={'address.region'}>*/}
                                                        {/*    {*/}
                                                        {/*        (field: FieldProps) => (*/}
                                                        {/*            <FormikInputComponent*/}
                                                        {/*                label={'Region'}*/}
                                                        {/*                placeholder={'Enter Region'}*/}
                                                        {/*                type={"text"}*/}
                                                        {/*                required={true}*/}
                                                        {/*                formikField={field}*/}
                                                        {/*                fullWidth={true}*/}
                                                        {/*            />*/}
                                                        {/*        )*/}
                                                        {/*    }*/}
                                                        {/*</Field>*/}
                                                        <LabelComponent title={'Region*'}/>
                                                        <Field name={'address.region'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormControl sx={{m: 1, width: 236.5}}>
                                                                        {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            inputProps={{
                                                                                name: 'address.region',
                                                                                id: 'uncontrolled-native',
                                                                            }}
                                                                            // input={<BootstrapInput/>}
                                                                            value={selectedRegion}
                                                                            onChange={(event: any) => {
                                                                                setSelectedRegion(event.target.value);
                                                                                setFieldValue('address.region', event.target.value);
                                                                            }}
                                                                            // label="Select Facility"
                                                                        >
                                                                            {regionList.map((item: any) => (
                                                                                <MenuItem key={item.code}
                                                                                          value={item?.code}>
                                                                                    <ListItemText
                                                                                        primary={item.title}/>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="facility-edit-save-button d-flex mrg-top-10">
                                        <ButtonComponent
                                            disabled={isSubmitting}
                                            isLoading={isSubmitting}
                                            color={'secondary'}
                                            type={'submit'}
                                        >
                                            Save Details
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};
export default EditInternalHcpBasicDetailsComponent;

