import {Field, FieldArray, FieldProps, Form, Formik, FormikHelpers} from "formik";
import React, {useCallback, useEffect, useState} from "react";
import LabelComponent from "../../../../../shared/components/label/LabelComponent";
import {Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {
    contactTypeList, contractShiftType, shiftDurationList, weekDaysList
} from "../../../../../data";
import FormikInputComponent from "../../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import FormikCheckBoxComponent
    from "../../../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";
import FormControl from "@mui/material/FormControl";
import ButtonComponent from "../../../../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc} from "../../../../../constants";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../../../../store/reducers";
import {useNavigate} from "react-router-dom";
import "../home-health -hospice/HomeHealthHospiceTypeShiftRequirement.scss";
import {CommonService} from "../../../../../shared/services";
import {IAPIResponseType} from "../../../../../shared/models/api.model";
import FormikTextAreaComponent
    from "../../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
import FormikDatePickerComponent
    from "../../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import FormikSelectDropdownComponent
    from "../../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import {getStaffSpecialityList} from "../../../../../store/actions/meta.action";
import {getFacilityAgencyListLite} from "../../../../../store/actions/static-data.action";


interface AddShiftDetailsProps {
    setShiftTypeHeader?: any;
    setIsFloatValueSelected?: any;
    setIsInternalValueSelected?: any;
    setCreatedRequirementList?: any;
    setIsInternalHcp?: any;
    getFacilityDetails?: any;
    handleInternalHCPListSubmit?: any;
    handleFloatHcpListSubmit?: any;
    setSelectedFacilityId?: any;
    selectedFacilityId?: any;
    selectedHcpType?: any;
    setSelectedHcpType?: any;
    sixMonthsFromNow?: any;
    isShiftCreationForSingleDate?: any;
    selectedDateFromCalender?: any;
    onCalender?: any;
    schedulingListFilterState?: any;
    setOpenDialog?: any;
    setSchedulingListFilterState?: any
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface PerDiem {
    type: string;
    rate: number;
    week_rate: number;
}


const shiftDetailsValidationSchema = Yup.object().shape({
    facility_id: Yup.string().required('Facility Selection is required'),
    shift_type: Yup.string().required('Shift Type is required'),
    hcp_type_id: Yup.string().required('Staff Type is required'),
    day_per_week: Yup.string().required('Day per week is required'),
    shift_duration: Yup.string().required('Shift Duration is required'),
    job_type: Yup.string().required('Type is required'),
    required_hcp_count: Yup.string().required('Open Positions is required'),
    no_of_weeks: Yup.number().required('No of weeks is required'),
    start_date: Yup.date().typeError('Invalid date').required("Start Date is required"),
    end_date: Yup.date().typeError('Invalid date')
        .test('is-greater-or-equal', 'End Date cannot be less than Start Date', function (end_date) {
            const {start_date} = this.parent; // Access the value of start_date
            // Perform the comparison
            if (start_date && end_date) {
                return new Date(end_date) >= new Date(start_date);
            }
            // If either value is missing, assume validation passes
            return true;
        }).required("End Date is required"),
    additional_confirmations: Yup.string(),
    cancel_policy: Yup.string().required("Cancel Policy is required"),
    staff_requirement: Yup.string(),
    job_details: Yup.string(),
    job_benefits: Yup.string(),
});

interface formInitialValuesFormSchema {
    //required fields for contract travel form
    facility_id: string;
    shift_type: string;
    day_per_week: string;
    shift_duration: string;
    type: string;
    required_hcp_count: any;
    shift_rate: string;
    start_date: string;
    end_date: string;
    start_time: string;
    weekly_earning: string;
    hcp_type_id: string;
    cancel_policy: string;
    staff_requirement: string;
    job_benefits: string;
    job_details: string;
    //not required fields for contract travel form
    send_to: {
        is_float: any;
        is_internal: any;
        specific_agencies: any;
    };
    travel_per_diems: PerDiem[];
}


const ContractTravelComponent = (props: AddShiftDetailsProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        setShiftTypeHeader,
        setIsInternalValueSelected,
        setIsFloatValueSelected,
        setIsInternalHcp,
        setCreatedRequirementList,
        selectedFacilityId,
        handleInternalHCPListSubmit,
        handleFloatHcpListSubmit,
        getFacilityDetails,
        selectedDateFromCalender,
        onCalender,
        schedulingListFilterState,
        setOpenDialog,
        setSchedulingListFilterState,
    } = props;
    const {
        HcpTypesList,
    } = useSelector((state: IRootReducerState) => state.meta);
    const {facilityList, facilityAgencyList} = useSelector((state: IRootReducerState) => state.staticData);
    const [selectedAgency, setSelectedAgency] = React.useState<Array<string>>([]);
    const [isAgencySelected, setIsAgencySelected] = useState(false);
    const {staffSpecialityList} = useSelector((state: IRootReducerState) => state.meta);

    const formInitialValues: formInitialValuesFormSchema = {
        facility_id: "",
        shift_type: "",
        day_per_week: "",
        shift_duration: "",
        type: "",
        required_hcp_count: "",
        shift_rate: "",
        start_date: selectedDateFromCalender ? selectedDateFromCalender : null,
        end_date: "",
        start_time: "",
        cancel_policy: "",
        staff_requirement: "",
        job_details: "",
        job_benefits: "",

        weekly_earning: "",
        hcp_type_id: "",
        send_to: {
            is_float: false,
            is_internal: false,
            specific_agencies: false,
        },
        travel_per_diems: [
            {
                type: 'Shift Rate',
                rate: 0,
                week_rate: 0,
            },
            {
                type: 'Housing',
                rate: 0,
                week_rate: 0,
            },
            {
                type: 'Meals',
                rate: 0,
                week_rate: 0,
            },
        ],
    };
    const [initialValues] = useState<formInitialValuesFormSchema>(formInitialValues);


    const handleAgencyTypeSelection = (event: SelectChangeEvent<typeof selectedAgency>) => {
        // @ts-ignore
        setSelectedAgency(event.target.value);
    };


    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        let payload: any = {
            ...values
        }
        payload.start_time = CommonService.convertToAdjustedMinutes(values.start_time);
        payload.send_to.specific_agencies = values.send_to.specific_agencies
        payload.send_to.is_internal = values.send_to.is_internal
        payload.send_to.is_float = values.send_to.is_float
        payload.allowed_agency_ids = selectedAgency?.map((item: any) => item?.code);
        payload.requirementType = "Contract Travel";
        // payload.weekly_earning = CommonService.calculateSum(values?.travel_per_diems) * Number(values?.day_per_week);
        payload.weekly_earning = values.weekly_earning;
        payload.start_date = CommonService.formatDate(values.start_date);
        payload.end_date = CommonService.formatDate(values.end_date);
        payload.shift_rate = values?.travel_per_diems[0]?.rate;
        //  payload.total_travel_rate = (Number(values.day_per_week) * Number(values.travel_per_diem_rate)).toFixed(2);

        let apiCall: any = {};
        apiCall = CommonService._shift_management.addNewContractShiftApiCall(payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            if (values?.send_to?.is_internal || values?.send_to?.is_float) {
                setIsInternalHcp(true);
                setShiftTypeHeader("Create Approved Shift");
                setCreatedRequirementList(response?.data);
                if (values?.send_to?.is_internal && !values?.send_to?.is_float) {
                    setIsInternalValueSelected(true);
                } else if (values?.send_to?.is_float && !values?.send_to?.is_internal) {
                    setIsFloatValueSelected(true);
                } else if (values?.send_to?.is_float && values?.send_to?.is_internal) {
                    setIsInternalValueSelected(true);
                    setIsFloatValueSelected(true);
                }
            } else {
                if (onCalender) {
                    setOpenDialog(false)
                    setSchedulingListFilterState({...schedulingListFilterState});
                    // navigate(CommonService._routeConfig.SchedulerRoute());
                } else {
                    navigate(CommonService._routeConfig.ShiftManagementRoute());
                }
            }
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                CommonService._alert.showToast(error?.error, "error");
            }).finally(() => {
            setSubmitting(false);
        });
        // eslint-disable-next-line
    }, [selectedAgency, navigate, selectedDateFromCalender]);

    useEffect(() => {
        if (selectedFacilityId) {
            handleInternalHCPListSubmit();
            handleFloatHcpListSubmit();
        }
    }, [handleFloatHcpListSubmit, handleInternalHCPListSubmit, selectedFacilityId]);

    useEffect(() => {
        getFacilityDetails();
    }, [getFacilityDetails]);


    return (
        <>
            <Formik
                validationSchema={shiftDetailsValidationSchema}
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                onSubmit={onSubmit}
            >
                {({
                      touched,
                      values,
                      errors,
                      validateForm,
                      isValid,
                      setFieldValue,
                      isSubmitting,
                      handleBlur
                  }) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form className="t-form scroll-content" noValidate={true}>
                            {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}

                            <div className="add-component-content">
                                <>
                                    <div className={'body-min-height'}>
                                        <div className={'requirement-form-body-wrapper'}>
                                            <div className={'form-header-text mrg-bottom-10'}>
                                                Opportunity Details
                                            </div>
                                            <div className={'ts-row'}>
                                                <div className="ts-col-4">
                                                    <Field name={'facility_id'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={facilityList}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    label={'Facility*'}
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    searchable={true}
                                                                    fullWidth={true}
                                                                    onUpdate={(value) => {
                                                                        //setSelectedFacilityId(code);
                                                                        dispatch(getFacilityAgencyListLite(value))
                                                                        console.log(facilityList, HcpTypesList)
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field name={'hcp_type_id'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={HcpTypesList}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    label={'Staff Type*'}
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    searchable={true}
                                                                    fullWidth={true}
                                                                    onUpdate={(val: any) => {
                                                                        if (val) {
                                                                            dispatch(getStaffSpecialityList(val))
                                                                        }
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>


                                            </div>

                                            <div className={'ts-row'}>
                                                <div className="ts-col-4">
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field name={'hcp_speciality_id'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={staffSpecialityList}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    label={'Staff Speciality'}
                                                                    disabled={!values?.hcp_type_id}
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    searchable={true}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field name={'required_hcp_count'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'# of positions*'}
                                                                    placeholder={'Enter value'}
                                                                    formikField={field}
                                                                    type={"number"}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>

                                            </div>


                                            <div className={'shift-requirement-divider-line mrg-bottom-10'}/>
                                            <div className={'form-header-text mrg-bottom-10'}>
                                                Job Details
                                            </div>
                                            <div className={"ts-row"}>
                                                <div className="ts-col-4">
                                                    <Field name={'job_type'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={contractShiftType}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    label={'Job Type*'}
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field name={'day_per_week'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={weekDaysList}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    label={'# of Days per Week*'}
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field name={'shift_duration'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={shiftDurationList}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    label={'Hours per Shift*'}
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>


                                            <div className={"ts-row"}>
                                                <div className="ts-col-4">
                                                    <Field name={'shift_type'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={contactTypeList}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    label={'Shift Type*'}
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>

                                                <div className="ts-col-4">
                                                    <LabelComponent title={"Start Date*"}/>
                                                    <Field name={'start_date'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikDatePickerComponent
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                    required={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-4">
                                                    <LabelComponent title={"End Date*"}/>
                                                    <Field name={'end_date'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikDatePickerComponent
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                {/*<div className="ts-col-3">*/}
                                                {/*    <LabelComponent title={'Start Time*'}/>*/}
                                                {/*    <Field name={'start_time'}>*/}
                                                {/*        {*/}
                                                {/*            (field: FieldProps) => (*/}
                                                {/*                <FormikTimePickerComponent*/}
                                                {/*                    placeholder={'Start Time'}*/}
                                                {/*                    required={true}*/}
                                                {/*                    formikField={field}*/}
                                                {/*                    fullWidth={true}*/}
                                                {/*                />*/}
                                                {/*            )*/}
                                                {/*        }*/}
                                                {/*    </Field>*/}
                                                {/*</div>*/}
                                            </div>


                                            <div className={"ts-row"}>
                                                <div className="ts-col-4">
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field name={'no_of_weeks'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'# of Weeks*'}
                                                                    placeholder={'Enter value'}
                                                                    formikField={field}
                                                                    type={"number"}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>


                                            <div className={'shift-requirement-divider-line mrg-bottom-10'}/>

                                            <div className={'form-header-text mrg-bottom-10'}>
                                                Rates
                                            </div>
                                            <FieldArray name="travel_per_diems">
                                                {({push, remove}) => (
                                                    <div>
                                                        {values?.travel_per_diems.map((perDiem, index) => (
                                                            <div className="ts-row">
                                                                <div className="ts-col-4">
                                                                    <Field
                                                                        name={`travel_per_diems.${index}.type`}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    //  label={'Travel Per Diems Type*'}
                                                                                    placeholder={'Enter value'}
                                                                                    disabled={index < 3}
                                                                                    type={"text"}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                                {index === 0 &&
                                                                    <div className="ts-col-4">
                                                                        <Field
                                                                            name={`travel_per_diems.${index}.rate`}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikInputComponent
                                                                                        //  label={'Travel Per Diem Rate*'}
                                                                                        placeholder={"$/hr"}
                                                                                        suffix={<>$</>}
                                                                                        type={"number"}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>}
                                                                {index > 0 &&
                                                                    <div className="ts-col-4">
                                                                        <Field
                                                                            name={`travel_per_diems.${index}.rate`}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikInputComponent
                                                                                        //  label={'Travel Per Diem Rate*'}
                                                                                        placeholder={"$/day"}
                                                                                        type={"number"}
                                                                                        suffix={<>$</>}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>}
                                                                <div
                                                                    className={`ts-col-${index > 2 ? 3 : 4}`}>
                                                                    <Field name={`travel_per_diems.${index}.week_rate`}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    //   label={'Weekly Earnings*'}
                                                                                    placeholder={'$/week'}
                                                                                    type={"number"}
                                                                                    suffix={<>$</>}
                                                                                    //  disabled={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                                {(values?.travel_per_diems?.length > 3 && index > 2) &&
                                                                    <div
                                                                        className="mrg-7">
                                                                        <div onClick={() => {
                                                                            remove(index)
                                                                        }} className={'icon-button'}>
                                                                            <ImageConfig.CrossIcon/>
                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                        ))}
                                                        <div className={'mrg-bottom-10'}>
                                                            <div className={'d-flex justify-content-space-between'}>
                                                                <div
                                                                    className={'d-flex ts-justify-content-center align-items-center'}>
                                                                    <ButtonComponent onClick={() =>
                                                                        push({type: '', rate: 0, total: 0})
                                                                    } color={'primary'} prefixIcon={
                                                                        <ImageConfig.AddOutlinedIcon/>}>
                                                                        Add More
                                                                    </ButtonComponent>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}
                                            </FieldArray>

                                            <div className={'shift-requirement-divider-line mrg-bottom-10'}/>

                                            <div className="ts-row">
                                                <div className="ts-col-12">
                                                    <Field name={'cancel_policy'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikTextAreaComponent
                                                                    label={"Cancel Policy*"}
                                                                    placeholder={'Enter Cancel Policy'}
                                                                    rows={6}
                                                                    //required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="ts-row">
                                                <div className="ts-col-12">
                                                    <Field name={'staff_requirement'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikTextAreaComponent
                                                                    label={"Staff Requirements"}
                                                                    placeholder={'• References, Skills, certifications, or required years of experience'}
                                                                    rows={6}
                                                                    //required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="ts-row">
                                                <div className="ts-col-12">
                                                    <Field name={'job_details'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikTextAreaComponent
                                                                    label={"Job Details"}
                                                                    placeholder={'• Enter any relevant details about the job'}
                                                                    rows={6}
                                                                    //required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="ts-row">
                                                <div className="ts-col-12">
                                                    <Field name={'job_benefits'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikTextAreaComponent
                                                                    label={"Job Benefits"}
                                                                    placeholder={'• Allied travel assignments are typically for 13 weeks and offer ​ generous packages that include: \n' +
                                                                        '• Competitive pay rates \n' +
                                                                        '• Medical, Dental, Vision \n' +
                                                                        '• 401(k) and Flex Spending \n' +
                                                                        '• Life Insurance \n' +
                                                                        '• Accident and Short-term Disability Coverage \n' +
                                                                        '• Free Continuing Education \n' +
                                                                        '• Competitive Housing Deal \n' +
                                                                        '• Refer a friend and earn extra cash!'}
                                                                    rows={9}
                                                                    //required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>

                                            <div className={'shift-requirement-divider-line mrg-bottom-10'}/>

                                            <LabelComponent title={'Send Shift Req to'} isBold={true}/>
                                            <div className="ts-row mrg-top-10">
                                                <div className="ts-col-4">
                                                    <Field name={'send_to.is_internal'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikCheckBoxComponent
                                                                    formikField={field}
                                                                    label={"Internal HCP"}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field name={'send_to.is_float'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikCheckBoxComponent
                                                                    formikField={field}
                                                                    label={"Floating HCP"}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field name={'send_to.specific_agencies'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikCheckBoxComponent
                                                                    formikField={field}
                                                                    label={"Agency"}
                                                                    onChange={(e) => {
                                                                        setIsAgencySelected(e);
                                                                        if (e) {
                                                                            const allAgencyCodes = facilityAgencyList.map((item: any) => item);
                                                                            setSelectedAgency(allAgencyCodes);
                                                                        } else {
                                                                            setSelectedAgency([]);
                                                                        }
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            {isAgencySelected &&
                                                <FormControl sx={{m: 1, minWidth: '818px', maxWidth: "818px",}}>
                                                    {/*<InputLabel id="demo-multiple-checkbox-label">*/}
                                                    {/*    {" "}*/}
                                                    {/*    Select Agency*/}
                                                    {/*</InputLabel>*/}
                                                    {selectedAgency?.length === 0 &&
                                                        <div className={'material-select-box-placeholder-custom'}>
                                                            Select Agency
                                                        </div>}
                                                    <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        value={selectedAgency}
                                                        onChange={handleAgencyTypeSelection}
                                                        // input={<OutlinedInput label="Select Agency"/>}
                                                        renderValue={(selected) => selected.map((item: any) => item?.title).join(", ")}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {facilityAgencyList.map((item: any) => (
                                                            <MenuItem key={item.code} value={item}>
                                                                <Checkbox style={{
                                                                    color: "#202937"
                                                                }}
                                                                          checked={selectedAgency.some((selectedItem: any) => selectedItem.code === item.code)}/>
                                                                <ListItemText primary={item.title}/>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>}
                                        </div>
                                    </div>
                                </>
                                <div className={"ts-row mrg-top-15"} style={{alignSelf: "center"}}>
                                    <ButtonComponent
                                        disabled={isSubmitting || !isValid}
                                        isLoading={isSubmitting}
                                        type={'submit'}
                                        style={{backgroundColor: "#192938", color: "white"}}
                                        suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                    >
                                        Create Shift
                                    </ButtonComponent>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default ContractTravelComponent;
