import ENV from "./ENV";

interface IAPIConfig {
    [k: string]: {
        URL: string | Function | any,
        METHOD: "get" | "post" | "delete" | "put"
    };
}

const APIConfig: IAPIConfig = {
    //meta start
    GENDER_LIST: {
        URL: ENV.API_URL + "/gender",
        METHOD: "get"
    },
    LANGUAGE_LIST: {
        URL: ENV.API_URL + "/language",
        METHOD: "get"
    },
    // USER_ROLE_LIST: {
    //     URL: ENV.API_URL + "/userRole",
    //     METHOD: "get"
    // },

    TOGGLE_FACILITY: {
        URL: (facilityId: string) => ENV.API_URL + '/facility/' + facilityId + "/toggle",
        METHOD: 'put'
    },


    TOGGLE_AGENCY: {
        URL: (agencyadminid: string) => ENV.API_URL + '/agencyadmin/' + agencyadminid + "/toggle",
        METHOD: 'put'
    },

    TOGGLE_FACILITY_ADMIN: {
        URL: (facilityId: string) => ENV.API_URL + '/facilityadmin/' + facilityId + "/toggle",
        METHOD: 'put'
    },

    TOGGLE_AGENCY_ADMIN: {
        URL: (facilityId: string) => ENV.API_URL + '/agencyadmin/' + facilityId + "/toggle",
        METHOD: 'put'
    },


    TOGGLE_WING: {
        URL: (facilityId: string, wingId: string) => ENV.API_URL + '/facility/' + facilityId + '/wing/' + wingId + '/toggle',
        METHOD: 'put'
    },
    //meta end

    // authentication start
    LOGIN: {
        URL: ENV.API_URL + "/login",
        METHOD: "post"
    },
    CHECK_LOGIN: {
        URL: ENV.API_URL + "/checkLogin",
        METHOD: "get"
    },
    LOGOUT: {
        URL: ENV.API_URL + "/login",
        METHOD: "delete"
    },
    FORGOT_PASSWORD: {
        URL: ENV.API_URL + "/forgotPassword",
        METHOD: "put"
    },
    SEND_OTP: {
        URL: ENV.API_URL + "/sendOtp",
        METHOD: "post"
    },
    VERIFY_OTP: {
        URL: ENV.API_URL + "/verifyOtp",
        METHOD: "post"
    },
    SET_NEW_PASSWORD: {
        URL: ENV.API_URL + "/resetPassword",
        METHOD: "post"
    },
    // authentication end

    //facility start vars health
    ADD_FACILITY: {
        URL: ENV.API_URL + '/facility',
        METHOD: 'post'
    },
    ADD_FACILITY_ADMIN_UNDER_USER_MANAGEMENT: {
        URL: ENV.API_URL + '/facilityadmin/user',
        METHOD: 'post'
    },
    ADD_AGENCY_ADMIN_UNDER_USER_MANAGEMENT: {
        URL: ENV.API_URL + '/agencyadmin',
        METHOD: 'post'
    },
    ADD_WING: {
        URL: (facilityId: string) => ENV.API_URL + '/facility/' + facilityId + "/wing",
        METHOD: 'post'
    },
    ADD_SHIFT_TIMINGS: {
        URL: (facilityId: string) => ENV.API_URL + '/facility/' + facilityId + "/shifttimings",
        METHOD: 'post'
    },
    EDIT_WING: {
        URL: (facilityId: string, wingId: any) => ENV.API_URL + '/facility/' + facilityId + "/wing/" + wingId,
        METHOD: 'put'
    },
    FACILITY_LIST: {
        URL: ENV.API_URL + '/facility',
        METHOD: 'get'
    },
    USER_MANAGEMENT_LIST: {
        URL: ENV.API_URL + '/facilityadmin/list',
        METHOD: 'get'
    },
    AGENCY_USER_MANAGEMENT_LIST: {
        URL: ENV.API_URL + '/agencyadmin/list',
        METHOD: 'get'
    },

    WING_LIST: {
        URL: (facilityId: string) => ENV.API_URL + '/facility/' + facilityId + '/wing',
        METHOD: 'get'
    },
    LINKED_AGENCY_LIST_FOR_FACILITY: {
        URL: ENV.API_URL + '/agency',
        METHOD: 'get'
    },
    WING_DETAILS: {
        URL: (facilityId: any, wingId: string) => ENV.API_URL + '/facility/' + facilityId + '/wing/' + wingId,
        METHOD: 'get'
    },
    FACILITY_HCP_SHIFTS: {
        URL: ENV.API_URL + '/shifts/list',
        METHOD: 'post'
    },
    FACILITY_LIST_LITE: {
        URL: ENV.API_URL + '/facility/lite',
        METHOD: 'get'
    },
    FACILITY_DETAILS: {
        URL: (facilityId: string) => ENV.API_URL + '/facility/' + facilityId,
        METHOD: 'get'
    },
    EDIT_FACILITY: {
        URL: (facilityId: string) => ENV.API_URL + '/facility/' + facilityId,
        METHOD: 'put'
    },
    EDIT_FACILITY_ADMIN_DETAILS: {
        URL: (facilityId: string) => ENV.API_URL + '/facilityadmin/' + facilityId,
        METHOD: 'put'
    },
    WINGS_LIST_LITE: {
        URL: ENV.API_URL + '/wing/wingLite',
        METHOD: 'get'
    },
    REGION_LIST: {
        URL: ENV.API_URL + '/regions',
        METHOD: 'get'
    },
    EDIT_AGENCY_ADMIN_DETAILS: {
        URL: (agencyId: string) => ENV.API_URL + '/agencyadmin/' + agencyId,
        METHOD: 'put'
    },

    //Agency api urls
    ADD_AGENCY: {
        URL: (facilityId: string) => ENV.API_URL + '/facility/' + facilityId + "/agency",
        METHOD: 'post'
    },
    AGENCY_LIST: {
        URL: ENV.API_URL + '/agency',
        METHOD: 'get'
    },
    AGENCY_LIST_LITE: {
        URL: ENV.API_URL + '/agency/lite',
        METHOD: 'get'
    },
    FACILITY_AGENCY_LIST_LITE: {
        URL: (facilityId: string) => ENV.API_URL + "/facility/" + facilityId + "/agency/lite",
        METHOD: 'get'
    },
    STAFF_SPECIALITY_LIST: {
        URL: (hcpTypeId: string) => ENV.API_URL + "/hcpType/" + hcpTypeId + "/specialities",
        METHOD: 'get'
    },
    AGENCY_DETAILS: {
        URL: (agencyId: string) => ENV.API_URL + "/agency/" + agencyId,
        METHOD: 'get'
    },
    EDIT_AGENCY: {
        URL: (agencyId: string) => ENV.API_URL + "/agency/" + agencyId,
        METHOD: 'put'
    },
    EDIT_FACILITY_AGENCY_RATES: {
        URL: (facilityId: any, agencyId: string) => ENV.API_URL + "/facility/" + facilityId + "/agency/" + agencyId,
        METHOD: 'put'
    },
    LINK_AGENCY_FACILITY: {
        URL: (facilityId: string, agencyId: string) => ENV.API_URL + "/facility/" + facilityId + "/agency/" + agencyId + "/link",
        METHOD: 'post'
    },
    GET_LINKED_FACILITY_AGENCY: {
        URL: (agencyId: string) => `${ENV.API_URL}/agency/${agencyId}/facility/`,
        METHOD: 'get'
    },

    GET_AGENCY_RATE_WITH_FACILITY_ID: {
        URL: (facilityId: string, agencyId: string) => ENV.API_URL + "/facility/" + facilityId + "/agency/" + agencyId,
        METHOD: 'get'
    },

    EDIT_HCP_TYPES_RATE: {
        URL: (facilityId: string, agencyId: string) => ENV.API_URL + "/facility/" + facilityId + "/agency/" + agencyId,
        METHOD: 'put'
    },
    CONTRACT_UPLOAD_AGENCY_FACILITY: {
        URL: (facilityId: string, agencyId: string) => ENV.API_URL + "/facility/" + facilityId + "/agency/" + agencyId + "/contract",
        METHOD: 'post'
    },
    //hcp-management-apis
    HCP_MANAGEMENT_LIST: {
        URL: ENV.API_URL + '/hcp/list',
        METHOD: 'post'
    },
    HCP_MANAGEMENT_LIST_LITE: {
        URL: ENV.API_URL + '/hcp/lite',
        METHOD: 'get'
    },
    ONBOARD_HCP: {
        URL: ENV.API_URL + '/hcp',
        METHOD: 'post'
    },
    UPLOAD_HCP_DOCUMENT: {
        URL: (hcpId: string) => ENV.API_URL + '/hcp/' + hcpId + "/document",
        METHOD: 'post'
    },
    HCP_DETAILS: {
        URL: (HCPId: string) => ENV.API_URL + "/hcp/" + HCPId,
        METHOD: 'get'
    },
    HCP_DOCUMENT_DELETE: {
        URL: (hcpId: any, documentId: any) => ENV.API_URL + '/hcp/' + hcpId + "/document/" + documentId,
        METHOD: 'delete'
    },
    HCP_DOCUMENT_DOWNLOAD: {
        URL: ENV.API_URL + '/downloadAttachment',
        METHOD: 'post'
    },
    HCP_LIST_REQUIREMENT_BASED: {
        URL: ENV.API_URL + '/hcp/lite',
        METHOD: 'get'
    },
    HCP_DOCUMENTS: {
        URL: (HCPId: string) => ENV.API_URL + "/hcp/" + HCPId + "/document",
        METHOD: 'get'
    },
    EDIT_HCP_DETAILS: {
        URL: (hcpId: string) => ENV.API_URL + '/hcp/' + hcpId,
        METHOD: 'put'
    },
    //shift-management-apis
    SHIFT_MANAGEMENT_LIST: {
        URL: ENV.API_URL + '/requirement/list',
        METHOD: 'post'
    },
    ADD_NEW_SHIFT: {
        URL: ENV.API_URL + '/requirement',
        METHOD: 'post'
    },
    ADD_NEW_OPEN_SHIFT: {
        URL: ENV.API_URL + '/requirement',
        METHOD: 'post'
    },

    BULK_UPLOAD_OPEN_REQUIREMENT: {
        URL: ENV.API_URL + '/requirement/bulkOpenShifts',
        METHOD: 'post'
    },
    BULK_UPLOAD_HOME_REQUIREMENT: {
        URL: ENV.API_URL + '/requirement/bulkHomeShifts',
        METHOD: 'post'
    },
    BULK_UPLOAD_CONTRACT_REQUIREMENT: {
        URL: ENV.API_URL + '/requirement/bulkContractShifts',
        METHOD: 'post'
    },

    ADD_CONTRACT_SHIFT: {
        URL: ENV.API_URL + '/requirement/contract',
        METHOD: 'post'
    },

    ADD_APPROVED_NEW_SHIFT: {
        URL: ENV.API_URL + '/facilityshift',
        METHOD: 'post'
    },
    SHIFT_REQUIREMENT_DETAILS: {
        URL: (shiftId: any) => ENV.API_URL + "/requirement/" + shiftId,
        METHOD: 'get'
    },

    SHIFT_ASSIGNED_DETAILS: {
        URL: (shiftId: any) => ENV.API_URL + "/shift/" + shiftId + '/view',
        METHOD: 'get'
    },


    INTERNAL_SHIFT_ASSIGNED_DETAILS: {
        URL: (shiftId: any) => ENV.API_URL + "/facilityShift/" + shiftId + '/view',
        METHOD: 'get'
    },

    SHIFT_MANAGEMENT_LIST_AFTER_APPROVAL: {
        URL: ENV.API_URL + '/shifts/list',
        METHOD: 'post'
    },
    SHIFT_MANAGEMENT_PENDING_LIST: {
        URL: ENV.API_URL + '/application/list',
        METHOD: 'post'
    },
    SHIFT_BULK_UPLOAD_LIST: {
        URL: ENV.API_URL + '/requirement/listExcelDocs',
        METHOD: 'post'
    },
    SHIFT_MANAGEMENT_PENDING_VIEW: {
        URL: (shiftId: any) => ENV.API_URL + "/application/" + shiftId,
        METHOD: 'get'
    },

    SHIFTS_CALENDAR_LIST: {
        URL: ENV.API_URL + '/shifts/calendar',
        METHOD: "post"
    },

    SHIFTS_CALENDAR_WEEK_LIST: {
        URL: ENV.API_URL + '/shift/weekView',
        METHOD: "post"
    },

    SHIFTS_CALENDAR_DAY_LIST: {
        URL: ENV.API_URL + '/shift/dayView',
        METHOD: "post"
    },
    INTERNAL_SHIFTS_CALENDAR_LIST: {
        URL: ENV.API_URL + '/shifts/internalCalendar',
        METHOD: "post"
    },
    INTERNAL_SHIFT_CALENDAR_LIST_BY_DATE: {
        URL: ENV.API_URL + '/facilityShift/internalDayShifts',
        METHOD: "post"
    },
    SHIFT_CALENDAR_LIST_BY_DATE: {
        URL: ENV.API_URL + '/shift/dayShifts',
        METHOD: "post"
    },

    HCP_ASSIGNED_TO_SHIFT: {
        URL: (requirementId: any) => ENV.API_URL + "/requirement/" + requirementId + "/applications",
        METHOD: 'get'
    },
    ADD_NEW_APPLICATIONS_TO_SHIFT: {
        URL: (requirementId: any) => ENV.API_URL + "/requirement/" + requirementId + "/applications",
        METHOD: 'post'
    },
    ADD_NEW_APPROVED_SHIFTS_FOR_INTERNAL_STAFFS: {
        URL: (requirementId: any) => ENV.API_URL + "/requirement/" + requirementId + "/internalShifts",
        METHOD: 'post'
    },

    CANCEL_SHIFT_DETAILS: {
        URL: (requirementId: any) => ENV.API_URL + "/requirement/" + requirementId + "/cancel",
        METHOD: 'put'
    },
    UPLOAD_TIMESHEET: {
        URL: (shiftId: any) => ENV.API_URL + "/shift/" + shiftId + "/timesheet",
        METHOD: 'post',
    },
    MARK_APPROVED_SHIFT_TO_COMPLETED: {
        URL: ENV.API_URL + '/shifts/markAsCompleted',
        METHOD: 'put'
    },
    MARK_COMPLETED_SHIFT_TO_CONFIRMED: {
        URL: ENV.API_URL + '/shifts/markAsConfirmed',
        METHOD: 'put'
    },
    CANCEL_APPROVED_SHIFT_TO_CANCELLED: {
        URL: ENV.API_URL + '/shifts/cancel',
        METHOD: 'put'
    },


    //meta-apis -vars
    GET_HCP_TYPES: {
        URL: ENV.API_URL + '/hcpTypes',
        METHOD: 'get'
    },
    GET_ADDITIONAL_RATES_TYPE: {
        URL: ENV.API_URL + '/addlRates',
        METHOD: 'get'
    },
    ADD_NEW_HCP_TYPE: {
        URL: ENV.API_URL + '/hcpTypes',
        METHOD: 'post'
    },
    ADD_NEW_ADDITIONAL_RATE: {
        URL: ENV.API_URL + '/addlRates',
        METHOD: 'post'
    },
    APPROVE_SHIFT_REQUIREMENT_APPLICATION: {
        URL: (shiftId: any) => ENV.API_URL + "/application/" + shiftId + "/approve",
        METHOD: 'post'
    },
    REJECT_SHIFT_REQUIREMENT_APPLICATION: {
        URL: (shiftId: any) => ENV.API_URL + "/application/" + shiftId + "/reject",
        METHOD: 'put'
    },
    DOCUMENT_DOWNLOAD: {
        URL: ENV.API_URL + '/downloadAttachment',
        METHOD: 'post'
    },
    DOCUMENT_DELETE: {
        URL: ENV.API_URL + '/deleteAttachment',
        METHOD: 'delete'
    },
    DOWNLOAD_SHIFT_DATA_CSV: {
        URL: ENV.API_URL + '/shifts/download',
        METHOD: 'post'
    },
    DOWNLOAD_BULK_UPLOAD_DATA_CSV: {
        URL: ENV.API_URL + '/downloadTemplate',
        METHOD: 'post'
    },
    DOWNLOAD_USER_DATA_CSV: {
        URL: ENV.API_URL + '/facilityadmin/download',
        METHOD: 'post'
    },
    DOWNLOAD_AGENCY_USER_DATA_CSV: {
        URL: ENV.API_URL + '/agencyadmin/download',
        METHOD: 'post'
    },
    DOWNLOAD_FACILITY_DATA_CSV: {
        URL: ENV.API_URL + '/facility/download',
        METHOD: 'post'
    },
    DOWNLOAD_AGENCY_DATA_CSV: {
        URL: ENV.API_URL + '/agency/download',
        METHOD: 'post'
    },
    DOWNLOAD_HCP_DATA_CSV: {
        URL: ENV.API_URL + '/hcp/download',
        METHOD: 'post'
    },


    DOWNLOAD_OPEN_SHIFT_DATA_CSV: {
        URL: ENV.API_URL + '/requirement/download',
        METHOD: 'post'
    },
    CONTRACT_UPLOAD_HCP: {
        URL: (hcpId: any) => ENV.API_URL + "/hcp/" + hcpId + "/contract",
        METHOD: 'post'
    },
    DELETE_SHIFT_TIMINGS: {
        URL: (facilityId: any, shiftTimingId: any) => ENV.API_URL + "/facility/" + facilityId + "/shifttimings/" + shiftTimingId,
        METHOD: "delete"
    },

    MARK_APPLICATION_AS_APPROVE: {
        URL: ENV.API_URL + '/applications/approve',
        METHOD: 'post'
    },
    MARK_APPLICATION_AS_REJECT: {
        URL: ENV.API_URL + '/applications/reject',
        METHOD: 'put'
    },
    DELETE_WING: {
        URL: (wingId: any, facilityId: any) => ENV.API_URL + "/facility/" + facilityId + "/wing/" + wingId,
        METHOD: "delete"
    },
    ADD_HCP_EDUCATION: {
        URL: (hcpId: string) => ENV.API_URL + '/hcp/' + hcpId + "/education",
        METHOD: 'post'
    },
    GET_HCP_EDUCATION: {
        URL: (hcpId: string) => ENV.API_URL + '/hcp/' + hcpId + "/education",
        METHOD: 'get'
    },
    EDIT_HCP_EDUCATION: {
        URL: (hcpId: string, educationId: string) => ENV.API_URL + '/hcp/' + hcpId + "/education/" + educationId,
        METHOD: 'put'
    },
    DELETE_HCP_EDUCATION: {
        URL: (hcpId: string, educationId: string) => ENV.API_URL + '/hcp/' + hcpId + "/education/" + educationId,
        METHOD: 'delete'
    },
    ADD_HCP_EXPERIENCE: {
        URL: (hcpId: string) => ENV.API_URL + '/hcp/' + hcpId + "/experience",
        METHOD: 'post'
    },
    EDIT_HCP_EXPERIENCE: {
        URL: (hcpId: string, experienceId: string) => ENV.API_URL + '/hcp/' + hcpId + "/experience/" + experienceId,
        METHOD: 'put'
    },
    DELETE_HCP_EXPERIENCE: {
        URL: (hcpId: string, experienceId: string) => ENV.API_URL + '/hcp/' + hcpId + "/experience/" + experienceId,
        METHOD: 'delete'
    },
    GET_HCP_EXPERIENCE: {
        URL: (hcpId: string) => ENV.API_URL + '/hcp/' + hcpId + "/experience",
        METHOD: 'get'
    },
    TOGGLE_HCP: {
        URL: (facilityId: string) => ENV.API_URL + '/hcp/' + facilityId + "/toggle",
        METHOD: 'put'
    },
    TOGGLE_LIST_AGENCY: {
        URL: (agencyadminid: string) => ENV.API_URL + '/agency/' + agencyadminid + "/toggle",
        METHOD: 'put'
    },
    APPROVE_HOME_HEALTH_APPLICATION: {
        URL: ENV.API_URL + '/applications/approve/home',
        METHOD: 'post'
    },
    APPROVE_TRAVEL_CONTRACT_APPLICATION: {
        URL: ENV.API_URL + '/applications/approve/contract',
        METHOD: 'post'
    },
    ADD_NEW_HOME_HEALTH_HOSPICE_SHIFT: {
        URL: ENV.API_URL + '/requirement/home',
        METHOD: 'post'
    }
};


export default APIConfig;
